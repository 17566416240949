import { orderForecastAnalyticsApi } from "api";

export const orderForecastService = {
  getFilterOptions(data) {
    return orderForecastAnalyticsApi
      .getFilterOptions({
        ...data,
        filters: {},
        data_type: "orders",
        frequency: "MS",
      })
      .then((res) => {
        return res;
      });
  },
  getDemandForecast(filters, showInsights = false) {
    return orderForecastAnalyticsApi
      .getDemandForecast({
        filters: filters,
        show_insights: showInsights,
        data_type: "orders",
        frequency: "MS",
        model_type: "winters",
        periods: 3,
      })
      .then((res) => {
        return res.data;
      });
  },
  getSeasonalDecomposition(filters, showInsights = false) {
    return orderForecastAnalyticsApi
      .getSeasonalDecomposition({
        filters: filters,
        show_insights: showInsights,
        data_type: "orders",
        frequency: "MS",
        model_type: "winters",
        periods: 3,
      })
      .then((res) => {
        return res.data;
      });
  },
  getRegionWiseDemand(filters, showInsights = false) {
    return orderForecastAnalyticsApi
      .getRegionWiseDemand({
        filters: filters,
        show_insights: showInsights,
        data_type: "orders",
        frequency: "MS",
        model_type: "winters",
        periods: 3,
      })
      .then((res) => {
        return res.data;
      });
  },
};
