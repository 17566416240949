import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import { format } from "date-fns";
import dayjs from "dayjs";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import DataTable from "examples/Tables/DataTable";
import FileModel from "layouts/folders/components/FileViewer";
import { folderService } from "layouts/folders/service/folderService";
import { useState } from "react";
import { Link } from "react-router-dom";
import DialogWrapper from "utils/dialog/dialogWrapper";
import EmailViewer from "./components/EmailViewer";
import ExcelPopupTable from "./components/ExcelPopupTable";
import useEmail from "./hooks/useEmail";
import useIntegration from "../useIntegration";
import { ConfigSettings, SourcesDropdown } from "../components/Config";
import { OutlinedButton } from "components/MDButton/button";
import FlowChart from "../components/FlowChart";

const ignoreFilterList = ["limit"];
const sourceType = "email";

const Email = () => {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [applyFilterFlag, setApplyFilterFlag] = useState(false);
  const [activeEmail, setActiveEmail] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [showFlowChart, setShowFlowChart] = useState(false);

  const {
    sources,
    setSources,
    activeSourceId,
    setActiveSourceId,
    integrationLoading,
    setIntegrationLoading,
  } = useIntegration(sourceType);

  const { isLoading, emailDetails, columns, pages, filters, setFilters, filterOptions } = useEmail({
    applyFilterFlag,
    activeSourceId,
    sourceType
  });

  const handleRowClick = (row) => {
    const clickedData = {};
    Object.keys(row.values).forEach((key) => {
      clickedData[key] = row.values[key].props.data;
    });
    setActiveEmail(clickedData);
  };

  const clearAllFilter = () => {
    setFilters(initialFilters);
    handleClickApplyFilter();
  };

  const handleFilterChange = (event, type) => {
    if (
      (event.target.value instanceof Date &&
        !isNaN(event.target.value.getTime())) ||
      dayjs.isDayjs(event.target.value) ||
      type == "Date"
    ) {
      event.target.value = format(event.target.value, "yyyy-MM-dd");
    }
    if (Array.isArray(event.target.value) && event.target.value.length > 0) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
          ? value._id
          : "";
      }
    }
    if (
      Array.isArray(event.target.value) &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = { ...filters, [event.target.name]: event.target.value };

    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      tempFilters[event.target.name] = [];
    }

    setFilters(tempFilters);
  };

  let debounceTimer;

  const handleSearchChange = (text, type) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilters((prevValue) => {
        return { ...prevValue, search: text };
      });
      setApplyFilterFlag(Math.random());
    }, 300);
  };

  const handleClickApplyFilter = () => {
    setApplyFilterFlag(Math.random());
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              email
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Link to={"/integrations"}>
              <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
                Integrations
              </MDBox>
            </Link>
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "1.5em" }}
            >
              chevron_right
            </span>
            <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Email</MDBox>
            <SourcesDropdown
              sources={sources}
              setActiveSourceId={setActiveSourceId}
              activeSourceId={activeSourceId}
            />
          </MDBox>
        }
        rightContent={
          <MDBox
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <OutlinedButton
              name={"Flow Chart"}
              sx={{
                backgroundColor: showFlowChart ? palette.primary.light : "",
              }}
              onClick={() => {
                setShowFlowChart((pre) => !pre);
              }}
              icon={<span class="material-symbols-outlined">account_tree</span>}
            />
            <ConfigSettings
              type={"email"}
              sources={sources}
              setSources={setSources}
              activeSourceId={activeSourceId}
              setActiveSourceId={setActiveSourceId}
              integrationLoading={integrationLoading}
              setIntegrationLoading={setIntegrationLoading}
            />
          </MDBox>
        }
      />
      {showFlowChart ? (
        <FlowChart
          type={"email"}
          sources={sources}
          setSources={setSources}
          integrationLoading={integrationLoading}
          setIntegrationLoading={setIntegrationLoading}
        />
      ) : (
        <MDBox
          sx={{
            position: "relative",
          }}
        >
          <DataTable
            table={{ columns: columns, rows: emailDetails || [] }}
            isSorted={true}
            entriesPerPage={{ defaultValue: filters?.limit }}
            showTotalEntries={true}
            noEndBorder
            canSearch={true}
            rowClick={handleRowClick}
            filters={filters}
            filterOptions={filterOptions}
            handleFilterChange={handleFilterChange}
            clearAllFilter={clearAllFilter}
            handleSearchChange={(text) => handleSearchChange(text, "Filters")}
            downloadable={false}
            customPagination={true}
            pageInfo={pages}
            isLoading={isLoading || integrationLoading}
            handleClickApplyFilter={handleClickApplyFilter}
            tableHeight={"calc(100vh - 184px)"}
          />

          {emailDetails && activeEmail && (
            <MDBox
              sx={{
                height: "100%",
                width: "100%",
                zIndex: 1,
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: white.main,
                borderRadius: "10px",
                padding: "1rem",
              }}
            >
              <EmailViewer
                emailDetails={activeEmail}
                onBackClick={() => {
                  setActiveEmail(null);
                }}
                setPreviewFile={setPreviewFile}
                sourceId={activeSourceId}
              />
            </MDBox>
          )}
        </MDBox>
      )}
      {previewFile && !folderService.isExcel(previewFile?.name) && (
        <DialogWrapper handleClose={() => setPreviewFile(null)} width={"35%"}>
          <FileModel file={previewFile} />
        </DialogWrapper>
      )}
      {previewFile && folderService.isExcel(previewFile?.name) && (
        <ExcelPopupTable
          fileId={previewFile?.hash}
          handleClose={() => setPreviewFile(null)}
          sourceId={activeSourceId}
        />
      )}
    </DashboardLayout>
  );
};

export default Email;
