import { useTheme } from "@emotion/react";
import Icon from "@mui/material/Icon";
import { teamsManagementAPI } from "api";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import DataTable from "examples/Tables/DataTable";
import { formatDate } from "examples/Tables/DataTable/utils";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTeams from "./hooks/useTeams";
import { setGlobalState } from "Global";
import { MarkerType, useEdgesState, useNodesState } from "reactflow";
import WorkFlow from "examples/workflow/Workflow";
const layoutOptions = {
  about: false,
  algorithm: "dagre",
  direction: "TB",
  spacing: [80, 80],
};

const ignoreColumns = ["_id", "members", "sub_teams", "parent_team"];

function TeamManagement() {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { pxToRem } = functions;

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showAddTeam, setShowAddTeam] = useState(false);
  const [showFlowChart, setShowFlowChart] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const defaultEdgeOptions = {
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: palette.primary.main,
    },
    pathOptions: { offset: 5 },
    style: {
      stroke: palette.primary.main,
      strokeWidth: 2,
    },
    labelStyle: {
      fill: palette.primary.main,
      fontSize: "12px",
      fontWeight: "bold",
    },
    labelBgStyle: {
      fill: palette.primary.light,
      stroke: palette.primary.main,
    },
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 5,
  };

  const { teamList, columns, users, setTeamList, setIsLoading, isLoading } =
    useTeams();

  useEffect(() => {
    if (teamList && teamList.length > 0) {
      generateFlowChart(teamList);
    }
  }, [teamList]);

  const generateFlowChart = (teamList = []) => {
    const getNode = ({ name, heading, onClick }) => {
      return (
        <MDBox
          sx={{
            backgroundColor: palette.primary.light,
            border: "1px solid " + palette.primary.main,
            borderRadius: "5px",
            minWidth: "100px",
            cursor: onClick ? "pointer" : "default",
          }}
          onClick={onClick}
          color={palette.primary.main}
        >
          {heading && (
            <MDBox
              color={"inherit"}
              sx={{
                fontWeight: "bold",
                borderBottom: "1px solid " + palette.primary.main,
                padding: "5px 10px",
              }}
            >
              {heading}
            </MDBox>
          )}
          <MDBox color={"inherit"} sx={{ padding: "10px" }}>
            <MDBox style={{ fontSize: "small" }} color={"inherit"}>
              {name}
            </MDBox>
          </MDBox>
        </MDBox>
      );
    };

    let tempNodes = [];
    let tempEdges = [];
    teamList.forEach((data) => {
      tempNodes.push({
        id: data._id,
        data: {
          label: getNode({
            name: data.team_name,
            onClick: () => {
              handleNodeClick(data._id);
            },
          }),
        },
        position: { x: 0, y: 0 },
      });
      tempEdges.push({
        id: data.parent_team + "->" + data._id,
        source: data.parent_team,
        target: data._id,
      });
    });
    setNodes(tempNodes);
    setEdges(tempEdges);
  };

  const tabs = [
    {
      name: "User",
      route: "user",
    },
    {
      name: "Team",
      route: "team",
    },
  ];

  const defaultInputs = {
    team_name: {
      type: "Text",
      editable: true,
      required: true,
    },
    parent_team: {
      type: "DropdownSingleSelect",
      editable: true,
      options: teamList,
      dataKey: "_id",
      labelKey: "team_name",
    },
    members: {
      type: "DropdownMultiSelect",
      options: users,
      dataKey: "email_id",
      editable: true,
    },
  };

  const selectTeamById = (id) => {
    if (id) {
      setSelectedTeam(teamList.find((data) => data._id === id));
    }
  };
  const handleRowClick = (row) => {
    selectTeamById(row?.values?._id);
  };
  const handleNodeClick = (id) => {
    selectTeamById(id);
  };

  const handleUpdateTeam = (id, data) => {
    setIsLoading(true);
    const { members, team_name } = data;
    teamsManagementAPI
      .updateTeam(id, members, team_name)
      .then((res) => {
        if (res.status === 200) {
          const tempData = teamList.map((data) => {
            if (id === data._id) {
              return { ...data, members: members };
            }
            return data;
          });
          setGlobalState("error", {
            open: true,
            message: "Team Updated successfully!",
            type: "success",
            code: 200,
          });
          setTeamList(tempData);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
        setSelectedTeam(null);
      });
  };

  const handleCreateNewTeam = (data) => {
    setIsLoading(true);
    const { team_name, parent_team, members, sub_teams } = data;
    teamsManagementAPI
      .createTeam(team_name, parent_team, members, sub_teams)
      .then((res) => {
        if (res.status === 200) {
          setGlobalState("error", {
            open: true,
            message: `Team ${team_name} created successfully!`,
            type: "success",
            code: 200,
          });
          setTeamList((pre) => [
            ...pre,
            {
              ...data,
              created_at: formatDate(new Date()),
              updated_at: formatDate(new Date()),
              members_count: data.members.length,
              _id: res.data.team_id,
            },
          ]);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
        setShowAddTeam(false);
      });
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              manage_accounts
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            Team Management
          </MDBox>
        }
        rightContent={
          <MDBox
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <OutlinedButton
              name={"Flow Chart"}
              sx={{
                backgroundColor: showFlowChart ? palette.primary.light : "",
              }}
              onClick={() => {
                setShowFlowChart((pre) => !pre);
              }}
              icon={<span class="material-symbols-outlined">account_tree</span>}
            />
            <OutlinedButton
              name={"Add Team"}
              onClick={() => {
                setShowAddTeam(true);
                setSelectedTeam(null);
              }}
              icon={
                <Icon className={"font-unset"} fontSize="1em">
                  add
                </Icon>
              }
            />
          </MDBox>
        }
      />
      <MDBox
        sx={{
          display: "flex",
          fontSize: typography.size["sm"],
          fontWeight: typography.fontWeightMedium,
          borderBottom: `1px solid ${palette.grey[300]}`,
          gap: "1em",
        }}
        mb={2}
      >
        {tabs.map((tab, index) => (
          <Link to={"/management/" + tab.route}>
            <MDBox
              key={index}
              sx={{
                padding: pxToRem(8),
                cursor: "pointer",
                gap: "5px",
                display: "flex",
                paddingBottom: pxToRem(5),
                paddingTop: 0,
                borderBottom:
                  tab?.name === "Team"
                    ? `2px solid ${palette.primary.main}`
                    : ``,
              }}
              color={palette.text.header}
            >
              {tab?.name}
            </MDBox>
          </Link>
        ))}
      </MDBox>
      <>
        {showFlowChart ? (
          <MDBox
            sx={{
              width: "100%",
              height: "calc(100% - 127px)",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <style jsx>{`
              .react-flow__pane {
                cursor: default;
              }
            `}</style>
            {nodes.length > 0 && edges.length > 0 && (
              <WorkFlow
                nodes={nodes}
                edges={edges}
                zoomOnScroll={false}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                isAutoLayout={true}
                showBackground={true}
                showControls={false}
                edgeOptions={defaultEdgeOptions}
                nodesDraggable={false}
                panOnDrag={false}
                layoutOptions={layoutOptions}
                nodeStyle={`
                            padding: 0;
                            border: none;
                            width: auto;
                            max-width: 300px;
                          `}
                nodeDotStyle={`border:none; background-color: #00000000;`}
                key={edges + nodes}
              />
            )}
          </MDBox>
        ) : (
          <MDBox>
            <DataTable
              table={{ columns: columns, rows: teamList }}
              isLoading={isLoading}
              downloadable={true}
              canSearch={true}
              ignoreColumns={ignoreColumns}
              rowClick={handleRowClick}
              tableHeight={"calc(100vh - 231px)"}
              entriesPerPage={{ defaultValue: 10 }}
              isSorted={true}
              showTotalEntries={true}
              customPagination={false}
            />
          </MDBox>
        )}
      </>
      {selectedTeam && (
        <DataInputDialog
          open={!!selectedTeam}
          handleClose={() => {
            setSelectedTeam(null);
          }}
          defaultInputs={{
            team_name: {
              ...defaultInputs.team_name,
              value: selectedTeam.team_name,
            },
            members: { ...defaultInputs.members, value: selectedTeam.members },
          }}
          handleSubmit={(data) => {
            handleUpdateTeam(selectedTeam._id, data);
          }}
          buttonName={"Update"}
          heading={"Update Team"}
          iconName={"group"}
          isLoading={isLoading}
          doubleColAfterInputs={4}
        />
      )}
      {showAddTeam && (
        <DataInputDialog
          open={showAddTeam}
          handleClose={() => {
            setShowAddTeam(false);
          }}
          defaultInputs={defaultInputs}
          handleSubmit={(data) => {
            handleCreateNewTeam(data);
          }}
          buttonName={"Create Team"}
          heading={"Create New Team"}
          iconName={"group_add"}
          isLoading={isLoading}
          doubleColAfterInputs={4}
        />
      )}
    </DashboardLayout>
  );
}

export default TeamManagement;
