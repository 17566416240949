import { useTheme } from "@emotion/react";
import { Autocomplete, Dialog, Select, TextField } from "@mui/material";
import { integrationAPI } from "api";
import CustomAutoComplete from "components/controls/AutoComplete";
import { controlTypes } from "components/controls/Controls";
import { CustomPopper } from "components/controls/CustomPopper";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import FormBuilder from "examples/formBuilder/FormBuilder";
import { defaultPermissions } from "examples/formBuilder/FormBuilder";
import WorkFlow from "examples/workflow/Workflow";
import { setGlobalState } from "Global";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { MarkerType, useEdgesState, useNodesState } from "reactflow";
import { convertKeyToName } from "utils/conversion";
import ValidationDialog from "./ValidationDialog";

const layoutOptions = {
  about: false,
  algorithm: "dagre",
  direction: "LR",
  spacing: [150, 150],
};

const FlowChart = ({ type, sources, setSources }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text, grey } = palette;
  const { pxToRem } = functions;

  const [showInputDialog, setShowInputDialog] = useState(false);
  const [showCreateSourceDialog, setShowCreateSourceDialog] = useState(null);
  const [tempCreateSourceFields, setTempCreateSourceFields] = useState({});
  const [showFormBuilderCreateSource, setShowFormBuilderCreateSource] =
    useState(false);
  const [showFormBuilder, setShowFormBuilder] = useState(false);
  const [sourceDetails, setSourceDetails] = useState(null);
  const [defaultInputs, setDefaultInputs] = useState({});
  const [activeSourceId, setActiveSourceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [showValidationDialog, setShowValidationDialog] = useState(false);

  const defaultEdgeOptions = {
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: primary.main,
    },
    pathOptions: { offset: 5 },
    style: {
      stroke: primary.main,
      strokeWidth: 2,
    },
    labelStyle: {
      fill: primary.main,
      fontSize: "12px",
      fontWeight: "bold",
    },
    labelBgStyle: {
      fill: primary.light,
      stroke: primary.main,
    },
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 5,
  };

  useEffect(() => {
    if (sources) {
      const getNode = (name, heading, id, onClick) => {
        return (
          <MDBox
            sx={{
              backgroundColor: primary.light,
              border: "1px solid " + primary.main,
              borderRadius: "5px",
              minWidth: "100px",
              cursor: onClick ? "pointer" : "default",
            }}
            color={primary.main}
            onClick={
              onClick
                ? () => {
                    setActiveSourceId(id);
                    setShowInputDialog(true);
                  }
                : () => {}
            }
          >
            <MDBox
              color={"inherit"}
              sx={{
                fontWeight: "bold",
                borderBottom: "1px solid " + primary.main,
                padding: "5px 10px",
              }}
            >
              {heading}
            </MDBox>
            <MDBox color={"inherit"} sx={{ padding: "10px" }}>
              <MDBox style={{ fontSize: "small" }} color={"inherit"}>
                {name}
              </MDBox>
            </MDBox>
          </MDBox>
        );
      };

      let tempNodes = [];
      let tempEdges = [];
      sources.forEach((data, index) => {
        tempNodes.push({
          id: data.id,
          data: {
            label: getNode(data.name, "Source", data.id, true),
          },
          position: { x: 0, y: 0 },
        });
        tempNodes.push({
          id: data.id + "validation",
          data: {
            label: (
              <MDBox
                sx={{
                  backgroundColor: primary.light,
                  border: "1px solid " + primary.main,
                  borderRadius: "5px",
                  padding: "10px",
                  minWidth: "100px",
                  cursor: "pointer",
                }}
                color={primary.main}
                onClick={() => {
                  {
                    setActiveSourceId(data.id);
                    setShowValidationDialog(true);
                  }
                }}
              >
                Validation
              </MDBox>
            ),
          },
          position: { x: 0, y: 0 },
        });
        tempNodes.push({
          id: data.id + "target",
          data: {
            label: getNode(
              <MDBox sx={{ minWidth: "150px" }}>
                <CustomAutoComplete
                  name="target"
                  label="Active Target"
                  value={data.target}
                  options={["delivery", "orders"]}
                  disableClearable
                  onChange={(e) => {
                    if (e.target.value) {
                      updateSource(data.id, {
                        target: e.target.value,
                      });
                    }
                  }}
                />
              </MDBox>,
              "Target"
            ),
          },
          position: { x: 0, y: 0 },
        });
        tempEdges.push({
          id: index + "->validation",
          source: data.id,
          target: data.id + "validation",
        });
        tempEdges.push({
          id: index + "->target",
          source: data.id + "validation",
          target: data.id + "target",
        });
      });

      setNodes(tempNodes);
      setEdges(tempEdges);
    }
  }, [sources]);

  useEffect(() => {
    if (sourceDetails) {
      setDefaultInputs({
        id: {
          type: "Text",
          editable: false,
          value: sourceDetails?.id,
        },
        name: {
          type: "Text",
          editable: true,
          value: sourceDetails?.name,
        },
        description: {
          type: "Text",
          editable: true,
          value: sourceDetails?.description,
        },
        target: {
          type: "Text",
          editable: true,
          required: true,
          value: sourceDetails?.target,
        },
      });
    }
  }, [sourceDetails]);

  useEffect(() => {
    if (activeSourceId) {
      getSourceDetails(activeSourceId);
    }
  }, [activeSourceId]);

  const getSourceDetails = (id) => {
    setIsLoading(true);
    integrationAPI
      .getSourceDetails(id, type)
      .then((res) => {
        if (res.status == 200) {
          let fields = {};
          Object.keys(res.data.fields).forEach((key, index) => {
            fields[key] = {
              name: res.data.fields[key].field,
              permissions:
                res.data.fields[key]?.permissions || defaultPermissions,
              type: res.data.fields[key].type,
              edit: res.data.fields[key].edit,
            };
          });
          setSourceDetails({ ...res.data, fields });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateSource = (id, sourceDetails, message) => {
    setIsLoading(true)
    integrationAPI
      .updateSourceDetails({
        integration_type: type,
        id: id,
        source_details: sourceDetails,
      })
      .then((res) => {
        if (res.status == 200) {
          let tempSources = sources.map((data) => {
            if (data.id === id) {
              return { ...data, ...sourceDetails };
            }
            return data;
          });
          setSources(tempSources);
          setGlobalState("error", {
            open: true,
            message: message || "Target Updated successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setShowValidationDialog(false);
        setActiveSourceId(null);
      });
  };

  const updateSourceDetails = (id, name, description, target, fields) => {
    let tempFields = [];
    Object.keys(fields).forEach((key, index) => {
      tempFields.push({
        field: fields[key].name,
        ...fields[key],
      });
    });
    integrationAPI
      .updateSourceDetails({
        integration_type: type,
        id: id,
        source_details: {
          ...sourceDetails,
          id: id,
          name: name,
          description: description,
          target: target,
          fields: tempFields,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setSourceDetails((pre) => ({
            ...pre,
            name,
            description,
            target,
            fields,
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setActiveSourceId(null);
      });
  };
  const createSource = (name, description, target, fields) => {
    let tempFields = [];
    Object.keys(fields).forEach((key, index) => {
      tempFields.push({
        field: fields[key].name,
        ...fields[key],
      });
    });
    integrationAPI
      .createSource(type, name, description, target, tempFields)
      .then((res) => {
        if (res.status == 200) {
          setSources((pre) => [
            ...pre,
            {
              id: res.data?.id || "",
              name,
              description,
              target,
              fields,
            },
          ]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setTempCreateSourceFields({});
        setShowCreateSourceDialog(false);
      });
  };

  return (
    <>
      <MDBox
        sx={{
          width: "100%",
          height: "calc(100% - 80px)",
          position: "relative",
        }}
      >
        <style jsx>{`
          .react-flow__pane {
            cursor: default;
          }
        `}</style>
        {nodes.length > 0 && edges.length > 0 && (
          <WorkFlow
            nodes={nodes}
            edges={edges}
            zoomOnScroll={false}
            setNodes={setNodes}
            setEdges={setEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            isAutoLayout={true}
            showBackground={true}
            showControls={false}
            edgeOptions={defaultEdgeOptions}
            nodesDraggable={false}
            panOnDrag={false}
            layoutOptions={layoutOptions}
            nodeStyle={`
              padding: 0;
              border: none;
              width: auto;
              max-width: 300px;
            `}
            nodeDotStyle={`border:none; background-color: #00000000;`}
            key={edges + nodes}
          />
        )}
      </MDBox>
      {showCreateSourceDialog && (
        <DataInputDialog
          open={showCreateSourceDialog}
          handleClose={() => {
            setShowCreateSourceDialog(false);
          }}
          defaultInputs={{
            name: {
              type: "Text",
              editable: true,
              required: true,
            },
            description: {
              type: "Text",
              editable: true,
              required: true,
            },
            target: {
              type: "Text",
              editable: true,
              required: true,
            },
          }}
          handleSubmit={(data) => {
            const { name, description, target } = data;
            createSource(name, description, target, tempCreateSourceFields);
          }}
          buttonName={"Create"}
          heading={"Create Source"}
          iconName={"add"}
          isLoading={isLoading}
          doubleColAfterInputs={10}
          bottomInfo={
            <>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  fontSize: typography.size["sm"],
                  fontWeight: typography.fontWeightBold,
                }}
                py={1}
                pl={2}
              >
                <MDBox sx={{ fontSize: "inherit" }}>
                  Form Fields (
                  {Object.keys(tempCreateSourceFields)?.length || 0 || 0})
                </MDBox>
                <OutlinedButton
                  name={" Show"}
                  onClick={() => setShowFormBuilderCreateSource(true)}
                  sx={{
                    height: "30px",
                    borderRadius: "100px",
                    paddingLeft: "13px",
                  }}
                  icon={<></>}
                />
              </MDBox>
              <Dialog
                open={showFormBuilderCreateSource}
                onClose={() => setShowFormBuilderCreateSource(false)}
                sx={{
                  "& .MuiPaper-root": {
                    maxWidth: "700px",
                    width: "70vw",
                    height: "70vh",
                  },
                }}
              >
                <style jsx>{`
                  ._loading_overlay_wrapper {
                    height: 100%;
                  }
                `}</style>
                <LoadingOverlay active={isLoading} spinner>
                  <MDBox sx={{ minWidth: "30rem", height: "100%" }}>
                    <FormBuilder
                      defaultInputs={tempCreateSourceFields}
                      inputTypes={[
                        controlTypes.text,
                        controlTypes.dropdown,
                        controlTypes.integer,
                        controlTypes.date,
                      ]}
                      handleSubmit={(data) => {
                        setTempCreateSourceFields(data);
                        setShowFormBuilderCreateSource(false);
                      }}
                      heading={"Form Builder"}
                      handleClose={() => {
                        setShowFormBuilderCreateSource(false);
                      }}
                    />
                  </MDBox>
                </LoadingOverlay>
              </Dialog>
            </>
          }
        />
      )}

      {showValidationDialog && (
        <ValidationDialog
          open={showValidationDialog}
          handleClose={() => {
            setShowValidationDialog(false);
            setActiveSourceId(null);
          }}
          defaultInputs={{
            primary_keys: {
              type: "DropdownMultiSelect",
              editable: true,
              required: true,
              options: [],
              value: sourceDetails?.validation?.primary_keys,
            },
            validation_email: {
              type: "Text",
              editable: true,
              required: true,
              value: sourceDetails?.validation?.validation_email,
            },
            validation_name: {
              type: "Text",
              editable: true,
              required: true,
              value: sourceDetails?.validation?.validation_name,
            },
          }}
          defaultSheets={sourceDetails?.validation?.sheets}
          handleSubmit={(data) => {
            updateSource(
              activeSourceId,
              {
                validation: data,
              },
              "Source updated successfully!"
            );
          }}
          buttonName={"update"}
          heading={"Validation"}
          iconName={"update"}
          isLoading={isLoading}
        />
      )}

      {showInputDialog && (
        <DataInputDialog
          open={showInputDialog}
          handleClose={() => {
            setShowInputDialog(null);
            setActiveSourceId(null);
          }}
          defaultInputs={defaultInputs}
          handleSubmit={(data) => {
            const { id, name, description, target } = data;
            updateSourceDetails(
              id,
              name,
              description,
              target,
              sourceDetails?.fields
            );
          }}
          buttonName={"Update"}
          heading={"Configurations"}
          iconName={"settings"}
          isLoading={isLoading}
          doubleColAfterInputs={10}
          bottomInfo={
            <>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  fontSize: typography.size["sm"],
                  fontWeight: typography.fontWeightBold,
                }}
                py={1}
                pl={2}
              >
                <MDBox sx={{ fontSize: "inherit" }}>
                  Form Fields (
                  {sourceDetails
                    ? Object.keys(sourceDetails?.fields)?.length || 0
                    : 0}
                  )
                </MDBox>
                <OutlinedButton
                  name={"Show"}
                  onClick={() => setShowFormBuilder(true)}
                  sx={{
                    height: "30px",
                    borderRadius: "100px",
                    paddingLeft: "13px",
                  }}
                  icon={<></>}
                />
              </MDBox>
              <Dialog
                open={showFormBuilder}
                onClose={() => setShowFormBuilder(false)}
                sx={{
                  "& .MuiPaper-root": {
                    maxWidth: "700px",
                    width: "70vw",
                    height: "70vh",
                  },
                }}
              >
                <style jsx>{`
                  ._loading_overlay_wrapper {
                    height: 100%;
                  }
                `}</style>
                <LoadingOverlay active={isLoading} spinner>
                  <MDBox sx={{ minWidth: "30rem", height: "100%" }}>
                    <FormBuilder
                      defaultInputs={sourceDetails?.fields || {}}
                      inputTypes={[
                        controlTypes.text,
                        controlTypes.dropdown,
                        controlTypes.integer,
                        controlTypes.date,
                      ]}
                      handleSubmit={(data) => {
                        setSourceDetails((pre) => ({ ...pre, fileds: data }));
                        setShowFormBuilder(false);
                      }}
                      heading={"Form Builder"}
                      handleClose={() => {
                        setShowFormBuilder(false);
                      }}
                    />
                  </MDBox>
                </LoadingOverlay>
              </Dialog>
            </>
          }
        />
      )}
    </>
  );
};

export default FlowChart;
