import { integrationAPI } from "api";
import { useEffect, useState } from "react";

const useIntegration = (type) => {
  const [sources, setSources] = useState(null);
  const [activeSourceId, setActiveSourceId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getSources = () => {
    setIsLoading(true);
    integrationAPI
      .getSources(type)
      .then((res) => {
        if (res.status == 200) {
          setSources(res.data);
          setActiveSourceId(res.data?.[0]?.id);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSources();
  }, [type]);

  return {
    sources,
    setSources,
    activeSourceId,
    setActiveSourceId,
    integrationLoading: isLoading,
    setIntegrationLoading: setIsLoading,
  };
};

export default useIntegration;
