import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import { Outlet, useLocation } from "react-router-dom";
import IconCard from "./components/IconCard";
import { useEffect, useState } from "react";
import { CircularProgress, Icon } from "@mui/material";
import { integrationAPI } from "api";
import { OutlinedButton } from "components/MDButton/button";
import { MarkerType, useEdgesState, useNodesState } from "reactflow";
import { useTheme } from "@emotion/react";
import { convertKeyToName } from "utils/conversion";
import WorkFlow from "examples/workflow/Workflow";
const layoutOptions = {
  about: false,
  algorithm: "dagre",
  direction: "LR",
  spacing: [80, 150],
};
const Integration = () => {
  const pathLenght = useLocation()
    .pathname.split("/")
    .filter((data) => data).length;
  const styles = useStyles();

  const theme = useTheme();
  const colors = ["#666CFF", "#FF7B70", "#FEB686", "#34D7B0"];
  const [integrations, setIntegrations] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [showFlowChart, setShowFlowChart] = useState(false);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const defaultEdgeOptions = {
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: theme.palette.primary.main,
    },
    pathOptions: { offset: 5 },
    style: {
      stroke: theme.palette.primary.main,
      strokeWidth: 2,
    },
    labelStyle: {
      fill: theme.palette.primary.main,
      fontSize: "12px",
      fontWeight: "bold",
    },
    labelBgStyle: {
      fill: theme.palette.primary.light,
      stroke: theme.palette.primary.main,
    },
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 5,
  };

  const generateFlowChart = (integrationNames, targets) => {
    const getNode = (name, heading) => {
      return (
        <MDBox
          sx={{
            backgroundColor: theme.palette.primary.light,
            border: "1px solid " + theme.palette.primary.main,
            borderRadius: "5px",
            minWidth: "100px",
          }}
          color={theme.palette.primary.main}
        >
          <MDBox
            color={"inherit"}
            sx={{
              fontWeight: "bold",
              borderBottom: "1px solid " + theme.palette.primary.main,
              padding: "5px 10px",
            }}
          >
            {heading}
          </MDBox>
          <MDBox color={"inherit"} sx={{ padding: "10px" }}>
            <MDBox style={{ fontSize: "small" }} color={"inherit"}>
              {name}
            </MDBox>
          </MDBox>
        </MDBox>
      );
    };
    let tempNodes = [
      {
        id: "Validation",
        data: {
          label: (
            <MDBox
              sx={{
                backgroundColor: theme.palette.primary.light,
                border: "1px solid " + theme.palette.primary.main,
                borderRadius: "5px",
                padding: "10px",
                minWidth: "100px",
              }}
              color={theme.palette.primary.main}
            >
              Validation
            </MDBox>
          ),
        },
        position: { x: 0, y: 0 },
      },
    ];
    let tempEdges = [];
    integrationNames.forEach((nodeName, index) => {
      tempNodes.push({
        id: nodeName,
        data: {
          label: getNode(nodeName, "Source"),
        },
        position: { x: 0, y: 0 },
      });
      tempEdges.push({
        id: nodeName + "->Validation",
        source: nodeName,
        target: "Validation",
      });
    });
    targets.forEach((nodeName, index) => {
      tempNodes.push({
        id: nodeName,
        data: {
          label: getNode(nodeName, "Target"),
        },
        position: { x: 0, y: 0 },
      });
      tempEdges.push({
        id: "Validation->" + nodeName,
        source: "Validation",
        target: nodeName,
      });
    });

    setNodes(tempNodes);
    setEdges(tempEdges);
  };

  const getIntegrationList = () => {
    setIsloading(true);
    integrationAPI
      .getIntegrationsList()
      .then((res) => {
        if (res.status == 200) {
          setIntegrations(res.data);
          let activeIntegrations = res.data
            ?.map((data) => {
              if (data?.is_active) {
                return convertKeyToName(data?.name);
              }
            })
            .filter((data) => data);
          generateFlowChart(activeIntegrations, ["orders", "delivery"]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsloading(false);
      });
  };

  useEffect(() => {
    getIntegrationList();
  }, []);

  return pathLenght === 1 ? (
    <DashboardLayout>
      {isLoading ? (
        <MDBox
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            minHeight: "100px",
          }}
        >
          <CircularProgress color="primary" />
        </MDBox>
      ) : (
        <>
          <TopNavbar
            icon={
              <MDBox sx={styles.iconHolder}>
                <span
                  style={{ fontSize: "1.3em" }}
                  class="material-symbols-outlined"
                >
                  widgets
                </span>
              </MDBox>
            }
            leftContent={
              <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
                Integrations
              </MDBox>
            }
            rightContent={
              <OutlinedButton
                name={"Flow Chart"}
                sx={{
                  backgroundColor: showFlowChart
                    ? theme.palette.primary.light
                    : "",
                }}
                onClick={() => {
                  setShowFlowChart((pre) => !pre);
                }}
                icon={
                  <span class="material-symbols-outlined">account_tree</span>
                }
              />
            }
          />
          {showFlowChart ? (
            <MDBox
              sx={{
                width: "100%",
                height: "calc(100% - 80px)",
                position: "relative",
                cursor: "pointer",
              }}
            >
              <style jsx>{`
                .react-flow__pane {
                  cursor: default;
                }
              `}</style>
              {nodes.length > 0 && edges.length > 0 && (
                <WorkFlow
                  nodes={nodes}
                  edges={edges}
                  zoomOnScroll={false}
                  setNodes={setNodes}
                  setEdges={setEdges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  isAutoLayout={true}
                  showBackground={true}
                  showControls={false}
                  edgeOptions={defaultEdgeOptions}
                  nodesDraggable={false}
                  panOnDrag={false}
                  layoutOptions={layoutOptions}
                  nodeStyle={`
                    padding: 0;
                    border: none;
                    width: auto;
                    max-width: 300px;
                    cursor: default;
                  `}
                  nodeDotStyle={`border:none; background-color: #00000000;`}
                  key={edges + nodes}
                />
              )}
            </MDBox>
          ) : (
            <MDBox
              sx={{
                display: "grid",
                gap: "1rem",
                gridTemplateColumns: "repeat(6, 1fr)",
                // "@media (max-width: 1115px)": {
                //   gridTemplateColumns: "repeat(8, 1fr)",
                // },
                // "@media (max-width: 1115px)": {
                //   gridTemplateColumns: "repeat(8, 1fr)",
                // },
                "@media (max-width: 1050px)": {
                  gridTemplateColumns: "repeat(5, 1fr)",
                },
                "@media (max-width: 800px)": {
                  gridTemplateColumns: "repeat(3, 1fr)",
                },
                "@media (max-width: 500px)": {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
              }}
            >
              {integrations?.map((card, index) => (
                <IconCard
                  title={card.name}
                  path={card.path}
                  isActive={card.is_active}
                  iconColor={colors[index % colors.length]}
                  iconName={card.icon_name}
                  iconUrl={card?.icon_url}
                  count={card?.count}
                  getIntegrationList={getIntegrationList}
                />
              ))}
            </MDBox>
          )}
        </>
      )}
    </DashboardLayout>
  ) : (
    <Outlet />
  );
};

export default Integration;
