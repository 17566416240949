import { useTheme } from "@emotion/react";
import {
  Checkbox,
  Icon,
  Table,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import QuickActionPopup from "components/quickActionPopup/QuickActionPopup";
import Confirmation from "examples/dialogs/Confirmation";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import FileUploadDialog from "examples/dialogs/FileUploadDialog";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import SearchBar from "examples/searchBar/SearchBar";
import { formatDate } from "examples/Tables/DataTable/utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DialogWrapper from "utils/dialog/dialogWrapper";
import FileModel from "./components/FileViewer";
import SearchFilesDialog from "./components/SearchFilesDialog";
import TextWithIcon from "./components/TextWithIcon";
import useFolder from "./hooks/useFolder";
import { folderService } from "./service/folderService";
import ListView from "./components/ListView";
import GridView from "./components/GridView";
import { setGlobalState } from "Global";
import { folderAPI } from "api";
import { useGlobalState } from "Global";
import ShareDialog from "./components/ShareDialog";
import { MarkerType, useEdgesState, useNodesState } from "reactflow";
import WorkFlow from "examples/workflow/Workflow";
import { OutlinedButton } from "components/MDButton/button";
import { deleteSvg } from "examples/svgIcons/svgIcons";
import { ShareWithSvg } from "examples/svgIcons/svgIcons";
import LoadingOverlay from "react-loading-overlay";
const layoutOptions = {
  about: false,
  algorithm: "dagre",
  direction: "TB",
  spacing: [80, 80],
};

const Folders = () => {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;

  const { id } = useParams();
  const navigation = useNavigate();
  const [userDetails] = useGlobalState("userDetails");
  const [isLoading, setIsLoading] = useState(false);
  const [showFlowChart, setShowFlowChart] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isChartLoading, setIsChartLoading] = useState(false);
  const defaultEdgeOptions = {
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: palette.primary.main,
    },
    pathOptions: { offset: 5 },
    style: {
      stroke: palette.primary.main,
      strokeWidth: 2,
    },
    labelStyle: {
      fill: palette.primary.main,
      fontSize: "12px",
      fontWeight: "bold",
    },
    labelBgStyle: {
      fill: palette.primary.light,
      stroke: palette.primary.main,
    },
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 5,
  };

  const {
    foldersAndFiles,
    setFoldersAndFiles,
    users,
    teamList,
    isLoadingFolder,
    getFolderAndFilesDetails,
  } = useFolder(id);
  const [previewFile, setPreviewFile] = useState(null);
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [showSearchFilesDialog, setShowSearchFilesDialog] = useState(false);
  const [showDeleteFolderDialog, setShowDeleteFolderDialog] = useState(null);
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(null);
  const [showShareWithDialog, setShowShareWithDialog] = useState(null);
  const [selectedFolderDetails, setSelectedFolderDetails] = useState(null);

  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [showMultiDelete, setShowMultiDelete] = useState(false);
  const [showMultiShare, setShowMultiShare] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    files: [],
    folders: [],
  });
  const [islistView, setIsListView] = useState(true);

  useEffect(() => {
    if (foldersAndFiles) {
      generateFlowChart(foldersAndFiles);
    }
  }, [foldersAndFiles]);

  const deleteNodeAndChildren = (nodeId) => {
    const childNodes = new Set();
    const toVisit = [nodeId];

    // Perform DFS to gather all child nodes
    while (toVisit.length) {
      const currentNode = toVisit.pop();
      if (!childNodes.has(currentNode)) {
        childNodes.add(currentNode);
        edges.forEach((edge) => {
          if (edge.source === currentNode && !childNodes.has(edge.target)) {
            toVisit.push(edge.target);
          }
        });
      }
    }
    const filteredNodes = nodes.filter((node) => !childNodes.has(node.id));
    const filteredEdges = edges.filter(
      (edge) => !childNodes.has(edge.source) && !childNodes.has(edge.target)
    );

    setNodes(filteredNodes);
    setEdges(filteredEdges);
  };

  const generateFlowChart = (folderDetails) => {
    setIsChartLoading(true);

    const getNode = ({ name, data }) => {
      return (
        <MDBox
          sx={{
            backgroundColor: palette.primary.light,
            border: "1px solid " + palette.primary.main,
            borderRadius: "5px",
            minWidth: "100px",
            position: "relative",
            padding: "10px 15px",
          }}
          color={palette.primary.main}
          onClick={
            data
              ? () => {
                  getFolderDetails(data.folder_id);
                }
              : () => {}
          }
        >
          {data && (
            <MDBox
              sx={{
                position: "absolute",
                right: "0",
                top: "0",
                transform: "translate(50%, -25%)",
              }}
            >
              <MDBox mb={0.2}>
                <Tooltip title={"Share Folder"}>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowShareWithDialog(data);
                    }}
                    class="material-symbols-outlined"
                    style={{
                      color: primary.main,
                      cursor: "pointer",
                      background: white.main,
                      padding: "4px",
                      height: "1.3rem",
                      display: "block",
                      width: "1.3rem",
                      borderRadius: "100%",
                      border: "1px solid " + primary.main,
                      fontSize: ".8em",
                      lineHeight: ".6em",
                    }}
                  >
                    {ShareWithSvg(primary.main)}
                  </span>
                </Tooltip>
              </MDBox>
              <MDBox>
                <Tooltip title={"Delete Folder"}>
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteFolderDialog(data);
                    }}
                    class="material-symbols-outlined"
                    style={{
                      color: error.main,
                      cursor: "pointer",
                      background: white.main,
                      padding: "4px",
                      height: "1.3rem",
                      display: "block",
                      width: "1.3rem",
                      borderRadius: "100%",
                      border: "1px solid " + error.main,
                      fontSize: ".8em",
                      lineHeight: ".6em",
                    }}
                  >
                    {deleteSvg(error.main)}
                  </span>
                </Tooltip>
              </MDBox>
            </MDBox>
          )}
          <MDBox color={"inherit"}>{name}</MDBox>
        </MDBox>
      );
    };

    const currentFolderId = folderDetails.folder_id
      ? folderDetails.folder_id
      : "Organization";
    const currentFolderName = folderDetails.folder_location.find(
      (data) => data.folder_id === currentFolderId
    );
    const newNodes = [
      {
        id: currentFolderId,
        data: {
          label: getNode({
            name: currentFolderName?.folder_name || "Organization",
          }),
        },
        position: { x: 0, y: 0 },
      },
      ...folderDetails?.subfolders.map((data) => ({
        id: data.folder_id,
        data: {
          label: getNode({
            name: data.folder_name,
            data: data,
          }),
        },
        position: { x: 0, y: 0 },
      })),
    ];
    const newEdges = folderDetails?.subfolders.map((data) => ({
      id: currentFolderId + "->" + data.folder_id,
      source: currentFolderId,
      target: data.folder_id,
    }));

    setNodes((prevNodes) => {
      const uniqueNewNodes = newNodes.filter(
        (newNode) =>
          !prevNodes.some((existingNode) => existingNode.id === newNode.id)
      );
      return [...prevNodes, ...uniqueNewNodes];
    });
    setEdges((prevEdges) => {
      const uniqueNewEdges = newEdges.filter(
        (newEdge) =>
          !prevEdges.some((existingEdge) => existingEdge.id === newEdge.id)
      );
      return [...prevEdges, ...uniqueNewEdges];
    });

    setIsChartLoading(false);
  };
  const handleFolderClick = (id) => {
    navigation("/folders/" + id);
  };

  const defaultInputs = {
    folder_name: {
      type: "Text",
      editable: true,
      required: true,
      value: "New Folder",
    },
    shared_with: {
      label: "Shared With",
      type: "DropdownMultiSelect",
      options: [...users, ...teamList],
      dataKey: "email_id",
      editable: true,
    },
  };

  useEffect(() => {
    if (showShareWithDialog) {
      const dynamicFunction = showShareWithDialog.file_id
        ? folderAPI.getFileDetails
        : folderAPI.getFolderDetails;
      setIsLoading(true);
      dynamicFunction(
        showShareWithDialog.folder_id || showShareWithDialog.file_id
      )
        .then((res) => {
          if (res.status === 200) {
            setSelectedFolderDetails(res.data);
          }
        })
        .catch(() => {
          setShowShareWithDialog(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [showShareWithDialog]);

  const handleCreateFolder = (data) => {
    setIsLoading(true);
    folderService
      .createFolder(
        {
          ...data,
          shared_with: [...(data.shared_with || []), userDetails.email_id],
        },
        foldersAndFiles?.folder_id
      )
      .then((res) => {
        if (res.status == 200) {
          setFoldersAndFiles((pre) => ({
            ...(pre || {}),
            subfolders: [
              ...(pre?.subfolders || []),
              {
                folder_id: res.data.folder_id,
                folder_name: data.folder_name,
                owner_email: userDetails.email_id,
                files: [],
                folders: [],
                created_at: new Date(),
              },
            ],
          }));
          setGlobalState("error", {
            open: true,
            message: "Folder Created successfully!",
            type: "success",
            code: 200,
          });
          setShowCreateFolderDialog(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setShowCreateFolderDialog(false);
        setIsLoading(false);
      });
  };
  const handleDeleteFolder = (id) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      setIsChartLoading(true)
      folderService
        .deleteFolder(id)
        .then((res) => {
          if (res.status == 200) {
            let tempSubFolders = foldersAndFiles.subfolders.filter(
              (folders) => folders.folder_id !== id
            );
            setFoldersAndFiles((pre) => ({
              ...pre,
              subfolders: tempSubFolders,
            }));
            deleteNodeAndChildren(id);
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setShowDeleteFolderDialog(null);
          setIsLoading(false);
          setIsChartLoading(flase)
        });
    });
  };

  const getFolderDetails = (folderId) => {
    setIsChartLoading(true);
    folderService
      .getFolderAndFilesDetails({
        folder_id: folderId || "",
      })
      .then((res) => {
        if (res.status == 200) {
          generateFlowChart({
            ...res.data,
            folder_location: res.data.folder_location.reverse(),
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsChartLoading(false);
      });
  };

  const handleUploadFile = async (files) => {
    setIsLoading(true);

    const formdata = new FormData();
    files.forEach((file) => {
      formdata.append("files", file);
    });
    formdata.append("folder_id", foldersAndFiles.folder_id);
    formdata.append("metadata", "");

    folderService
      .uploadFile(formdata)
      .then((res) => {
        if (res.status == 200) {
          getFolderAndFilesDetails(id);
          setGlobalState("error", {
            open: true,
            message: "File uploaded successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowFileUploadDialog(false);
        setIsLoading(false);
      });
  };

  const handleDeleteFile = (id) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      folderService
        .deleteFile(id)
        .then((res) => {
          if (res.status == 200) {
            let tempFiles = foldersAndFiles.files.filter(
              (file) => file.file_id !== id
            );
            setFoldersAndFiles((pre) => ({
              ...pre,
              files: tempFiles,
            }));
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setShowDeleteFileDialog(null);
          setIsLoading(false);
        });
    });
  };

  const handleShare = (id, shareWith, removedList, type, ownerEmail) => {
    // for team data extracting _id
    shareWith = shareWith.map((data) => {
      if (typeof data == "object") {
        return data._id || data.email_id;
      }
      return data;
    });
    if (ownerEmail) {
      removedList = removedList.filter((id) => id != ownerEmail);
    }
    return new Promise((resolve, reject) => {
      setIsLoading(true);

      const dynamicFunction =
        type === "folder" ? folderService.shareFolder : folderService.shareFile;

      dynamicFunction(id, shareWith, removedList)
        .then((res) => {
          if (res.status == 200) {
            if (type === "folder" && id === foldersAndFiles.folder_id) {
              setFoldersAndFiles((pre) => ({
                ...pre,
                shared_with: shareWith,
              }));
            } else if (type === "folder") {
              setFoldersAndFiles((pre) => ({
                ...pre,
                subfolders: pre.subfolders.map((data) =>
                  data.folder_id === id
                    ? { ...data, shared_with: shareWith }
                    : data
                ),
              }));
            } else {
              setFoldersAndFiles((pre) => ({
                ...pre,
                files: pre.files.map((data) =>
                  data.file_id === id
                    ? { ...data, shared_with: shareWith }
                    : data
                ),
              }));
            }
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setShowShareWithDialog(null);
          setIsLoading(false);
        });
    });
  };

  const handleSelect = (value, id, type) => {
    if (type === "all") {
      setSelectedItems({
        files: foldersAndFiles?.files.map((data) => data.file_id),
        folders: foldersAndFiles?.subfolders.map((data) => data.folder_id),
      });
    } else {
      let tempItems = { ...selectedItems };
      if (value) {
        tempItems[type].push(id);
      } else {
        tempItems[type] = tempItems[type].filter((ItemId) => ItemId != id);
      }
      setSelectedItems(tempItems);
    }
  };

  const handleMultiDownload = async () => {
    const selectedFiles = foldersAndFiles.files.filter((data) =>
      selectedItems.files.includes(data.file_id)
    );

    for (let i = 0; i < selectedFiles.length; i++) {
      const data = selectedFiles[i];
      folderService.downloadFile(data.s3_url, data.name);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    setSelectedItems({
      files: [],
      folders: [],
    });
  };

  const handleMultiDelete = () => {
    if (data.shared_with.length > 0) {
      setIsLoading(true);
      let promises = [];
      selectedItems.files.forEach((id) => {
        promises.push(handleDeleteFile(id));
      });
      selectedItems.folders.forEach((id) => {
        promises.push(handleDeleteFolder(id));
      });

      Promise.all(promises)
        .then(() => {
          setGlobalState("error", {
            open: true,
            message: "All items have been deleted successfully!",
            type: "success",
            code: 200,
          });
        })
        .catch((error) => {})
        .finally(() => {
          setShowMultiShare(false);
          setIsLoading(false);
          setSelectedItems({
            files: [],
            folders: [],
          });
        });
    }
  };

  const handleMultiShare = (data) => {
    if (data.shared_with.length > 0) {
      setIsLoading(true);

      let promises = [];
      // selectedItems.files.forEach((id) => {
      //   promises.push(handleShare(id, data.shared_with, "file"));
      // });
      selectedItems.folders.forEach((id) => {
        promises.push(handleShare(id, data.shared_with, "folder"));
      });

      Promise.all(promises)
        .then(() => {
          setGlobalState("error", {
            open: true,
            message: "All items have been shared successfully!",
            type: "success",
            code: 200,
          });
        })
        .catch((error) => {})
        .finally(() => {
          setShowMultiShare(false);
          setIsLoading(false);
          setSelectedItems({
            files: [],
            folders: [],
          });
        });
    }
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={{ ...styles.iconHolder, fontSize: "1.2em" }}>
            <span class="material-symbols-outlined">folder</span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Documents</MDBox>
        }
        rightContent={
          <MDBox sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <SearchBar
              onClick={() => {
                setShowSearchFilesDialog(true);
              }}
              placeholder={"Search Files"}
              sx={{ border: "none", backgroundColor: primary.light }}
              fontColor={primary.main}
            />
            <OutlinedButton
              name={"Flow Chart"}
              sx={{
                backgroundColor: showFlowChart ? palette.primary.light : "",
                height: "32px",
                borderRadius: "100px",
              }}
              onClick={() => {
                setShowFlowChart((pre) => !pre);
              }}
              icon={<span class="material-symbols-outlined">account_tree</span>}
            />
            <QuickActionPopup
              icon={
                <span
                  class="material-symbols-outlined"
                  style={{ color: primary.main, cursor: "pointer" }}
                >
                  more_vert
                </span>
              }
            >
              <TextWithIcon
                onClick={() => setShowCreateFolderDialog(true)}
                iconName={"create_new_folder"}
                title={"Create New Folder"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <TextWithIcon
                onClick={() => setShowFileUploadDialog(true)}
                iconName={"upload_file"}
                title={"Upload File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <TextWithIcon
                onClick={() => {
                  setShowShareWithDialog(foldersAndFiles);
                }}
                iconName={"group_add"}
                title={"Share Folder"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
            </QuickActionPopup>
          </MDBox>
        }
      />

      <MDBox
        sx={{
          borderRadius: "10px",
          backgroundColor: white.main,
          height: "calc(100% - 80px)",
          overflow: "auto",
          boxShadow: "0px 0px 8px 1px #0000000D",
          padding: "1rem",
        }}
      >
        {showFlowChart ? (
          <MDBox
            component={LoadingOverlay}
            active={isChartLoading}
            spinner
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <style jsx>{`
              .react-flow__pane {
                cursor: default;
              }
            `}</style>
            {nodes.length > 0 && edges.length > 0 && (
              <WorkFlow
                nodes={nodes}
                edges={edges}
                zoomOnScroll={true}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                isAutoLayout={true}
                showBackground={true}
                showControls={false}
                edgeOptions={defaultEdgeOptions}
                nodesDraggable={false}
                panOnDrag={true}
                layoutOptions={layoutOptions}
                nodeStyle={`
                                    padding: 0;
                                    border: none;
                                    width: auto;
                                    max-width: 300px;
                                    cursor: default;
                                  `}
                nodeDotStyle={`border:none; background-color: #00000000;`}
                key={edges + nodes}
              />
            )}
          </MDBox>
        ) : (
          <>
            <MDBox
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <TextWithIcon
                  onClick={() => handleFolderClick("")}
                  iconName={"home"}
                  title={"Organisation"}
                />
                {foldersAndFiles?.folder_location?.map((data, index) => (
                  <TextWithIcon
                    onClick={() => handleFolderClick(data.folder_id)}
                    pb={"2px"}
                    iconName={"chevron_right"}
                    title={data.folder_name}
                  />
                ))}

                {/* <QuickActionPopup
              icon={
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: "1.3em",
                    borderRadius: "100%",
                    padding: "3px",
                    color: primary.main,
                    backgroundColor: primary.light,
                    cursor: "pointer",
                  }}
                >
                  add
                </span>
              }
              sx={{
                left: "10px",
                top: "1.8em",
                width: "200px",
              }}
            >
              <TextWithIcon
                onClick={() => setShowCreateFolderDialog(true)}
                iconName={"create_new_folder"}
                title={"Create New Folder"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <TextWithIcon
                onClick={() => setShowFileUploadDialog(true)}
                iconName={"upload_file"}
                title={"Upload File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
            </QuickActionPopup> */}
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                {showMultiSelect && (
                  <MDBox
                    sx={{
                      height: "30px",
                      borderRadius: "100px",
                      background: grey[200],
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      // width: "220px",
                      padding: "5px",
                    }}
                  >
                    <MDBox color={text.secondary} sx={{ whiteSpace: "nowrap" }}>
                      {selectedItems.files.length +
                        selectedItems.folders.length}{" "}
                      selected
                    </MDBox>
                    <span
                      class="material-symbols-outlined"
                      onClick={() => {
                        selectedItems.files.length +
                          selectedItems.folders.length >
                        0
                          ? setShowMultiShare(true)
                          : () => {};
                      }}
                      style={{
                        color: text.secondary,
                        fontSize: "1.4em",
                        cursor: "pointer",
                      }}
                    >
                      group_add
                    </span>
                    <span
                      class="material-symbols-outlined"
                      onClick={() => {
                        selectedItems.files.length +
                          selectedItems.folders.length >
                        0
                          ? setShowMultiDelete(true)
                          : () => {};
                      }}
                      style={{
                        color: text.secondary,
                        fontSize: "1.4em",
                        cursor: "pointer",
                      }}
                    >
                      delete
                    </span>
                    {selectedItems.files.length > 0 && (
                      <span
                        class="material-symbols-outlined"
                        onClick={() => {
                          handleMultiDownload();
                        }}
                        style={{
                          color: text.secondary,
                          fontSize: "1.4em",
                          cursor: "pointer",
                        }}
                      >
                        download
                      </span>
                    )}
                    <Tooltip title={"Close"}>
                      <MDBox sx={{ display: "flex" }}>
                        <span
                          class="material-symbols-outlined"
                          onClick={() => {
                            setShowMultiSelect(false);
                            setSelectedItems({
                              files: [],
                              folders: [],
                            });
                          }}
                          style={{
                            color: text.secondary,
                            backgroundColor: white.main,
                            borderRadius: "100%",
                            fontSize: "1.2em",
                            padding: "3px",
                            cursor: "pointer",
                            "& :hover": {
                              backgroundColor: grey[200],
                            },
                          }}
                        >
                          close
                        </span>
                      </MDBox>
                    </Tooltip>
                  </MDBox>
                )}
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {!showMultiSelect && (
                    <span
                      class="material-symbols-outlined"
                      style={{
                        fontSize: "1.3em",
                        borderRadius: "100%",
                        padding: "3px",
                        color: primary.main,
                        backgroundColor: primary.light,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowMultiSelect(true);
                      }}
                    >
                      checklist_rtl
                    </span>
                  )}
                  <TextWithIcon
                    onClick={() => setShowCreateFolderDialog(true)}
                    iconName={"create_new_folder"}
                    title={"Create Folder"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      borderRadius: "100px",
                      padding: "3px 5px",
                      color: primary.main,
                      cursor: "pointer",
                    }}
                  />
                  <TextWithIcon
                    onClick={() => setShowFileUploadDialog(true)}
                    iconName={"upload"}
                    title={"Upload File"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      borderRadius: "100px",
                      padding: "3px 5px",
                      color: primary.main,
                      cursor: "pointer",
                    }}
                  />
                  <MDBox sx={{ display: "flex" }}>
                    <span
                      class="material-symbols-outlined"
                      style={{
                        fontSize: "1.3em",
                        borderRadius: "100%",
                        padding: "3px",
                        color: primary.main,
                        backgroundColor: islistView ? primary.light : "",
                        cursor: "pointer",
                        borderRadius: "100px 0 0 100px",
                        border: "1px solid " + primary.main,
                        paddingLeft: "10px",
                      }}
                      onClick={() => setIsListView(true)}
                    >
                      reorder
                    </span>
                    <span
                      class="material-symbols-outlined"
                      style={{
                        fontSize: "1.3em",
                        borderRadius: "100%",
                        padding: "3px",
                        color: primary.main,
                        borderRadius: "0 100px 100px 0",
                        backgroundColor: !islistView ? primary.light : "",
                        cursor: "pointer",
                        border: "1px solid " + primary.main,
                        paddingRight: "10px",
                        borderLeft: "none",
                      }}
                      onClick={() => setIsListView(false)}
                    >
                      grid_view
                    </span>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox sx={{ width: "100%" }}>
              {islistView ? (
                <ListView
                  showMultiSelect={showMultiSelect}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  foldersAndFiles={foldersAndFiles}
                  handleSelect={handleSelect}
                  handleFolderClick={handleFolderClick}
                  setPreviewFile={setPreviewFile}
                  setShowShareWithDialog={setShowShareWithDialog}
                  setShowDeleteFileDialog={setShowDeleteFileDialog}
                  setShowDeleteFolderDialog={setShowDeleteFolderDialog}
                  isLoading={isLoadingFolder}
                />
              ) : (
                <GridView
                  showMultiSelect={showMultiSelect}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  foldersAndFiles={foldersAndFiles}
                  handleSelect={handleSelect}
                  handleFolderClick={handleFolderClick}
                  setPreviewFile={setPreviewFile}
                  setShowShareWithDialog={setShowShareWithDialog}
                  setShowDeleteFileDialog={setShowDeleteFileDialog}
                  setShowDeleteFolderDialog={setShowDeleteFolderDialog}
                  isLoading={isLoadingFolder}
                />
              )}
            </MDBox>
          </>
        )}
      </MDBox>
      {previewFile && (
        <DialogWrapper handleClose={() => setPreviewFile(null)} width={"35%"}>
          <FileModel file={previewFile} />
        </DialogWrapper>
      )}

      {showCreateFolderDialog && (
        <DataInputDialog
          open={showCreateFolderDialog}
          handleClose={() => {
            setShowCreateFolderDialog(null);
          }}
          defaultInputs={defaultInputs}
          handleSubmit={(data) => {
            handleCreateFolder(data);
          }}
          buttonName={"Create Folder"}
          heading={"Create New Folder"}
          iconName={"create_new_folder"}
          isLoading={isLoading}
        />
      )}
      <Confirmation
        open={showDeleteFolderDialog}
        onClose={() => {
          setShowDeleteFolderDialog(null);
        }}
        onAccept={() =>
          handleDeleteFolder(showDeleteFolderDialog?.folder_id)
            .then(() => {
              setGlobalState("error", {
                open: true,
                message: "Folder deleted successfully!",
                type: "success",
                code: 200,
              });
            })
            .catch((e) => {})
        }
        acceptLable={"Delete"}
        title={`Do you really want to delete folder: ${showDeleteFolderDialog?.folder_name}?`}
        isLoading={isLoading}
      />
      <Confirmation
        open={showMultiDelete}
        onClose={() => {
          setShowMultiDelete(null);
        }}
        onAccept={() => handleMultiDelete()}
        acceptLable={"Delete"}
        title={`Do you really want to delete selected items`}
        isLoading={isLoading}
      />
      <Confirmation
        open={showDeleteFileDialog}
        onClose={() => {
          setShowDeleteFileDialog(null);
        }}
        onAccept={() =>
          handleDeleteFile(showDeleteFileDialog?.file_id)
            .then(() => {
              setGlobalState("error", {
                open: true,
                message: "File deleted successfully!",
                type: "success",
                code: 200,
              });
            })
            .catch(() => {})
        }
        acceptLable={"Delete"}
        title={`Do you really want to delete File: ${showDeleteFileDialog?.filename}?`}
        isLoading={isLoading}
      />
      {showFileUploadDialog && (
        <FileUploadDialog
          open={showFileUploadDialog}
          onClose={() => {
            setShowFileUploadDialog(false);
          }}
          multiFilesAllowed={true}
          handleUpload={handleUploadFile}
          isLoading={isLoading}
        />
      )}
      {showSearchFilesDialog && (
        <SearchFilesDialog
          open={showSearchFilesDialog}
          handleClose={() => {
            setShowSearchFilesDialog(false);
          }}
        />
      )}
      {showShareWithDialog && (
        <ShareDialog
          open={showShareWithDialog}
          topInfo={`${
            showShareWithDialog.owner_email ||
            selectedFolderDetails?.owner_email
          } (Owner)`}
          handleClose={() => {
            setShowShareWithDialog(null);
            setSelectedFolderDetails(null);
          }}
          defaultInputs={{
            shared_with: {
              ...defaultInputs.shared_with,
              value:
                selectedFolderDetails?.shared_with?.filter(
                  (id) =>
                    id !==
                    (showShareWithDialog.owner_email ||
                      selectedFolderDetails?.owner_email)
                ) || [],
            },
          }}
          shareWith={
            selectedFolderDetails?.shared_with?.filter(
              (id) =>
                id !==
                (showShareWithDialog.owner_email ||
                  selectedFolderDetails?.owner_email)
            ) || []
          }
          handleSubmit={(data, removedList) => {
            showShareWithDialog?.folder_id != null ||
            showShareWithDialog?.folder_id != undefined
              ? handleShare(
                  showShareWithDialog?.folder_id,
                  data.shared_with,
                  removedList,
                  "folder",
                  showShareWithDialog.owner_email ||
                    selectedFolderDetails?.owner_email
                )
                  .then(() => {
                    setGlobalState("error", {
                      open: true,
                      message: "Folder shared successfully!",
                      type: "success",
                      code: 200,
                    });
                  })
                  .catch(() => {})
              : handleShare(
                  showShareWithDialog?.file_id,
                  data.shared_with,
                  removedList,
                  "file",
                  showShareWithDialog.owner_email ||
                    selectedFolderDetails?.owner_email
                )
                  .then(() => {
                    setGlobalState("error", {
                      open: true,
                      message: "File shared successfully!",
                      type: "success",
                      code: 200,
                    });
                  })
                  .catch(() => {});
          }}
          buttonName={"Submit"}
          heading={
            showShareWithDialog?.folder_id != null ||
            showShareWithDialog?.folder_id != undefined
              ? `Share Folder (${
                  showShareWithDialog?.folder_name ||
                  selectedFolderDetails?.folder_name ||
                  "This"
                }) With`
              : `Share File (${showShareWithDialog?.filename}) With`
          }
          iconName={"group_add"}
          isLoading={isLoading}
        />
      )}
      {showMultiShare && (
        <DataInputDialog
          open={showMultiShare}
          handleClose={() => {
            setShowMultiShare(null);
          }}
          defaultInputs={{
            shared_with: {
              ...defaultInputs.shared_with,
              value: [],
            },
          }}
          handleSubmit={(data) => {
            handleMultiShare(data);
          }}
          buttonName={"Submit"}
          heading={"Share With"}
          iconName={"group_add"}
          isLoading={isLoading}
        />
      )}
    </DashboardLayout>
  );
};

export default Folders;