import { integrationAPI } from "api";

export const databaseService = {
  getIngestions(data) {
    return integrationAPI.getIngestions(data);
  },
  getIngestionDetails(ingestionId, dbType, sourceId) {
    return integrationAPI.getIngestionDetails({
      filters: { ingestion_id: ingestionId },
      source_type: dbType,
      id: sourceId,
    });
  },
  initiateIngestion(dbType, sourceId) {
    return integrationAPI.initiateIngestion({
      source_type: dbType,
      id: sourceId,
    });
  },
  getDatabaseFilters(dbType, sourceId) {
    return integrationAPI.getDatabaseFilters({
      id: sourceId,
      source_type: dbType,
    });
  },
  getIngestionSchedules(sourceId) {
    return integrationAPI.getIngestionSchedules({
      id: sourceId,
    });
  },
  createIngestionSchedules(name, intervalInMinutes, isEnabled, sourceId) {
    return integrationAPI.createIngestionSchedules({
      name: name || "",
      schedule_expression: `rate(${intervalInMinutes} minutes)`,
      enabled: isEnabled,
      id: sourceId,
    });
  },
  updateIngestionSchedules(name, intervalInMinutes, isEnabled, sourceId) {
    return integrationAPI.updateIngestionSchedules({
      name: name || "",
      schedule_expression: `rate(${intervalInMinutes} minutes)`,
      enabled: isEnabled,
      id: sourceId,
    });
  },
};
