import { useEffect, useState } from "react";
import { folderService } from "../service/folderService";
import { teamsManagementAPI } from "api";
import { useNavigate } from "react-router-dom";

const useFolder = (id) => {
  const [foldersAndFiles, setFoldersAndFiles] = useState({
    folder_id: "",
    subfolders: [],
    files: [],
    members: [],
    folder_location: [],
  });
  const [users, setUsers] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [isLoadingFolder, setIsLoadingFolder] = useState(false);
  const navigation = useNavigate();

  const getFolderAndFilesDetails = (folder_id) => {
    setIsLoadingFolder(true);
    folderService
      .getFolderAndFilesDetails({
        folder_id: folder_id || "",
      })
      .then((res) => {
        if (res.status == 200) {
          setFoldersAndFiles({
            ...res.data,
            folder_location: res.data.folder_location.reverse(),
          });
        }
      })
      .catch((err) => {
        navigation("/folders/" + foldersAndFiles.folder_id);
      })
      .finally(() => {
        setIsLoadingFolder(false);
      });
  };

  useEffect(() => {
    getFolderAndFilesDetails(id);
  }, [id]);

  useEffect(() => {
    getAllTeams();
    getAllUsers();
  }, []);

  const getAllTeams = () => {
    teamsManagementAPI
      .getAllTeams()
      .then((res) => {
        if (res.status == 200) {
          const tempdata = res.data.map((data) => ({
            _id: data._id,
            name: data.team_name,
          }));
          setTeamList(tempdata);
        }
      })
      .catch(() => {});
  };

  const getAllUsers = () => {
    folderService
      .getAllUsers()
      .then((res) => {
        if (res.status == 200) {
          setUsers(res.data);
        }
      })
      .catch((err) => {});
  };

  return {
    foldersAndFiles,
    setFoldersAndFiles,
    users,
    teamList,
    isLoadingFolder,
    getFolderAndFilesDetails,
  };
};

export default useFolder;
