import { integrationAPI } from "api";

export const emailService = {
  getAllEmails(data) {
    return integrationAPI.getAllEmails(data);
  },
  getAttachmentDetails(id, sourceId) {
    return integrationAPI.getAttachmentDetails(id, sourceId);
  },
  getEmailFilters(sourceType, sourceId){
    return integrationAPI.getEmailFilters(sourceType, sourceId)
  },
};
