import { useTheme } from "@emotion/react";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import MDBox from "components/MDBox";
import { convertKeyToName } from "utils/conversion";

export default function CustomDateRangePicker({
  name,
  label = "hello",
  value,
  onChange,
  disabled,
  type = "mobile",
  size,
}) {
  const [date, setDate] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (value) {
      setDate({
        from:
          value?.from && dayjs(value?.from).isValid()
            ? dayjs(value?.from)
            : null,
        to: value?.to && dayjs(value?.to).isValid() ? dayjs(value?.to) : null,
      });
    }
  }, [value]);

  const handleChange = (updatedDate, type) => {
    const newDate = {
      ...date,
      [type]: updatedDate,
    };
    setDate(newDate);
    onChange({
      target: {
        name: name,
        value: {
          from: newDate?.from ? format(newDate.from, "yyyy-MM-dd") : "",
          to: newDate?.to ? format(newDate.to, "yyyy-MM-dd") : "",
        },
      },
    });
  };

  const { palette } = useTheme();
  const { primary, text, inputBorderColor } = palette;

  const style = {
    margin: "10px 0",
    "& input": {
      ...(size === "small"
        ? {
            fontSize: "12px",
            padding: "8.3px",
          }
        : {}),
    },
    "& label": {
      ...(size === "small"
        ? {
            fontSize: "11px",
            padding: "8.3px 5px 0 5px",
            top: "-.4em",
          }
        : {}),
    },
    "& .MuiOutlinedInput-root": {
      "& input, svg": {
        color: text.secondary,
        fill: text.disabled,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: inputBorderColor,
        color: text.disabled,
      },
      "&.Mui-focused fieldset": {
        border: "2px solid " + primary.main,
      },
    },
  };

  return (
    <MDBox sx={{ position: "relative", whiteSpace: "nowrap", width: '100%' }}>
      {label && (
        <MDBox
          sx={{
            position: "absolute",
            top: "0.2em",
            fontSize: ".9em",
            padding: "2px 5px",
            backgroundColor: palette.white.main,
            marginLeft: "10px",
            lineHeight: "1em",
            zIndex: "2",
            color: palette.text.disabled,
            ...style["& label"],
          }}
        >
          {convertKeyToName(label)}
        </MDBox>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          sx={{
            width: '50%',
            minWidth: size === "small" ? "90px" : "115px",
            ...style,
            "& .MuiInputBase-root": { padding: "0" },
            "& input": {
              textAlign: "right",
              color: palette.text.disabled,
              borderRadius: "0.375rem 0 0 0.375rem",
              ...style["& input"],
            },
            "& fieldset": {
              borderRadius: "0.375rem 0 0 0.375rem",
              borderRight: "0",
              borderColor: palette.text.light,
              padding: "0",
            },
          }}
          disabled={disabled}
          name={name}
          value={date?.from}
          onAccept={(date) => handleChange(date, "from")}
          format="DD/MM/YYYY"
        />
        <MobileDatePicker
          format="DD/MM/YYYY"
          value={date?.to}
          onAccept={(date) => handleChange(date, "to")}
          disabled={disabled}
          name={name}
          sx={{
            width: '50%',
            minWidth: size === "small" ? "90px" : "115px",
            ...style,
            "& .MuiInputBase-root": { padding: "0" },
            "& input": {
              color: palette.text.disabled,
              borderRadius: "0 0.375rem 0.375rem 0",
              backgroundColor: palette.white.main,
              ...style["& input"],
            },
            "& fieldset": {
              borderRadius: "0 0.375rem 0.375rem 0",
              borderLeft: "0",
              borderColor: palette.text.light,
              padding: "0",
            },
            "&::after": {
              content: "'--'",
              position: "absolute",
              letterSpacing: "-2px",
              left: "-3px",
              fontSize: "1em",
              top: size === "small" ? "6px" : "11px",
              color: palette.text.disabled,
            },
          }}
        />
      </LocalizationProvider>
    </MDBox>
  );
}
