import { useEffect, useState } from "react";
import { orderService } from "../service/ordersService";

const initialFilters = {
  limit: 50,
  offset: 0,
};

export const useOrders = () => {
  const [filterOptions, setFilterOptions] = useState(null);
  const [filters, setFilters] = useState(null);
  const [orderByStatus, setOrderByStatus] = useState(null);

  useEffect(() => {
    orderService.getOrdersByStatus({}).then((data) => {
      setOrderByStatus(data);
    });
    getFilterValues();
  }, []);

  const getFilterValues = () => {
    orderService
      .getFilterOptions()
      .then((res) => {
        if (res.status === 200) {
          const tempFilters = [];
          res.data.forEach((data) => {
            tempFilters[data.field] = data.options ? [] : "";
          });
          setFilterOptions(res.data || []);
          setFilters((pre) => ({ ...pre, ...tempFilters }));
        }
      })
      .catch(() => {});
  };

  return {
    filterOptions,
    filters,
    orderByStatus,
    setFilters
  };
};
