import { useTheme } from "@emotion/react";
import { Dialog } from "@mui/material";
import { integrationAPI } from "api";
import { controlTypes } from "components/controls/Controls";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import FormBuilder from "examples/formBuilder/FormBuilder";
import { setGlobalState } from "Global";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import { convertKeyToName } from "utils/conversion";

const IconCard = ({
  iconColor,
  iconName,
  iconUrl,
  title,
  isActive,
  path,
  count,
  getIntegrationList,
  sx,
}) => {
  const { palette, typography } = useTheme();
  const { white, text, success, warning } = palette;
  const [showCreateSourceDialog, setShowCreateSourceDialog] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tempCreateSourceFields, setTempCreateSourceFields] = useState({});
  const [showFormBuilderCreateSource, setShowFormBuilderCreateSource] =
    useState(false);

  const createSource = (name, description, target, fields) => {
    let tempFields = [];
    const type = title;
    Object.keys(fields).forEach((key, index) => {
      tempFields.push({
        field: fields[key].name,
        ...fields[key],
      });
    });
    integrationAPI
      .createSource(type, name, description, target, tempFields)
      .then((res) => {
        if (res.status == 200) {
          getIntegrationList();
          setGlobalState("error", {
            open: true,
            message: message || "Source created successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setTempCreateSourceFields({});
        setShowCreateSourceDialog(false);
      });
  };

  return (
    <MDBox component={isActive ? Link : "div"} to={path}>
      <MDBox
        sx={{
          minHeight: "130px",
          height: "100%",
          minWidth: "100px",
          borderRadius: "10px",
          boxShadow: "0px 0px 8px 1px #0000000D",
          padding: "13px",
          backgroundColor: white.main,
          ...sx,
        }}
      >
        <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
          <MDBox
            sx={{
              backgroundColor: isActive ? "#d6f7ef" : "#fff1e2",
              cursor: isActive ? "default" : "pointer",
              borderRadius: "100px",
              padding: "2px 10px",
              fontSize: "12px",
            }}
            onClick={
              isActive
                ? () => {}
                : () => {
                    setShowCreateSourceDialog(true);
                  }
            }
            color={isActive ? success.main : warning.main}
          >
            {isActive ? "Active" : "Connect"}
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            gap: "1rem",
          }}
        >
          {iconUrl ? (
            <MDBox
              component={"img"}
              src={iconUrl}
              sx={{
                height: "35px",
              }}
            />
          ) : (
            <MDBox
              mb={1}
              ml={0.5}
              sx={{
                color: iconColor,
                cursor: "pointer",
                fontSize: "1.3em",
                height: "30px",
                width: "30px",
                display: "grid",
                placeItems: "center",
                position: "relative",
                scale: 1.3,
                "&::after": {
                  position: "absolute",
                  content: '""',
                  height: "30px",
                  width: "30px",
                  backgroundColor: iconColor,
                  borderRadius: ".5em",
                  opacity: 0.2,
                },
              }}
            >
              <span class="material-symbols-outlined">{iconName}</span>
            </MDBox>
          )}
          <MDBox
            sx={{
              fontSize: typography.size["lg"],
              fontWeight: "bold",
            }}
            color={text.secondary}
          >
            {convertKeyToName(title)}
          </MDBox>
        </MDBox>
        <MDBox
          color={text.disabled}
          sx={{ fontSize: "12px", lineHeight: "1em" }}
        >
          Source count: {count || 0}
        </MDBox>
      </MDBox>
      {showCreateSourceDialog && (
        <DataInputDialog
          open={showCreateSourceDialog}
          handleClose={() => {
            setShowCreateSourceDialog(false);
          }}
          defaultInputs={{
            name: {
              type: "Text",
              editable: true,
              required: true,
            },
            description: {
              type: "Text",
              editable: true,
              required: true,
            },
            target: {
              type: "Text",
              editable: true,
              required: true,
            },
          }}
          handleSubmit={(data) => {
            const { name, description, target } = data;
            createSource(name, description, target, tempCreateSourceFields);
          }}
          buttonName={"Create"}
          heading={"Create Source"}
          iconName={"add"}
          isLoading={isLoading}
          doubleColAfterInputs={10}
          bottomInfo={
            <>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  fontSize: typography.size["sm"],
                  fontWeight: typography.fontWeightBold,
                }}
                py={1}
                pl={2}
              >
                <MDBox sx={{ fontSize: "inherit" }}>
                  Form Fields (
                  {Object.keys(tempCreateSourceFields)?.length || 0})
                </MDBox>
                <OutlinedButton
                  name={" Show"}
                  onClick={() => setShowFormBuilderCreateSource(true)}
                  sx={{
                    height: "30px",
                    borderRadius: "100px",
                    paddingLeft: "13px",
                  }}
                  icon={<></>}
                />
              </MDBox>
              <Dialog
                open={showFormBuilderCreateSource}
                onClose={() => setShowFormBuilderCreateSource(false)}
                sx={{
                  "& .MuiPaper-root": {
                    maxWidth: "700px",
                    width: "70vw",
                    height: "70vh",
                  },
                }}
              >
                <style jsx>{`
                  ._loading_overlay_wrapper {
                    height: 100%;
                  }
                `}</style>
                <LoadingOverlay active={isLoading} spinner>
                  <MDBox sx={{ minWidth: "30rem", height: "100%" }}>
                    <FormBuilder
                      defaultInputs={tempCreateSourceFields}
                      inputTypes={[
                        controlTypes.text,
                        controlTypes.dropdown,
                        controlTypes.integer,
                        controlTypes.date,
                      ]}
                      handleSubmit={(data) => {
                        setTempCreateSourceFields(data);
                        setShowFormBuilderCreateSource(false);
                      }}
                      heading={"Form Builder"}
                      handleClose={() => {
                        setShowFormBuilderCreateSource(false);
                      }}
                    />
                  </MDBox>
                </LoadingOverlay>
              </Dialog>
            </>
          }
        />
      )}
    </MDBox>
  );
};

export default IconCard;
