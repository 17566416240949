import { useTheme } from "@emotion/react";
import { Collapse, Icon, Switch, Tooltip } from "@mui/material";
import DateRangePicker from "components/controls/DateRangePicker";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ChartHolder from "./components/ChartHolder";
import InfoCard from "./components/InfoCard";
import ObjectToTable from "./components/ObjectToTable";
import { useOrders } from "./hooks/useOrders";
import { orderService } from "./service/ordersService";
import { getDateRange, handleFilterChangeCustom } from "./utils";
import { OutlinedButton } from "components/MDButton/button";
import Controls from "components/controls/Controls";
import Dropdown from "components/controls/Dropdown";
import { convertKeyToName } from "utils/conversion";
import MDChip from "components/MDChip";
const ignoreFilterList = [
  "days",
  "limit",
  "offset",
  "search",
  "from_date",
  "to_date",
  "category",
  "sku",
];

const ResponsiveGridLayout = WidthProvider(Responsive);

const initialLayout = [
  { i: "ordersbyTime", x: 0, y: 0, w: 8, h: 6 },
  { i: "BOOKED", x: 8, y: 0, w: 2, h: 3 },
  { i: "CANCELLED", x: 10, y: 0, w: 2, h: 3 },
  { i: "SHIPPED", x: 8, y: 3, w: 2, h: 3 },
  { i: "DELIVERED", x: 10, y: 3, w: 2, h: 3 },

  { i: "unitStatus", x: 0, y: 6, w: 4, h: 5 },
  { i: "product_category_performance", x: 4, y: 6, w: 8, h: 5 },

  { i: "top_customers", x: 0, y: 11, w: 6, h: 7 },
  { i: "warehouse_performance", x: 6, y: 11, w: 6, h: 7 },

  { i: "Top Products by Sales", x: 0, y: 18, w: 4, h: 6 },
  { i: "orders_by_status", x: 4, y: 18, w: 4, h: 6 },
  { i: "payment_method_distribution", x: 8, y: 18, w: 4, h: 6 },

  { i: "discount_analysis", x: 0, y: 24, w: 8, h: 6 },
  { i: "orderByorigin", x: 8, y: 24, w: 4, h: 6 },
];

const OrdersDashboard = ({ imageRef, tabComponent }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  // const { layout, onLayoutChange } = useGridLayout(initialLayout);
  const { filterOptions, filters, orderByStatus, setFilters } = useOrders();
  const [showFilters, setShowFilters] = useState(false);
  const [tempFilters, setTempFilters] = useState({});

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const [globalDateRange, setGlobalDateRange] = useState({});
  const [enableDrag, setEnableDrag] = useState(false);

  useEffect(() => {
    if (filters) {
      setTempFilters(filters);
    }
  }, [filters]);

  const handleFilterChange = (event, type) => {
    setTempFilters(handleFilterChangeCustom(event, tempFilters, type));
  };

  const cardStyle = {
    borderRadius: "10px",
    backgroundColor: white.main,
    boxShadow: "0px 0px 8px 1px #0000000D",
  };

  const handleSelectTab = (tab) => {
    if (tab === "Clear") {
      setActiveTab("");
      setGlobalDateRange({});
    } else {
      setActiveTab(tab);
      setGlobalDateRange(getDateRange(tab));
    }
  };
  const handleDateRangeChange = (type, date) => {
    const tDate = new Date(date);
    setActiveTab("Custom");
    if (type === "from") {
      setGlobalDateRange((pre) => ({ ...pre, from: tDate?.toISOString() }));
    } else {
      setGlobalDateRange((pre) => ({ ...pre, to: tDate?.toISOString() }));
    }
  };

  return (
    <>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          {tabComponent}
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1em",
            gap: "5px",
          }}
        >
          <OutlinedButton
            sx={{
              backgroundColor: showFilters ? "#D6D9FF" : white.main,
              border: "none",
              borderRadius: "1em",
            }}
            onClick={() => setShowFilters(!showFilters)}
            name={"Filter"}
            icon={<Icon fontSize="1em">filter_alt</Icon>}
          />
          {tabs.map((tab) => (
            <MDBox
              sx={{
                padding: "0.5em 1em",
                textAlign: "center",
                borderRadius: "1em",
                cursor: "pointer",
                backgroundColor: activeTab === tab ? primary.main : white.main,
              }}
              color={activeTab === tab ? white.main : text.disabled}
              onClick={() => handleSelectTab(tab)}
            >
              {tab}
            </MDBox>
          ))}
          <MDBox>
            <DateRangePicker
              from={globalDateRange?.from}
              to={globalDateRange?.to}
              onChangeFrom={(date) => handleDateRangeChange("from", date)}
              onChangeTo={(date) => handleDateRangeChange("to", date)}
            />
          </MDBox>
          <Tooltip title="Customize Layout">
            <Switch
              onChange={(e, value) => {
                setEnableDrag(value);
              }}
              value={enableDrag}
              sx={{
                "& .Mui-checked .MuiSwitch-thumb": {
                  borderColor: primary.main + " !important",
                },
                "& .Mui-checked+.MuiSwitch-track": {
                  borderColor: primary.main + " !important",
                  backgroundColor: primary.main + " !important",
                },
              }}
            />
          </Tooltip>
        </MDBox>
      </MDBox>
      {filterOptions && filters && (
        <Collapse in={showFilters}>
          <MDBox
            sx={{
              display: "flex",
              backgroundColor: white.main,
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1rem",
              boxShadow: "0px 0px 8px 1px #0000000D",
            }}
          >
            <MDBox
              sx={{
                width: "100%",
                display: "grid",
                columnGap: "1rem",
                gridTemplateColumns: "repeat(3, 1fr)",
                "@media (max-width: 800px)": {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
              }}
            >
              {filterOptions?.map((input, index) => {
                const filterName = input?.field || input?.name;
                if (!ignoreFilterList.includes()) {
                  return (
                    <Controls
                      type={input?.type}
                      DefaultType={Dropdown}
                      key={index}
                      name={filterName}
                      value={tempFilters[filterName]}
                      onChange={handleFilterChange}
                      dataKey={"value"}
                      options={input?.options || []}
                      getOptionLabel={(option) => {
                        return option?.name ? option.name : option;
                      }}
                      placeholder={
                        tempFilters[filterName] != "" ? "" : "Select"
                      }
                      label={convertKeyToName(filterName)}
                      size={"small"}
                    />
                  );
                }
              })}
            </MDBox>
            <MDBox
              sx={{
                width: "30%",
                display: "flex",
                alignItems: "flex-end",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <MDBox
                color={primary.main}
                onClick={() => setShowFilters(!showFilters)}
                sx={{
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                x Close
              </MDBox>
              <MDChip
                sx={{
                  backgroundColor: secondary.main,
                  padding: "5px 40px",
                  color: white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: "14px",
                  height: "40px",
                  borderRadius: "100px",
                  marginBottom: "10px",
                }}
                label={"Apply All Filters"}
                onClick={() => {
                  setFilters(tempFilters);
                }}
              />
            </MDBox>
          </MDBox>
        </Collapse>
      )}
      <MDBox ref={imageRef}>
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: initialLayout }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 12 }}
          rowHeight={50}
          margin={[15, 15]}
          // onLayoutChange={onLayoutChange}
        >
          <MDBox sx={cardStyle} key="ordersbyTime">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"No. of Orders Over Time"}
              id={"ordersbyTime"}
              initialFilters={filters}
              getData={orderService.getOrdersOverTime}
              // showFilter={false}
              chartProps={{
                grid: { vertical: false },
                type: "area",
                graph: {
                  order_total: {
                    fillOpacity: 0.3,
                    type: "monotone",
                    strokeWidth: 2,
                  },
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="BOOKED">
            <InfoCard
              enableDrag={enableDrag}
              title={"Booked"}
              iconName={"star"}
              iconColor={"#676DFF"}
              mainDataName={"Quantity"}
              mainData={orderByStatus?.[0]?.unit_quantity}
              subDataName={"Order Total"}
              subData={orderByStatus?.[0]?.order_total}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="CANCELLED">
            <InfoCard
              enableDrag={enableDrag}
              title={"Cancelled"}
              iconName={"cancel"}
              iconColor={"#EF5350"}
              mainDataName={"Quantity"}
              mainData={orderByStatus?.[1]?.unit_quantity}
              subDataName={"Order Total"}
              subData={orderByStatus?.[1]?.order_total}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="SHIPPED">
            <InfoCard
              enableDrag={enableDrag}
              title={"Shipped"}
              iconName={"local_shipping"}
              iconColor={"#EF6C00"}
              mainDataName={"Quantity"}
              mainData={orderByStatus?.[2]?.unit_quantity}
              subDataName={"Order Total"}
              subData={orderByStatus?.[2]?.order_total}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="DELIVERED">
            <InfoCard
              enableDrag={enableDrag}
              title={"Delivered"}
              iconName={"assignment_turned_in"}
              iconColor={"#33C23A"}
              mainDataName={"Quantity"}
              mainData={orderByStatus?.[3]?.unit_quantity}
              subDataName={"Order Total"}
              subData={orderByStatus?.[3]?.order_total}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="unitStatus">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Inventory Status"}
              initialFilters={filters}
              getData={orderService.getUnitStatusDistribution}
              id={"unitStatus"}
              // showFilter={false}
              chartProps={{
                type: "pie",
                dataKey: "count",
                graph: {
                  innerRadius: 40,
                  outerRadius: 70,
                  paddingAngle: 3,
                  showPercentage: false,
                },
                legend: {
                  show: true,
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="orderByorigin">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Order Channel Breakdown"}
              getData={orderService.getOrdersByOrigin}
              initialFilters={filters}
              id={"orderByorigin"}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                radius: 0,
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="product_category_performance">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              initialFilters={filters}
              title={"Top Products by Sales"}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                radius: 0,
              }}
              id={"product_category_performance"}
              getData={orderService.getProductCategoryPerformance}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="top_customers">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              initialFilters={filters}
              title={"Top Customers by Revenue"}
              getData={orderService.getTopCustomers}
              id={"top_customers"}
              CustomComponent={ObjectToTable}
              customComponentProps={{
                sx: { height: "100%", overflow: "auto", marginLeft: "1.5em" },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="warehouse_performance">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Warehouse Performance"}
              id={"warehouse_performance"}
              initialFilters={filters}
              getData={orderService.getWarehousePerformance}
              chartProps={{
                type: "scatter",
                grid: { vertical: false },
                xAxis: {
                  type: "category",
                  dataKey: "warehouse_name",
                  hide: true,
                },
                yAxis: { dataKey: "unit_quantity" },
                zAxis: { dataKey: "order_total" },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="Top Products by Sales">
            <ChartHolder
              initialFilters={filters}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Top Products by Sales"}
              id={"Top Products by Sales"}
              getData={orderService.getProductCategoryPerformance}
              chartProps={{
                type: "pie",
                grid: { vertical: false },
                graph: {
                  outerRadius: 100,
                  showPercentage: false,
                  paddingAngle: 0,
                },
                legend: {
                  show: false,
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="orders_by_status">
            <ChartHolder
              initialFilters={filters}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              id={"orders_by_status"}
              filterOptions={filterOptions}
              handleFilterChange={() => {}}
              title={"Order Status Distribution"}
              getData={orderService.getOrdersByStatus}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                variant: "horizontal",
                radius: 0,
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="payment_method_distribution">
            <ChartHolder
              initialFilters={filters}
              enableDrag={enableDrag}
              id={"payment_method_distribution"}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Payment Method Distribution"}
              getData={orderService.getPaymentMethodDistribution}
              chartProps={{
                type: "pie",
                dataKey: "count",
                graph: {
                  innerRadius: 40,
                  outerRadius: 70,
                  paddingAngle: 3,
                  showPercentage: false,
                },
                legend: {
                  show: true,
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="discount_analysis">
            <ChartHolder
              initialFilters={filters}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Discount Effectiveness"}
              id={"discount_analysis"}
              getData={orderService.getDiscountAnalysis}
              chartProps={{
                type: "scatter",
                grid: { vertical: false },
                xAxis: { type: "category", dataKey: "name", hide: true },
                yAxis: { dataKey: "count" },
                // zAxis: { dataKey: "count" },
              }}
            ></ChartHolder>
          </MDBox>
        </ResponsiveGridLayout>
      </MDBox>
    </>
  );
};

export default OrdersDashboard;
