import { useTheme } from "@emotion/react";
import { deliveryAPI } from "api";
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import Confirmation from "examples/dialogs/Confirmation";
import FileUploadDialog from "examples/dialogs/FileUploadDialog";
import SearchBar from "examples/searchBar/SearchBar";
import { setGlobalState } from "Global";
import FileModel from "layouts/folders/components/FileViewer";
import GridView from "layouts/folders/components/GridView";
import SearchFilesDialog from "layouts/folders/components/SearchFilesDialog";
import { folderService } from "layouts/folders/service/folderService";
import { useEffect, useState } from "react";
import DialogWrapper from "utils/dialog/dialogWrapper";

const Documents = ({
  deliveryId,
  deliveryFiles,
  setDeliveryFiles,
  deliveryOverview,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [showSearchFilesDialog, setShowSearchFilesDialog] = useState(false);
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(null);

  const [previewFile, setPreviewFile] = useState(null);

  const handleUploadFile = (files, fileData = []) => {
    setIsLoading(true);
    const fileIdsList = fileData?.map((data) => data.file_id);
    deliveryAPI
      .uploadFiles(deliveryId, files, fileIdsList)
      .then((res) => {
        if (res.status === 200) {
          getDeliveryFiles(deliveryId);
          setGlobalState("error", {
            open: true,
            message: "Files uploaded successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setShowFileUploadDialog(false);
        setShowSearchFilesDialog(false);
        setIsLoading(false);
      });
  };

  const handleDeleteFile = (id) => {
    setIsLoading(true);
    deliveryAPI
      .deleteFile(deliveryId, id)
      .then((res) => {
        if (res.status == 200) {
          const tempData = deliveryFiles.filter((data) => data.file_id !== id);
          setDeliveryFiles(tempData);
          setGlobalState("error", {
            open: true,
            message: "File deleted successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setShowDeleteFileDialog(null);
      });
  };

  const getDeliveryFiles = (deliveryId) => {
    setIsLoading(true);
    deliveryAPI
      .getDeliveryFiles(deliveryId)
      .then((res) => {
        if (res.status === 200) {
          setDeliveryFiles(
            res.data.map((data) => ({ ...data, file_id: data._id }))
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        setShowDeleteFileDialog(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (deliveryFiles?.length === 0) {
      getDeliveryFiles(deliveryId);
    }
  }, [deliveryId]);

  const autoSearchFileAndUpload = () => {
    if (deliveryOverview?.length > 0) {
      setIsLoading(true);
      let tempValue = deliveryOverview?.map((data) => {
        if (data.value) {
          return data.value;
        }
      });
      const query = tempValue?.join(" ");
      folderService
        .searchFiles(query, true)
        .then((res) => {
          if (res.status === 200) {
            const resData = [
              ...res.data.initial_response,
              ...res.data.llm_response,
            ];
            if (resData?.length > 0) {
              handleUploadFile([], resData.slice(0, 3));
              setGlobalState("error", {
                open: true,
                message: "Recived AI Suggested Files",
                type: "success",
                code: 200,
              });
            } else {
              setGlobalState("error", {
                open: true,
                message: "AI Not Able To Find Suggetions",
                type: "error",
              });
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <MDBox
      sx={{
        padding: ".5rem 1rem",
        height: "100%",
        position: "relative",
      }}
    >
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ gap: "1rem" }}
      >
        <SearchBar
          onClick={() => {
            setShowSearchFilesDialog(true);
          }}
          placeholder={"Search files in docstore"}
          sx={{
            border: "none",
            backgroundColor: primary.light,
            width: "250px",
          }}
          fontColor={primary.main}
        />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ gap: "1rem" }}
        >
          <MDChip
            sx={{
              backgroundColor: primary.main,
              padding: "5px",
              color: white.main,
              fontWeight: "bold",
              cursor: "pointer",
              // width: "90px",
            }}
            label={"AI Auto Upload"}
            onClick={() => autoSearchFileAndUpload()}
          />
          <MDChip
            sx={{
              backgroundColor: primary.main,
              padding: "5px",
              color: white.main,
              fontWeight: "bold",
              cursor: "pointer",
              // width: "90px",
            }}
            label={"Upload Files"}
            onClick={() => setShowFileUploadDialog(true)}
          />
        </MDBox>
      </MDBox>
      <MDBox>
        <GridView
          isLoading={isLoading}
          foldersAndFiles={{
            files: deliveryFiles || [],
            subfolders: [],
          }}
          setShowDeleteFileDialog={setShowDeleteFileDialog}
          setPreviewFile={setPreviewFile}
          showEmpty={true}
          sx={{
            gridTemplateColumns: "repeat(3, 1fr)",
            "@media (max-width: 1150px)": {
              gridTemplateColumns: "repeat(2, 1fr)",
            },
            "@media (max-width: 950px)": {
              gridTemplateColumns: "repeat(1, 1fr)",
            },
          }}
        />
      </MDBox>
      {showFileUploadDialog && (
        <FileUploadDialog
          open={showFileUploadDialog}
          onClose={() => {
            setShowFileUploadDialog(false);
          }}
          multiFilesAllowed={true}
          handleUpload={(files) => handleUploadFile(files)}
          isLoading={isLoading}
        />
      )}
      {showSearchFilesDialog && (
        <SearchFilesDialog
          open={showSearchFilesDialog}
          handleClose={() => {
            setShowSearchFilesDialog(false);
          }}
          handleUpload={(files) => handleUploadFile([], files)}
          isUploadLoading={isLoading}
        />
      )}
      {previewFile && (
        <DialogWrapper handleClose={() => setPreviewFile(null)} width={"35%"}>
          <FileModel file={previewFile} />
        </DialogWrapper>
      )}
      <Confirmation
        open={showDeleteFileDialog}
        onClose={() => {
          setShowDeleteFileDialog(null);
        }}
        onAccept={() => handleDeleteFile(showDeleteFileDialog?.file_id)}
        acceptLable={"Delete"}
        title={`Do you really want to delete File: ${showDeleteFileDialog?.filename}?`}
        isLoading={isLoading}
      />
    </MDBox>
  );
};

export default Documents;
