import { useTheme } from "@emotion/react";
import { Collapse, Icon, Switch, Tooltip } from "@mui/material";
import DateRangePicker from "components/controls/DateRangePicker";
import MDBox from "components/MDBox";
import DraggableChart from "examples/chart/DraggableChart";
import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ChartHolder from "./components/ChartHolder";
import { useOrderForecast } from "./hooks/useOrderForecast";
import { orderForecastService } from "./service/orderForecastService";
import { formatForecastTableData, getDateRange, handleFilterChangeCustom } from "./utils";
import { OutlinedButton } from "components/MDButton/button";
import Controls from "components/controls/Controls";
import Dropdown from "components/controls/Dropdown";
import { convertKeyToName } from "utils/conversion";
import MDChip from "components/MDChip";
import WorldMap from "examples/worldMap/WorldMap";
const ResponsiveGridLayout = WidthProvider(Responsive);

const initialLayout = [
  { i: "demandForecast", x: 0, y: 0, w: 6, h: 7 },
  { i: "seasonalDecomposition", x: 6, y: 0, w: 6, h: 7 },
  { i: "regionWiseDemand", x: 0, y: 7, w: 12, h: 7 },
];
const ignoreFilterList = [
  "days",
  "limit",
  "offset",
  "search",
  "from_date",
  "to_date",
  "category",
  "sku",
];

const OrdersForecastDashboard = ({ imageRef, tabComponent }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  // const { layout, onLayoutChange } = useGridLayout(initialLayout);
  const { filterOptions, filters, setFilters } = useOrderForecast();

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const [globalDateRange, setGlobalDateRange] = useState({});
  const [enableDrag, setEnableDrag] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [tempFilters, setTempFilters] = useState({});

  const cardStyle = {
    borderRadius: "10px",
    backgroundColor: white.main,
    boxShadow: "0px 0px 8px 1px #0000000D",
  };

  const handleSelectTab = (tab) => {
    if (tab === "Clear") {
      setActiveTab("");
      setGlobalDateRange({});
    } else {
      setActiveTab(tab);
      setGlobalDateRange(getDateRange(tab));
    }
  };
  const handleDateRangeChange = (type, date) => {
    const tDate = new Date(date);
    setActiveTab("Custom");
    if (type === "from") {
      setGlobalDateRange((pre) => ({ ...pre, from: tDate?.toISOString() }));
    } else {
      setGlobalDateRange((pre) => ({ ...pre, to: tDate?.toISOString() }));
    }
  };

  useEffect(() => {
    if(filters){
      setTempFilters(filters)
    }
  }, [filters])

  const handleFilterChange = (event, type) => {
    setTempFilters(handleFilterChangeCustom(event, tempFilters, type));
  };

  return (
    <>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          {tabComponent}
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1em",
            gap: "5px",
          }}
        >
          <OutlinedButton
            sx={{
              backgroundColor: showFilters ? "#D6D9FF" : white.main,
              border: "none",
              borderRadius: "1em",
            }}
            onClick={() => setShowFilters(!showFilters)}
            name={"Filter"}
            icon={<Icon fontSize="1em">filter_alt</Icon>}
          />
          {tabs.map((tab) => (
            <MDBox
              sx={{
                padding: "0.5em 1em",
                textAlign: "center",
                borderRadius: "1em",
                cursor: "pointer",
                backgroundColor: activeTab === tab ? primary.main : white.main,
              }}
              color={activeTab === tab ? white.main : text.disabled}
              onClick={() => handleSelectTab(tab)}
            >
              {tab}
            </MDBox>
          ))}
          <MDBox>
            <DateRangePicker
              from={globalDateRange?.from}
              to={globalDateRange?.to}
              onChangeFrom={(date) => handleDateRangeChange("from", date)}
              onChangeTo={(date) => handleDateRangeChange("to", date)}
            />
          </MDBox>
          <Tooltip title="Customize Layout">
            <Switch
              onChange={(e, value) => {
                setEnableDrag(value);
              }}
              value={enableDrag}
              sx={{
                "& .Mui-checked .MuiSwitch-thumb": {
                  borderColor: primary.main + " !important",
                },
                "& .Mui-checked+.MuiSwitch-track": {
                  borderColor: primary.main + " !important",
                  backgroundColor: primary.main + " !important",
                },
              }}
            />
          </Tooltip>
        </MDBox>
      </MDBox>
      {filterOptions && filters && (
        <Collapse in={showFilters}>
          <MDBox
            sx={{
              display: "flex",
              backgroundColor: white.main,
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1rem",
              boxShadow: "0px 0px 8px 1px #0000000D",
            }}
          >
            <MDBox
              sx={{
                width: "100%",
                display: "grid",
                columnGap: "1rem",
                gridTemplateColumns: "repeat(3, 1fr)",
                "@media (max-width: 800px)": {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
              }}
            >
              {filterOptions?.map((input, index) => {
                const filterName = input?.field || input?.name;
                if (!ignoreFilterList.includes()) {
                  return (
                    <Controls
                      type={input?.type}
                      DefaultType={Dropdown}
                      key={index}
                      name={filterName}
                      value={tempFilters[filterName]}
                      onChange={handleFilterChange}
                      dataKey={"value"}
                      options={input?.options || []}
                      getOptionLabel={(option) => {
                        return option?.name ? option.name : option;
                      }}
                      placeholder={tempFilters[filterName] != "" ? "" : "Select"}
                      label={convertKeyToName(filterName)}
                      size={"small"}
                    />
                  );
                }
              })}
            </MDBox>
            <MDBox
              sx={{
                width: "30%",
                display: "flex",
                alignItems: "flex-end",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <MDBox
                color={primary.main}
                onClick={() => setShowFilters(!showFilters)}
                sx={{
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                x Close
              </MDBox>
              <MDChip
                sx={{
                  backgroundColor: secondary.main,
                  padding: "5px 40px",
                  color: white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: "14px",
                  height: "40px",
                  borderRadius: "100px",
                  marginBottom: "10px",
                }}
                label={"Apply All Filters"}
                onClick={() => {
                  setFilters(tempFilters)
                }}
              />
            </MDBox>
          </MDBox>
        </Collapse>
      )}
      <MDBox ref={imageRef}>
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: initialLayout }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 12 }}
          rowHeight={50}
          margin={[15, 15]}
          // onLayoutChange={onLayoutChange}
        >
          <MDBox sx={cardStyle} key="demandForecast">
            <ChartHolder
              CustomComponent={DraggableChart}
              customComponentProps={{}}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              initialFilters={filters}
              title={"Delivery Forecast"}
              enableTableView={true}
              formatTableData={formatForecastTableData}
              chartProps={{
                grid: { vertical: false },
                domain: true,
              }}
              chartInsightsAccessor={"aiInsights"}
              dataAccessor={"chartData"}
              getData={orderForecastService.getDemandForecast}
              id={"demandForecast"}
              isGetChatInsightsByApi={true}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="seasonalDecomposition">
            <ChartHolder
              CustomComponent={DraggableChart}
              customComponentProps={{}}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              initialFilters={filters}
              title={"Seasonal Decomposition"}
              enableTableView={true}
              formatTableData={formatForecastTableData}
              chartProps={{
                grid: { vertical: false },
                domain: true,
              }}
              chartInsightsAccessor={"aiInsights"}
              dataAccessor={"chartData"}
              getData={orderForecastService.getSeasonalDecomposition}
              id={"seasonalDecomposition"}
              isGetChatInsightsByApi={true}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="regionWiseDemand">
            <ChartHolder
              CustomComponent={WorldMap}
              customComponentProps={{}}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              initialFilters={filters}
              title={"Region Wise Demand"}
              enableTableView={false}
              chartProps={{
                grid: { vertical: false },
                domain: true,
              }}
              chartInsightsAccessor={"aiInsights"}
              dataAccessor={"chartData.datasets"}
              getData={orderForecastService.getRegionWiseDemand}
              id={"regionWiseDemand"}
              isGetChatInsightsByApi={true}
            ></ChartHolder>
          </MDBox>
        </ResponsiveGridLayout>
      </MDBox>
    </>
  );
};

export default OrdersForecastDashboard;
