import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import { useState } from "react";
import Messages from "./components/messages/Messages";

const RightPanel = ({ deliveryId }) => {
  const tabs = ["Messages"];

  const { palette, functions, typography } = useTheme();
  const { white } = palette;
  const { pxToRem } = functions;

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <MDBox
      sx={{
        height: "100%",
        maxWidth: "100%",
        width: pxToRem(360),
        padding: pxToRem(15),
        paddingBottom: pxToRem(10),
        background: white.main,
        borderRadius: pxToRem(10),
        overflowY: "hidden",
        boxShadow: "0px 0px 8px 1px #0000000D",
      }}
    >
      <MDBox
        mb={1}
        sx={{
          display: "flex",
          overflowX: "auto",
        //   justifyContent: "space-around",
          width: "100%",
          fontSize: typography.size["sm"],
          borderBottom: `1px solid ${palette.grey[300]}`,
        }}
        color={palette.text.main}
      >
        {tabs.map((tab, index) => (
          <MDBox
            key={index}
            sx={{
              padding: pxToRem(8),
              cursor: "pointer",
              paddingBottom: pxToRem(10),
              borderBottom:
                activeTab === tab ? `2px solid ${palette.primary.main}` : ``,
            }}
            color={
              activeTab === tab ? palette.primary.main : palette.text.disabled
            }
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </MDBox>
        ))}
      </MDBox>

      {activeTab === tabs[0] ? (
        <Messages deliveryId={deliveryId} />
      ) : (
        ""
      )}
    </MDBox>
  );
};

export default RightPanel;
