// @mui material components

//  React components
import MDBox from "components/MDBox";
//  React example components
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
// Data

// Dashboard components
import DataTable from "examples/Tables/DataTable";

import AccessWrapper from "wrappers/accessWrapper";

import TableReport from "./tableReport";

function TableDisplay(props) {
  const navigation = useNavigate();

  const [excelRows, excelColumns] = TableReport({
    excelData: props.data,
  });

  const handleRowClick = (row) =>{
    
    if(row.values["case_id"]){
      window.open(process.env.REACT_APP_MAIN_URL+`/cases/${row.values["case_id"]}`,'_blank')
    }
  }

  return (
      <MDBox
        sx={{
          alignItems: "center",
          height: "auto",
          maxWidth: "100%",
          minHeight: "15vh",
          height: '100%',
          position: "relative",
          ...props?.sx
        }}
      >
          {excelRows && (
            <DataTable
              table={{
                columns: excelColumns,
                rows: excelRows,
              }}
              isSorted={true}
              canSearch={true}
              showTotalEntries={true}
              noEndBorder
              downloadable={false}
              entriesPerPage={{defaultValue:10}}
              rowClick={handleRowClick}
              {...props}
              // downloadFileName={`Excel ${tableValue} Data`}
            />
          )}
          <style jsx>{`.MuiPopper-root{z-index: 999999}`}</style>
      </MDBox>
  );
}
export default AccessWrapper(TableDisplay, ["agent1", "superuser", "admin"]);
