import { integrationAPI } from "api";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import { setGlobalState } from "Global";
import { useEffect, useState } from "react";

const ExcelPopupTable = ({ fileId, handleClose, sourceId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [excelData, setExcelData] = useState({
    column_data: [],
    data: [],
  });

  const getExcelData = () => {
    setIsLoading(true);
    integrationAPI
      .getExcelProcessedData(fileId, sourceId)
      .then((res) => {
        if (res.status === 200) {
          setExcelData(res.data);
        }
      })
      .catch(() => {
        handleClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (fileId) {
      getExcelData();
    } else {
      setGlobalState("error", {
        open: true,
        message: "File hash is not Present",
        type: "error",
      });
      handleClose();
    }
  }, [fileId]);

  return (
    <MDBox
      onClick={() => handleClose()}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        zIndex: "999999",
        height: "100vh",
        width: "100vw",
        left: "0",
        background: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <style jsx>{`
        .MuiPopper-root,
        .MuiDialog-root {
          z-index: 99999999 !important;
        }
      `}</style>
      <MDBox
        sx={{
          height: "calc(100vh - 100px)",
          width: "calc(100% - 100px)",
          overflow: "auto",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <DataTable
          table={{
            columns: excelData.column_data,
            rows: excelData.data,
          }}
          isSorted={true}
          canSearch={true}
          showTotalEntries={true}
          noEndBorder
          downloadable={true}
          isLoading={isLoading}
          entriesPerPage={{ defaultValue: 50 }}
          tableHeight={"calc(100% - 78px)"}
        />
      </MDBox>
    </MDBox>
  );
};

export default ExcelPopupTable;
