import { useEffect, useState } from "react";
import { deliveryService } from "../service/deliveryService";
const initialFilters = {
  limit: 50,
  offset: 0,
};

export const useDelivery = () => {
  const [filterOptions, setFilterOptions] = useState(null);
  const [filters, setFilters] = useState(null);
  const [deliveryStatus, setDeliveryStatus] = useState(null);

  useEffect(() => {
    deliveryService.getDeliveriesByTransporter({}).then((data) => {
      setDeliveryStatus(data);
    });
    getFilterValues();
  }, []);

  const getFilterValues = () => {
    deliveryService
      .getFilterOptions()
      .then((res) => {
        if (res.status === 200) {
          const tempFilters = [];
          res.data.forEach((data) => {
            tempFilters[data.field] = data.options ? [] : "";
          });
          setFilterOptions(res.data || []);
          setFilters((pre) => ({ ...pre, ...tempFilters }));
        }
      })
      .catch(() => {});
  };

  return {
    filterOptions,
    filters,
    deliveryStatus,
    setFilters
  };
};