import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.

// @mui material components
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

//  React context
import { useTheme } from "@emotion/react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";
import { useGlobalState } from "Global";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const scrollbarStyle = {
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#00000000",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#d3d3d34d",
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#d3d3d34d",
  },
};

function Sidenav({
  color,
  user,
  brand,
  brandName,
  routes,
  setCurrentTheme,
  ...rest
}) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [userDetails] = useGlobalState("userDetails");

  const theme = useTheme();
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;

  const mdBoxRef = useRef(null);
  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);
  const [isScrollDownVisible, setIsScrollDownVisible] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    const mdBoxElement = mdBoxRef.current;
    const handleScroll = () => {
      if (mdBoxElement) {
        const { scrollTop, scrollHeight, clientHeight } = mdBoxElement;
        setIsScrollUpVisible(scrollTop > 0);
        setIsScrollDownVisible(scrollTop + clientHeight < scrollHeight);
      }
    };
    const updateScrollability = () => {
      if (mdBoxElement) {
        const { scrollHeight, clientHeight } = mdBoxElement;
        setIsScrollable(scrollHeight > clientHeight);
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      updateScrollability();
      handleScroll();
    });
    if (mdBoxElement) {
      resizeObserver.observe(mdBoxElement);
      updateScrollability();
      mdBoxElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (mdBoxElement) {
        resizeObserver.unobserve(mdBoxElement);
        mdBoxElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const toggleSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const [alignment, setAlignment] = useState("Primary");

  useEffect(() => {
    setMiniSidenav(dispatch, true);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      // setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 800 ? false : transparentSidenav
      );
      setWhiteSidenav(dispatch, window.innerWidth < 800 ? false : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleNewTabRedirect = (href) => {
    // Open a duplicate tab
    window.open(href, "_blank");
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route }) => {
      let returnValue;
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={collapseName.includes(key)}
              noCollapse={noCollapse}
            />
          </Link>
        ) : key == "cases" ? (
          <NavLink key={key} to={`/${user?.id}/cases`}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={collapseName.includes(key)}
            />
          </NavLink>
        ) : key == "adminCases" ? (
          <NavLink key={key} to={`/${user?.id}/adminCases`}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={collapseName.includes(key)}
            />
          </NavLink>
        ) : key == "claims" ? (
          <NavLink key={key} to={`/${user?.id}/claims`}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={collapseName.includes(key)}
            />
          </NavLink>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={collapseName.includes(key)}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox
        mt={'15px'}
        mx={'18px'}
        textAlign="center"
        sx={{ overflowX: "visible" }}
      >
        <MDBox
          onClick={toggleSidenav}
          sx={(theme) => ({
            position: "absolute",
            top: 0,
            zIndex: '99',
            right: "0",
            transform: "translate(50%,0)",
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            cursor: "pointer",
            float: "right",
            backgroundColor: theme.palette.primary.main,
            overflow: "visible",
            borderRadius: "100px",
            border: "2px solid " + theme.palette.white.main,
            "&:hover": {
              border: "2px solid " + theme.palette.primary.main,
              backgroundColor: theme.palette.white.main,
              color: theme.palette.primary.main + " !important",
            },
            transition: "all .3s",
          })}
          mt={1}
          color={"white"}
        >
          {miniSidenav ? (
            <span class="material-symbols-outlined">chevron_right</span>
          ) : (
            <span class="material-symbols-outlined">chevron_left</span>
          )}
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && (
            <img src={brand} style={{
              width: '2.5rem',
            }} />
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            color={'white'}
          >
            {brandName}
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <MDBox py={"5px"} sx={{ position: "relative", height: "50%" }}>
        {isScrollable && isScrollUpVisible && (
          <span
            className="material-symbols-outlined"
            style={{
              position: "absolute",
              top: "-18px",
              width: "100%",
              textAlign: "center",
              color: theme.palette.white.main,
            }}
          >
            keyboard_arrow_up
          </span>
        )}
        <MDBox
          ref={mdBoxRef}
          sx={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            position: "relative",
            ...scrollbarStyle,
          }}
        >
          <List>{renderRoutes}</List>
        </MDBox>
        {isScrollable && isScrollDownVisible && (
          <span
            className="material-symbols-outlined"
            style={{
              position: "absolute",
              bottom: "-18px",
              width: "100%",
              textAlign: "center",
              color: theme.palette.white.main,
            }}
          >
            keyboard_arrow_down
          </span>
        )}
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />

      {/* Profile box */}
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        pb={2}
        pt={1}
        sx={{ overflowX: "hidden", ...scrollbarStyle,}}
      >
        <MDBox>
          <MDButton sx={{ width: "100%", padding: "0.5rem" }} color={"white"}>
            <MDBox
              sx={{
                height: "38px",
                width: "38px",
                fontSize: theme.typography.size["lg"],
                fontWeight: "bold",
                background: "#F0F1FF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                position: "relative",
                zIndex: "1",
                border: `1px solid ${theme.palette.primary.main}`,
              }}
            >
              {userDetails?.name[0].toUpperCase()}
            </MDBox>
            {miniSidenav ? (
              ""
            ) : (
              <MDBox
                color={theme.palette.primary.main}
                sx={{
                  paddingLeft: "0.5rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MDBox color={theme.palette.primary.main}>
                  {userDetails?.name}
                </MDBox>
                <MDBox sx={{ fontSize: theme.typography.size["xs"] }}>
                  {userDetails?.permission.toUpperCase()}
                </MDBox>
              </MDBox>
            )}
          </MDButton>
        </MDBox>
        <List sx={{ width: "100%", marginTop: "0.5rem" }}>
          {/* <NavLink key={1} to={`/profile`}>
            <SidenavCollapse
              name={"Profile"}
              icon={<span class="material-symbols-outlined">account_box</span>}
              active={`profile` === collapseName}
            />
          </NavLink> */}
          {/* Theme dropdown */}
          {/* <ToggleButtonGroup
            color="white"
            value={alignment}
            exclusive
            fullWidth
            onChange={(event, newAlignment) => {
              setCurrentTheme(newAlignment);
              setAlignment(newAlignment);
            }}
            aria-label="Platform"
          >
            <ToggleButton
              sx={{ background: "white", color: "white" }}
              value="Primary"
            >
              Primary
            </ToggleButton>
            <ToggleButton
              sx={{ background: "white", color: "white" }}
              value="Secondary"
            >
              Secondary
            </ToggleButton>
          </ToggleButtonGroup> */}

          <NavLink key={1} to={`/logout`}>
            <SidenavCollapse
              name={"Logout"}
              icon={<span class="material-symbols-outlined">logout</span>}
              active={false}
            />
          </NavLink>
        </List>
      </MDBox>
    </SidenavRoot>
  );
}

export default Sidenav;
