import { useTheme } from "@emotion/react";
import { CircularProgress, Grow, Icon } from "@mui/material";
import { chatGPTAPI } from "api";
import MDBox from "components/MDBox";
import { setGlobalState, useGlobalState } from "Global";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useLocation, useMatch } from "react-router-dom";
import ChatPanel from "./components/chatPanel/ChatPanel";
import SessionNameEdit from "./components/editSessionName";

const ChatBotPopup = ({ routes }) => {
  const { pathname } = useLocation();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [session, setSession] = useState(null);
  const [atFeatures, setAtFeatures] = useState(null);
  const [showEditSession, setShowEditSession] = useState(false);

  const matchedPath = [];
  routes.forEach((route) => {
    if (route.showChatbot && route.path) {
      matchedPath.push(route.path);
    }
    if (route.path && route.subRoute) {
      route.subRoute.forEach((subR) => {
        if (subR.showChatbot && subR.path) {
          matchedPath.push(route.path + "/" + subR.path);
        }
      });
    }
  });

  const matches = matchedPath?.map((path) => useMatch(path));
  const shouldShowChatbot = matches?.some(
    (match) =>
      match !== null ||
      pathname === matchedPath.find((path) => path === pathname)
  );

  const handleClick = () => {
    if (!atFeatures) getAvailableFeatureSelect();
    createNewSession();
  };

  const getAvailableFeatureSelect = () => {
    setIsLoading(true);
    chatGPTAPI
      .getAllFeaturesAvailable()
      .then((res) => {
        if (res.status == 200) {
          setAtFeatures(res.data);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createNewSession = () => {
    setIsLoading(true);
    chatGPTAPI
      .createNewSession()
      .then((res) => {
        if (res.status == 200) {
          setSession({
            _id: res.data,
            label: "New Conversation",
            timestamp_creation: new Date(),
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateSession = (session, summary) => {
    setIsLoadingChat(true);
    chatGPTAPI
      .updateSession({ session_id: session._id, summary: summary })
      .then((res) => {
        if (res.status == 200) {
          setIsLoadingChat(false);
          setSession((pre) => ({
            ...pre,
            label: summary,
          }));
        }
      })
      .catch((error) => {
        setIsLoadingChat(false);
      });
  };

  return (
    shouldShowChatbot && (
      <MDBox
        sx={{
          position: "fixed",
          bottom: "16px",
          right: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          zIndex: 999,
        }}
      >
        <GeneralMessage />
        {isLoading ? (
          <Grow in={isLoading}>
            <MDBox
              sx={{
                backgroundColor: primary.light,
                padding: ".5rem 1rem",
                margin: ".5rem 1rem",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                boxShadow: "0px 0px 8px 1px #0000000D",
              }}
            >
              <CircularProgress
                color="primary"
                sx={{
                  height: "18px !important",
                  width: "18px !important",
                }}
              />
              <MDBox mt={"3px"}>Creating new session...</MDBox>
            </MDBox>
          </Grow>
        ) : session && atFeatures ? (
          <Grow in={session && atFeatures}>
            <MDBox
              sx={{
                height: "calc(100vh - 32px)",
                width: "50vw",
                scroll: "auto",
                // paddingX: ".5rem",
                // margin: ".5rem 1rem",
                backgroundColor: white.main,
                borderRadius: "10px",
                boxShadow: "0px 0px 10px 5px #00000017",
                "& ._loading_overlay_wrapper": {
                  height: "100%",
                },
                position: "relative",
              }}
            >
              <Icon
                sx={{
                  color: "red",
                  position: "absolute",
                  left: "-1.5rem",
                  top: 0,
                  fontSize: "1.4rem !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSession(null);
                }}
              >
                close
              </Icon>
              <LoadingOverlay
                active={isLoadingChat}
                spinner
                styles={{ width: "100%" }}
              >
                <MDBox
                  sx={{ width: "100%", height: "100%", paddingX: ".5rem" }}
                >
                  <ChatPanel
                    selectedSession={session}
                    mentionFeatures={atFeatures}
                    handleTitleEdit={() => {
                      setShowEditSession(true);
                    }}
                    setIsLoadingChat={setIsLoadingChat}
                    iconsStyle={{
                      height: "4rem",
                      width: "4rem",
                    }}
                    theme={"popup"}
                  />
                </MDBox>
              </LoadingOverlay>
            </MDBox>
          </Grow>
        ) : (
          <></>
        )}

        {!(session && atFeatures) && (
          <lottie-player
            onClick={() => handleClick()}
            src="https://lottie.host/56b2c8f6-5525-4c17-96be-87ad65de28de/LL0Sw3bEMT.json"
            background="transparent"
            speed="1"
            style={{
              width: "3rem",
              height: "3rem",
              cursor: "pointer",
            }}
            loop
            autoplay
            direction="1"
            mode="normal"
          ></lottie-player>
        )}
        {showEditSession && (
          <SessionNameEdit
            open={true}
            handleClose={() => {
              setShowEditSession(false);
            }}
            handleSessionNameEdit={updateSession}
            session={session}
          />
        )}
      </MDBox>
    )
  );
};

export default ChatBotPopup;

const GeneralMessage = () => {
  const [chatBotMessage] = useGlobalState("chatBotMessage");
  const [show, setShow] = useState(chatBotMessage);
  const [grow, setGrow] = useState(true);
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;
  const messages = [
    "Hi!! I am here to help you",
    "Click on me and ask any question",
  ];
  const [activeMessage, setActiveMessage] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (activeMessage < messages.length - 1) {
        setGrow(false);
        const innerTimeoutId = setTimeout(() => {
          setGrow(true);
          setActiveMessage(activeMessage + 1);
        }, 400);
        return () => clearTimeout(innerTimeoutId);
      } else {
        handleClose();
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [activeMessage]);

  const handleClose = () => {
    setGlobalState("chatBotMessage", false);
    setShow(false);
  };
  useEffect(() => {
    console.log(messages[activeMessage]);
  }, [messages, activeMessage]);

  return (
    show && (
      <Grow in={grow && show}>
        <MDBox
          sx={{
            backgroundColor: primary.light,
            padding: ".5rem 1rem",
            margin: ".5rem",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            boxShadow: "0px 0px 8px 1px #0000000D",
            maxWdith: "200px",
            position: "relative",
          }}
        >
          {grow && show && (
            <MDBox
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 0,
                zIndex: "1",
                right: "0",
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                cursor: "pointer",
                backgroundColor: white.main,
                overflow: "visible",
                borderRadius: "100px",
                border: "1px solid " + primary.main,
                transform: "translate(50%, -50%)",
              }}
              color={primary.main}
            >
              <span
                style={{ fontSize: "14px", padding: "1px" }}
                class="material-symbols-outlined"
              >
                close
              </span>
            </MDBox>
          )}
          <MDBox>{messages[activeMessage]}</MDBox>
        </MDBox>
      </Grow>
    )
  );
};
