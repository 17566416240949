import Icon from "@mui/material/Icon";
import AccessDenied from "layouts/authentication/access-denied";
import MainChat from "layouts/chat";
import Dashboard from "layouts/dashboard/Dashboard";
import Delivery from "layouts/delivery/Delivery";
import DeliveryDetails from "layouts/deliveryDetails/DeliveryDetails";
import Folders from "layouts/folders/Folders";
import Integration from "layouts/integration/Integration";
import Database from "layouts/integration/database/Database";
import Email from "layouts/integration/email/Email";
import FileUpload from "layouts/integration/fileUpload/FileUpload";
import Logout from "layouts/logout";
import TeamManagement from "layouts/management/teamManagement/TeamManagement";
import UserManagement from "layouts/management/userManagement/UserManagement";
import { Navigate } from "react-router-dom";

export const defaultRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        dashboard
      </Icon>
    ),
    route: "/dashboard",
    path: "/dashboard",
    access: ["user", "agent", "admin", "agent2", "superuser"],
    component: <Dashboard />,
    showChatbot: true,
  },
  {
    type: "collapse",
    name: "Data",
    key: "deliveries",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        table_view
      </Icon>
    ),
    path: "/deliveries",
    route: "/deliveries",
    access: ["user", "agent", "admin", "superuser"],
    component: <Delivery />,
    showChatbot: true,
  },
  {
    type: "",
    name: "Delivery",
    key: "delivery",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        menu
      </Icon>
    ),
    path: "/delivery/:id",
    route: "/delivery",
    access: ["user", "agent", "admin", "superuser"],
    component: <DeliveryDetails />,
    showChatbot: true,
  },
  {
    type: "collapse",
    name: "Documents",
    key: "folders",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        folder
      </Icon>
    ),
    route: "/folders",
    path: "/folders",
    subRoute: [
      {
        path: ":id",
        component: <Folders />,
      },
    ],
    access: ["user", "agent1", "admin", "agent2", "superuser", "user"],
    component: <Folders />,
    showChatbot: true,
  },
  {
    type: "collapse",
    name: "Integrations",
    key: "integration",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        widgets
      </Icon>
    ),
    route: "/integrations",
    path: "/integrations",
    subRoute: [
      {
        path: "excel-upload",
        component: <FileUpload />,
        showChatbot: true,
      },
      {
        path: "email",
        component: <Email />,
        showChatbot: true,
      },
      {
        path: "database/:dbname",
        component: <Database />,
        showChatbot: true,
      },
    ],
    access: ["user", "agent1", "admin", "agent2", "superuser", "user"],
    component: <Integration />,
    showChatbot: true,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "management",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        group
      </Icon>
    ),
    route: "/management",
    path: "/management",
    access: ["user", "agent1", "admin", "agent2", "superuser", "user"],
    component: <Navigate to={"/management/user"} />,
  },
  {
    type: "",
    key: "teamManagement",
    route: "/management/team",
    path: "/management/team",
    access: ["user", "agent1", "admin", "agent2", "superuser", "user"],
    component: <TeamManagement />,
    showChatbot: true,
  },
  {
    type: "",
    key: "userManagement",
    route: "/management/user",
    path: "/management/user",
    access: ["user", "agent1", "admin", "agent2", "superuser", "user"],
    component: <UserManagement />,
    showChatbot: true,
  },
  {
    type: "collapse",
    name: "Chat Intel",
    key: "texttosql",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        smart_toy_rounded
      </Icon>
    ),
    route: "/texttosql",
    path: "/texttosql",
    access: ["user", "agent2", "superuser", "agent1", "admin", "3PA", "3PU"],
    component: <MainChat />,
  },
  {
    type: "collapse",
    name: "Claim Desk",
    key: "ClaimDesk",
    icon: <span class="material-symbols-outlined">inventory</span>,
    route: "/claimDesk",
    path: "/claimDesk",
    href: process.env.REACT_APP_CLAIM_DESK,
    access: ["user", "agent2", "superuser", "agent1", "admin", "user"],
  },
  {
    type: "",
    name: "Logout",
    key: "access-denied",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        logout
      </Icon>
    ),
    route: "/logout",
    path: "/logout",
    access: ["user", "agent2", "superuser", "agent", "admin"],

    component: <Logout />,
  },
  {
    type: "",
    name: "Access Denied",
    key: "access-denied",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        assignment
      </Icon>
    ),
    route: "/access-denied",
    path: "/access-denied",
    access: ["user", "agent2", "superuser", "agent", "admin"],

    component: <AccessDenied />,
  },
];
