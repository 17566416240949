import { useTheme } from "@emotion/react";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";

import { Paper } from "@mui/material";
import QuickActionPopup from "components/quickActionPopup/QuickActionPopup";
import { formatDate } from "examples/Tables/DataTable/utils";
import Confirmation from "examples/dialogs/Confirmation";
import SearchBar from "examples/searchBar/SearchBar";
import { Link } from "react-router-dom";
import DialogWrapper from "utils/dialog/dialogWrapper";
import EmptyResult from "utils/emptyResult";
import { folderService } from "../service/folderService";
import FileModel from "./FileViewer";
import TextWithIcon from "./TextWithIcon";
import { folderAPI } from "api";
import { useGlobalState } from "Global";
import searchFilegif from "assets/images/gifs/serchFile.gif";
import MDChip from "components/MDChip";
import LoadingOverlay from "react-loading-overlay";
import AiLoading from "examples/loaders/AiLoading";

const SearchFilesDialog = ({
  open,
  handleClose,
  handleUpload,
  isUploadLoading,
  sx,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;
  const searchRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleDelete = (file) => {
    let tempItems = selectedFiles.filter(
      (data) => data.file_id != file.file_id
    );
    setSelectedFiles(tempItems);
  };

  const [isLoading, setIsLoading] = useState({
    initial_response: false,
    llm_response: false,
  });

  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(null);

  const [searchResults, setSearchResults] = useState({
    initial_response: [],
    llm_response: [],
  });

  const [isVectorSearch, setIsVectorSearch] = useState(true);
  const [isFirstSearchDone, setIsFirstSearchDone] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [userDetails] = useGlobalState("userDetails");

  useEffect(() => {
    if (searchRef.current) searchRef.current.value = "";
    setIsFirstSearchDone(false);
    setIsLoading({
      initial_response: false,
      llm_response: false,
    });
    setSearchResults({
      initial_response: [],
      llm_response: [],
    });
  }, [isVectorSearch]);

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
    folderAPI.searchFilesWS({
      token: `Bearer ${userDetails["access_token"]}`,
    });

  useEffect(() => {
    if (lastJsonMessage) {
      const { status, content, type } = lastJsonMessage;
      const tempType =
        type === "initial_response" ? "initial_response" : "llm_response";
      setSearchResults((pre) => ({
        ...pre,
        [tempType]: Array.isArray(content) ? content : [],
      }));
      setIsLoading((pre) => ({
        ...pre,
        [tempType]: false,
      }));
    }
  }, [lastJsonMessage]);

  const sendRequestToWS = (body) => {
    setIsLoading({
      initial_response: true,
      llm_response: false,
    });
    if (readyState == 1) {
      sendJsonMessage(body);
    }
  };

  const searchFilesWS = () => {
    setIsFirstSearchDone(true);
    sendRequestToWS({
      type: "search_files",
      search_query: searchRef.current.value,
      vector_search: isVectorSearch,
    });
  };

  // const searchFile = () => {
  //   setIsLoading(true);
  //   folderService
  //     .searchFiles(searchRef.current.value, isVectorSearch)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setSearchResults(res.data);
  //       }
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  const handleDeleteFile = (id) => {
    setIsLoading(true);
    folderService
      .deleteFile(id)
      .then((res) => {
        if (res.status == 200) {
          let tempSearchFiles = searchResults.initial_response.filter(
            (file) => file.file_id !== id
          );
          let tempLlmFiles = searchResults.llm_response.filter(
            (file) => file.file_id !== id
          );
          searchResults({
            initial_response: tempSearchFiles,
            llm_response: tempLlmFiles,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setShowDeleteFileDialog(null);
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": { borderRadius: "14px", boxShadow: "none" },
      }}
    >
      <LoadingOverlay active={isUploadLoading} spinner>
        <Paper
          elevation={3}
          sx={{
            marginRight: "0%",
            marginLeft: "0%",
            width: "576px",
            padding: "1rem",
          }}
        >
          {!isFirstSearchDone && (
            <MDBox
              sx={{
                fontSize: "28px",
                fontWeight: "500",
                textAlign: "center",
                lineHeight: "1em",
                marginTop: "4rem",
              }}
              color={text.main}
            >
              Search Documents
            </MDBox>
          )}
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
              textAlign: "center",
              width: "75%",
              margin: "auto",
              gap: ".9rem",
              ...(isFirstSearchDone
                ? {
                    flexDirection: "column",
                    width: "98%",
                    gap: ".5rem",
                  }
                : {}),
            }}
          >
            <SearchBar
              handleSearch={searchFilesWS}
              searchOnEnterPress={true}
              inputRef={searchRef}
              disabled={!(readyState == 1)}
              placeholder={"Search Files"}
              sx={{
                border: "none",
                backgroundColor: white.main,
                padding: "5px 12px 5px 20px",
                boxShadow: "0 0 10px 3px #004CF133",
              }}
              fontColor={text.secondary}
              iconColor={primary.main}
              fontSize={".9em"}
              // isLoading={isLoading}
              focusOnOpen={true}
              serchIconPosition={"right"}
            />
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDBox
                sx={{
                  background: `-webkit-linear-gradient(0, ${primary.main}, #72AEFF)`,
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  fontWeight: "500",
                }}
              >
                AI search based on text inside
              </MDBox>
              <Switch
                onChange={(e, value) => {
                  setIsVectorSearch(value);
                }}
                checked={isVectorSearch}
                sx={{
                  rotate: "180deg",
                  "& .Mui-checked .MuiSwitch-thumb": {
                    borderColor: primary.main + " !important",
                  },
                  "& .Mui-checked+.MuiSwitch-track": {
                    borderColor: primary.main + " !important",
                    backgroundColor: primary.main + " !important",
                    background: `-webkit-linear-gradient(0,  #72AEFF,${primary.main})`,
                  },
                }}
              />
              <MDBox color={text.disabled} sx={{ fontWeight: "500" }}>
                based on file names
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox
            sx={{
              minHeight: isFirstSearchDone ? "200px" : "",
              maxHeight: handleUpload
                ? "calc(100vh - 300px)"
                : "calc(100vh - 200px)",
              overflow: "auto",
              position: "relative",
              ...sx,
            }}
            color={text.secondary}
          >
            {isFirstSearchDone && (
              <>
                {isLoading.initial_response ? (
                  isVectorSearch ? (
                    <MDBox sx={{ height: "200px" }}>
                      <AiLoading
                        messages={[
                          "Requesting AI...",
                          "Analyzing data...",
                          "Getting results...",
                        ]}
                        time={1000}
                      />
                    </MDBox>
                  ) : (
                    <MDBox
                      sx={{
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          width: "3rem !important",
                          height: "3rem !important",
                        }}
                      />
                    </MDBox>
                  )
                ) : searchResults.initial_response.length === 0 ? (
                  <EmptyResult
                    text="Oops!! no search results"
                    maxWidth="200px"
                    fontSize="1rem"
                  />
                ) : (
                  <ListFiles
                    files={searchResults.initial_response}
                    setPreviewFile={setPreviewFile}
                    handleUpload={handleUpload}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    handleClose={handleClose}
                  />
                )}
              </>
            )}
          </MDBox>
          {handleUpload && selectedFiles.length > 0 && (
            <MDBox sx={{ marginTop: "1rem" }}>
              <MDBox
                my={"5px"}
                sx={{ fontWeight: "bold" }}
                color={text.secondary}
              >
                Selected files:
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  gap: "5px",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {selectedFiles.map((file, index) => (
                  <MDChip
                    key={index}
                    sx={{
                      backgroundColor: grey[200],
                      color: text.disabled,
                      "& svg": {
                        fill: text.disabled,
                      },
                      "&:hover": { backgroundColor: grey[200] },
                    }}
                    icon={
                      <img
                        src={folderService.getFileIcon(file.filename)}
                        width={"15px"}
                      />
                    }
                    size={"small"}
                    label={file.filename}
                    onDelete={() => handleDelete(file)}
                  />
                ))}
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={2}
              >
                <MDChip
                  sx={{
                    backgroundColor: error.light + "45",
                    padding: "5px",
                    color: error.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    border: "1px solid " + error.main,
                    width: "90px",
                    "&:hover": {
                      backgroundColor: error.light + "45",
                      boxShadow: "0 0 5px 1px " + error.main + "82",
                    },
                  }}
                  size={"small"}
                  label={"Cancel"}
                  onClick={handleClose}
                />
                <MDChip
                  sx={{
                    backgroundColor: primary.main,
                    padding: "5px",
                    color: white.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    width: "90px",
                  }}
                  label={"Upload"}
                  size={"small"}
                  onClick={
                    selectedFiles.length === 0
                      ? () => {}
                      : () => {
                          handleUpload(selectedFiles);
                        }
                  }
                />
              </MDBox>
            </MDBox>
          )}
        </Paper>
        {!isFirstSearchDone && selectedFiles.length == 0 && (
          <MDBox
            sx={{
              height: "100px",
              overflow: "hidden",
            }}
          >
            <MDBox
              sx={{
                bottom: "-20px",
                left: "-20%",
                opacity: ".7",
                height: "120px",
                objectFit: "cover",
                position: "relative",
              }}
              component={"img"}
              src={searchFilegif}
            />
          </MDBox>
        )}
        {previewFile && (
          <DialogWrapper handleClose={() => setPreviewFile(null)} width={"35%"}>
            <FileModel file={previewFile} />
          </DialogWrapper>
        )}
        <Confirmation
          open={showDeleteFileDialog}
          onClose={() => {
            setShowDeleteFileDialog(null);
          }}
          onAccept={() => handleDeleteFile(showDeleteFileDialog?.file_id)}
          acceptLable={"Delete"}
          title={`Do you really want to delete File: ${showDeleteFileDialog?.filename}?`}
          isLoading={isLoading}
        />
      </LoadingOverlay>
    </Dialog>
  );
};

export default SearchFilesDialog;

const ListFiles = ({
  files,
  setShowDeleteFileDialog,
  setPreviewFile,
  selectedFiles,
  setSelectedFiles,
  handleUpload,
  handleClose,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;

  const handleSelect = (value, filesData) => {
    let tempItems = [...selectedFiles];
    if (value) {
      tempItems.push(filesData);
    } else {
      tempItems = tempItems.filter((data) => data.file_id != filesData.file_id);
    }
    setSelectedFiles(tempItems);
  };

  return (
    <>
      {files?.map((data) => (
        <MDBox
          py={1}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid " + grey[300],
          }}
        >
          {handleUpload && (
            <Checkbox
              sx={{
                height: "10px",
              }}
              checked={selectedFiles
                .map((data) => data.file_id)
                .includes(data.file_id)}
              onChange={(e, value) => {
                handleSelect(value, data);
              }}
            />
          )}
          <MDBox
            color={"inherit"}
            sx={{
              display: "flex",
              width: "100%",
              gap: "1rem",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              setPreviewFile({
                name: data.filename,
                url: data.s3_url,
                id: data.file_id,
                rawFile: data?.rawFile,
              })
            }
          >
            <img
              src={folderService.getFileIcon(data.filename)}
              style={{ height: "1.3em" }}
            />
            <MDBox color={"inherit"} sx={{ width: "100%" }}>
              {data.filename}
            </MDBox>
          </MDBox>
          {data?.score && (
            <MDBox sx={{ whiteSpace: "nowrap" }} color={text.disabled}>
              Match: {Math.round(data?.score * 100) || 0}%
            </MDBox>
          )}
          <MDBox sx={{ whiteSpace: "nowrap" }} color={text.disabled}>
            {formatDate(new Date())}
          </MDBox>
          <MDBox sx={{ display: "flex" }}>
            <QuickActionPopup
              icon={
                <span
                  class="material-symbols-outlined"
                  style={{
                    color: text.disabled,
                    cursor: "pointer",
                    fontSize: "1.3em",
                  }}
                >
                  more_vert
                </span>
              }
              sx={{
                right: "18px",
                top: "18px",
              }}
            >
              <TextWithIcon
                onClick={() => {
                  folderService.downloadFile(data.s3_url);
                }}
                iconName={"download"}
                title={"Download File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <Link to={`/folders/${data.folder_id}`}>
                <TextWithIcon
                  onClick={handleClose}
                  iconName={"open_in_new"}
                  title={"Show In Folder"}
                  pb={"2px"}
                  sx={{
                    padding: "5px 1rem",
                    transition: "background-color .3s",
                    "&:hover": {
                      backgroundColor: grey[200],
                    },
                  }}
                />
              </Link>
              <TextWithIcon
                onClick={() => {
                  setShowDeleteFileDialog(data);
                }}
                iconName={"delete"}
                title={"Delete File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
            </QuickActionPopup>
          </MDBox>
        </MDBox>
      ))}
    </>
  );
};
