import dayjs from "dayjs";
import TableCell from "./TableCell";
import { format } from "date-fns";
const dateRegex = /.*(date|timestamp).*/i;
export const applyStyleToEachTableCell = (columnData, data) => {
  const tempData = [];
  for (let row of data) {
    const tempObject = {};
    for (let column of columnData) {
      tempObject[column.accessor] = (
        <TableCell data={row[column.accessor]} type={column.type} />
      );
    }
    tempData.push(tempObject);
  }
  return tempData;
};

export const formatDate = (date) => {
  const dateObj = new Date(date);

  return dateObj.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  });
};

export const formatTime = (date) => {
  if (!date.includes("Z") && !date.includes("+")) {
    date += "Z";
  }

  const dateObj = new Date(date);

  return dateObj.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const handleFilterChangeForCustomTable = (event, filters, type) => {
  if (
    (event.target.value instanceof Date &&
      !isNaN(event.target.value.getTime())) ||
    dayjs.isDayjs(event.target.value) ||
    type == "Date"
  ) {
    event.target.value = format(event.target.value, "yyyy-MM-dd");
  }
  if (Array.isArray(event.target.value) && event.target.value.length > 0) {
    let index = event.target.value.length - 1;
    let value = event.target.value[event.target.value.length - 1];
    if (typeof value == typeof {}) {
      event.target.value[index] = value.email_id
        ? value.email_id
        : value._id
        ? value._id
        : "";
    }
  }
  if (Array.isArray(event.target.value) && event.target.value.includes("All")) {
    event.target.value = [];
  }
  let tempFilters = { ...filters, [event.target.name]: event.target.value };

  if (event.target.name == "limit") {
    tempFilters["offset"] = 0;
  } else if (
    typeof event.target.value == "list" &&
    event.target.value?.includes("All")
  ) {
    tempFilters[event.target.name] = [];
  }

  return tempFilters;
};

export const clearAllTableFilters = (filters) => {
  let tempFilters = {};
  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      tempFilters[key] = [];
    } else if (
      (filters[key] instanceof Date && !isNaN(filters[key].getTime())) ||
      dayjs.isDayjs(filters[key]) || dateRegex.test(key)
    ) {
      tempFilters[key] = "";
    }
  });

  return { ...tempFilters, search: "", page_no: 1, limit: 10, offset: 0 };
};
