import { useTheme } from "@emotion/react";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";

export default function CustomDatePicker({
  name,
  label,
  value,
  onChange,
  disabled,
  type = "mobile",
  size,
  ...props
}) {
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  const { palette } = useTheme();
  const { primary, text, inputBorderColor } = palette;

  const style = {
    margin: "10px 0",
    "& input":{
      ...(size === "small" ? {
        fontSize: "12px",
        padding: "8.3px",
      }: {}),
    },
    "& label":{
      ...(size === "small" ? {
        fontSize: "12px",
        padding: "8.3px",
        top: "-.4em",
      }: {}),
    },
    "& .MuiFormLabel-root": {
      padding: '1px'
    },
    "& .MuiOutlinedInput-root": {
      "& input, svg": {
        color: text.secondary,
        fill: text.disabled,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: inputBorderColor,
        color: text.disabled,
      },
      "&.Mui-focused fieldset": {
        border: "2px solid " + primary.main,
      },
    },
  };
  const validValue = value && dayjs(value).isValid() ? dayjs(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {type === "mobile" ? (
        <MobileDatePicker
          sx={style}
          disabled={disabled}
          name={name}
          label={label}
          value={validValue}
          onAccept={(date) => onChange(convertToDefEventPara(name, date))}
          format="DD/MM/YYYY"
          {...props}
        />
      ) : (
        <DesktopDatePicker
          sx={style}
          disabled={disabled}
          name={name}
          label={label}
          value={validValue}
          onChange={(date) => onChange(convertToDefEventPara(name, date))}
          format="DD/MM/YYYY"
          {...props}
        />
      )}
    </LocalizationProvider>
  );
}
