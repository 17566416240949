import { useTheme } from "@emotion/react";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import { useRef, useState } from "react";
import DeliveryDashboard from "./DeliveryDashboard";
import DocStoreDashboard from "./DocStoreDashboard";
import OrdersDashboard from "./OrdersDashboard";
import OrdersForecastDashboard from "./OrdersForecastDashboard";
import { downloadImage } from "./utils";

const Dashboard = () => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const styles = useStyles();
  const imageRef = useRef(null);

  const tabs = ["DELIVERY", "ORDERS", "FORECAST"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabComponent = (
    <MDBox
      sx={{
        fontSize: typography.size["sm"],
        fontWeight: typography.fontWeightMedium,
        display: "flex",
      }}
    >
      {tabs?.map((tab, index) => (
        <MDBox
          key={index}
          sx={{
            padding: pxToRem(8),
            cursor: "pointer",
            gap: "5px",
            textAlign: "center",
            paddingBottom: pxToRem(5),
            borderBottom:
              activeTab === tab ? `2px solid ${palette.primary.main}` : ``,
          }}
          color={palette.text.header}
          onClick={() => {
            setActiveTab(tab);
          }}
        >
          {tab}
        </MDBox>
      ))}
    </MDBox>
  );

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={{ ...styles.iconHolder, fontSize: "1.2em" }}>
            <span class="material-symbols-outlined">bar_chart_4_bars</span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Dashboard</MDBox>
        }
        rightContent={
          <OutlinedButton
            name={"Download"}
            onClick={() => downloadImage(imageRef.current, "Dashboard")}
            icon={
              <Icon className={"font-unset"} fontSize="1em">
                download
              </Icon>
            }
          />
        }
      />
      {activeTab === tabs[0] ? (
        <DeliveryDashboard imageRef={imageRef} tabComponent={tabComponent} />
      ) : activeTab === tabs[1] ? (
        <OrdersDashboard imageRef={imageRef} tabComponent={tabComponent} />
      ) : (
        // : activeTab === tabs[2] ? (
        //   <DocStoreDashboard imageRef={imageRef} tabComponent={tabComponent} />
        // )
        <OrdersForecastDashboard
          imageRef={imageRef}
          tabComponent={tabComponent}
        />
      )}
    </DashboardLayout>
  );
};

export default Dashboard;
