import { analyticsAPI, AnalyticsApi } from "api";
import { useEffect, useState } from "react";
import { orderForecastService } from "../service/orderForecastService";

const initialFilters = {
  limit: 50,
  offset: 0,
};

export const useOrderForecast = () => {
  const [filterOptions, setFilterOptions] = useState(null);
  const [filters, setFilters] = useState(null);
  useEffect(() => {
    getFilterValues();
  }, []);

  const getFilterValues = () => {
    orderForecastService
      .getFilterOptions()
      .then((res) => {
        if (res.status === 200) {
          const tempFilters = [];
          res.data.forEach((data) => {
            tempFilters[data.field] = data.options ? [] : "";
          });
          setFilterOptions(res.data || []);
          setFilters((pre) => ({ ...pre, ...tempFilters }));
        }
      })
      .catch(() => {});
  };

  return {
    filterOptions,
    filters,
    setFilters,
  };
};
