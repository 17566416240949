import dagre from "./dagre";
import d3Hierarchy from "./d3-hierarchy";
// import elk from './elk';

export const Direction = {
  TB: "TB",
  LR: "LR",
  RL: "RL",
  BT: "BT",
};

export const layoutAlgorithms = {
  direction: Object.values(Direction),
  spacing: [50, 50],
};

export const layoutAlgorithm = async (nodes, edges, options) => {
  // Implementation will depend on the specific layout algorithms
};

export default {
  dagre,
  d3Hierarchy,
  // elk,
};
