import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// @mui icons

//  React components
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { useTheme } from "@emotion/react";
import { setGlobalState, useGlobalState } from "Global";
import { basicAPI, client, analyticsClient, claimDeskClient } from "api";
import bgImage from "assets/images/background.png";
import CustomInput from "components/controls/CustomInput";
import JSEncrypt from "jsencrypt";

const encoder = new TextEncoder();

function Basic() {
  const [state, setState] = useState({});

  const navigation = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState();
  const [termsChecked, setTermsChecked] = useState(true);
  const [userDetails] = useGlobalState("userDetails");
  const from = location.state?.from?.pathname || "/";

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSignin = (e) => {
    e.preventDefault();
    if (!state.password || !state.username) {
      return setGlobalState("error", {
        open: true,
        message: "Please enter username and password",
        type: "error",
      });
    }
    basicAPI
      .signinNew(state.username, encryptPassword(state.password))
      .then((res) => {
        if (res.status == 200) {
          client.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
          claimDeskClient.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
          analyticsClient.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
          setGlobalState("userDetails", res.data);
          navigation(from == "/authentication/sign-in" ? "/" : from, {
            replace: true,
          });
        } else if (res.status == 202) {
          setGlobalState("error", {
            open: true,
            message:
              "2 Factor Authentication is mandatory. Please verify OTP sent to your email.",
            type: "info",
            code: 202,
          });
          navigation("/authentication/verify-email", {
            state: { from: from, email: state.username, replace: true },
          });
        }
      })
      .catch((error) => {
        if ([401, 422].includes(error.response?.status)) {
          let errorMessage =
            error.response.status == 401 ? error.response.data : "";

          setGlobalState("error", {
            open: true,
            message:
              errorMessage || "Invalid Username or password. Please try again.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };
  const encryptPassword = (password) => {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(key);
    return jsEncrypt.encrypt(password);
  };

  const getKey = () => {
    basicAPI
      .signinKey()
      .then((res) => {
        if (res.status == 200) {
          setKey(res.data);
        }
      })
      .catch((error) => {
        // console.log(error)
        if ([401, 422].includes(error.response?.status)) {
          setGlobalState("error", {
            open: true,
            message: "Invalid Username or password. Please try again.",
            type: "error",
            code: error.response.status,
          });
        }
      });
  };

  const handleLogin = () => {
    basicAPI
      .login()
      .then((res) => {
        let url = res.data.url;
        window.location.replace(url);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getKey();
  }, []);

  const theme = useTheme();
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          sx={(theme) => ({
            fontSize: theme.typography.size["xl"],
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
          })}
          px={3}
          pt={2}
        >
          <MDBox sx={{ fontSize: "inherit" }}>Sign In</MDBox>
          <span
            class="material-symbols-outlined"
            style={{ color: theme.palette.primary.main }}
          >
            login
          </span>
        </MDBox>
        <MDBox py={2} px={3}>
          <MDBox component="form" role="form">
            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
              <CustomInput
                type="email"
                label="Email"
                name="username"
                id="username"
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required={true}
              />
            </MDBox>
            <MDBox sx={{ display: "flex", justifyContent: "center" }}>
              <CustomInput
                type="password"
                label="Password"
                name="password"
                id="password"
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required={true}
              />
            </MDBox>
            <MDBox sx={{ position: "relative" }}>
              <Link to={"/authentication/reset-password"}>
                <MDBox
                  sx={{
                    float: "right",
                    marginBottom: "1rem",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Forgot Password?
                </MDBox>
              </Link>
            </MDBox>
            <MDBox
              mt={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <input
                id="checkbox"
                type="checkbox"
                checked={termsChecked}
                onClick={() => {
                  setTermsChecked(!termsChecked);
                }}
              />
              <label for="checkbox">
                &nbsp; I agree to these{" "}
                <a
                  href="https://neatprocess.org/terms/privacy-policy"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                .
              </label>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDButton
                variant="gradient"
                sx={(theme) => ({
                  borderRadius: "100px",
                  backgroundColor: theme.palette.primary.main,
                })}
                type="submit"
                onClick={handleSignin}
                disabled={!termsChecked || !key}
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDBox color="text">
                New User?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign Up
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox
              mt={-1}
              mb={1}
              textAlign="center"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <MDBox>Sign in with Microsoft?</MDBox>
              <MDBox
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                })}
                color={"primary"}
                onClick={handleLogin}
              >
                <Icon fontSize="sm" sx={{ marginBottom: "2px" }}>
                  window
                </Icon>
                <MDBox color={"primary"}>Login</MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
