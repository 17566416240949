import CustomInput from "./CustomInput";
import RadioGroup from "./RadioGroup";
import Checkbox from "./Checkbox";
// import DatePicker from "./CustomDatePicker";
import Button from "./Button";
import ListInput from "./ListInput";
import Bullet from "./Bullet";
import KeyValueSelect from "./KeyValueSelect";
import Paragraph from "./Paragraph";
import CustomAutoComplete from "./AutoComplete";
import KeyValueConst from "./KeyValueConst";
import CustomDatePicker from "./CustomDatePicker";
import Amount from "./Amount";
import CustomAutoCompleteMultiple from "./AutoCompleteMultiple";
import Dropdown from "./Dropdown";
import CustomInteger from "./Integer";
import CustomDateRangePicker from "./CustomDateRangePicker";

const Controls = ({ type, DefaultType, ...props }) => {
  const lowerCaseType = type?.toLowerCase();
  switch (lowerCaseType) {
    case "text":
      return <CustomInput {...props} />;
    case "radio":
      return <RadioGroup {...props} />;
    case "dropdownsingleselect":
      return <CustomAutoComplete {...props} />;
    case "dropdownmultiselect":
      return <Dropdown {...props} />;
    case "checkbox":
      return <Checkbox {...props} />;
    case "date":
    case "datetime":
      return <CustomDatePicker {...props} />;
    case "daterange":
    case "date_range":
      return <CustomDateRangePicker {...props} />;
    case "integer":
    case "number":
    case "float":
      return <CustomInteger {...props} />;
    case "amount":
      return <Amount {...props} />;
    case "list":
      return <ListInput {...props} />;
    case "bullet":
      return <Bullet {...props} />;
    case "keyvalue":
      return <KeyValueSelect {...props} />;
    case "paragraph":
      return <Paragraph {...props} />;
    case "keyvalueconstoptions":
      return <KeyValueConst {...props} />;
    default:
      return DefaultType ? <DefaultType {...props} /> : <CustomInput {...props} />;
  }
};

export default Controls;

export const controlTypes = {
  text: "Text",
  radio: "radio",
  dropdown: "DropdownSingleSelect",
  checkBox: "checkbox",
  date: "Date",
  datetime: "datetime",
  integer: "Integer",
  files: "ListofFiles",
};
