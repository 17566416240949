import React, { useEffect, useRef } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
} from "chart.js";
import "chartjs-plugin-dragdata";
import MDBox from "components/MDBox";
import { CircularProgress } from "@mui/material";

// Register necessary Chart.js components
Chart.register(
  CategoryScale, // For the X axis
  LinearScale, // For the Y axis
  PointElement, // For the points on the line
  LineElement, // For the line
  LineController, // For line chart
  BarController,
  BarElement,
  Title, // For the chart title
  Tooltip, // For tooltips
  Legend // For the legend
);

const backgroundBoxPlugin = {
  id: "backgroundBox",
  beforeDraw: (chart) => {
    const { ctx, chartArea, scales } = chart;
    const xScale = scales.x;

    const februaryX = xScale.getPixelForValue("February");
    const aprilX = xScale.getPixelForValue("April");

    const padding = 10;
    const boxLeft = februaryX - padding;
    const boxRight = aprilX + padding;
    const boxTop = chartArea.top - padding;
    const boxBottom = chartArea.bottom + padding;

    ctx.save();
    ctx.fillStyle = "rgba(239, 255, 10, 0.34)";
    ctx.fillRect(boxLeft, boxTop, boxRight - boxLeft, boxBottom - boxTop);

    ctx.fillStyle = "black";
    ctx.font = "14px Arial";
    ctx.fillText("Feb - Apr", boxLeft + 5, boxTop + 20);

    ctx.restore();
  },
};

const DraggableChart = ({
  type = "line",
  data,
  editableRegion,
  editableRegionColor,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && data?.datasets?.length > 0) {
      const ctx = chartRef?.current?.getContext("2d");

      const options = {
        responsive: true,
        scales: {
          x: {
            type: "category",
            labels: data?.labels,
          },
          y: {
            beginAtZero: false,
          },
        },
        plugins: {
          backgroundBox: {},
          dragData: {
            round: 1,
            showTooltip: true,
            onDragEnd: function (event, datasetIndex, index, value) {
              console.log(
                `Dragged point at index ${index} with new value: ${value}`
              );
            },
            onDragStart: function (event, datasetIndex, index) {
              return index === 1 || index === 2 || index === 3;
            },
          },
        },
      };

      const myChart = new Chart(ctx, {
        type: type,
        data: data,
        options: options,
        plugins: editableRegion ? [backgroundBoxPlugin] : null,
      });

      return () => {
        myChart.destroy();
      };
    }
  }, [data]);

  return (
    <>
      {data && data?.datasets?.length > 0 ? (
        <MDBox
          sx={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginLeft: "30px",
          }}
        >
          <canvas ref={chartRef} style={{ width: "100%" }}></canvas>
        </MDBox>
      ) : data?.datasets?.length === 0 ? (
        <EmptyResult text={"No Data Found"} />
      ) : (
        <MDBox
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <CircularProgress color="primary" />
        </MDBox>
      )}
    </>
  );
};

export default DraggableChart;
