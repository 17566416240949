import { useTheme } from "@emotion/react";
import { Collapse, Icon, Switch, Tooltip } from "@mui/material";
import DateRangePicker from "components/controls/DateRangePicker";
import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ChartHolder from "./components/ChartHolder";
import InfoCard from "./components/InfoCard";
import { useDelivery } from "./hooks/useDelivery";
import { deliveryService } from "./service/deliveryService";
import { getDateRange, handleFilterChangeCustom } from "./utils";
import { OutlinedButton } from "components/MDButton/button";
import Controls from "components/controls/Controls";
import Dropdown from "components/controls/Dropdown";
import { convertKeyToName } from "utils/conversion";
import MDChip from "components/MDChip";
import ObjectToTable from "./components/ObjectToTable";
const ignoreFilterList = [
  "days",
  "limit",
  "offset",
  "search",
  "from_date",
  "to_date",
  "category",
  "sku",
];
const ResponsiveGridLayout = WidthProvider(Responsive);

const initialLayout = [
  { i: "deliveries_over_time", x: 0, y: 0, w: 8, h: 6 },
  { i: "STORE", x: 8, y: 0, w: 2, h: 2 },
  { i: "Direct", x: 10, y: 0, w: 2, h: 2 },
  { i: "Collect", x: 8, y: 2, w: 2, h: 2 },
  { i: "Cancelled", x: 10, y: 2, w: 2, h: 2 },
  { i: "zadip", x: 8, y: 4, w: 2, h: 2 },
  { i: "Collect 8ton", x: 10, y: 4, w: 2, h: 2 },

  { i: "getPlannedVsActualAnalysis", x: 0, y: 8, w: 4, h: 5 },
  { i: "getDeliveriesByCustomer", x: 4, y: 8, w: 8, h: 5 },

  { i: "getTopShipments", x: 0, y: 13, w: 8, h: 6 },
  { i: "getMissedDeliveriesAnalysis", x: 8, y: 13, w: 4, h: 6 },

  { i: "getOntimeDeliveries", x: 0, y: 19, w: 3, h: 5 },
  { i: "getRegionPerformance", x: 3, y: 19, w: 6, h: 5 },
  { i: "getOntimeCollections", x: 9, y: 19, w: 3, h: 5 },
];

const DeliveryDashboard = ({ imageRef, tabComponent }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  // const { layout, onLayoutChange } = useGridLayout(initialLayout);
  const { deliveryStatus, filterOptions, filters, setFilters } = useDelivery();
  const [showFilters, setShowFilters] = useState(false);
  const [tempFilters, setTempFilters] = useState({});
  useEffect(() => {
    if (filters) {
      setTempFilters(filters);
    }
  }, [filters]);

  const handleFilterChange = (event, type) => {
    setTempFilters(handleFilterChangeCustom(event, tempFilters, type));
  };

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const [globalDateRange, setGlobalDateRange] = useState({});
  const [enableDrag, setEnableDrag] = useState(false);

  const cardStyle = {
    borderRadius: "10px",
    backgroundColor: white.main,
    boxShadow: "0px 0px 8px 1px #0000000D",
  };

  const handleSelectTab = (tab) => {
    if (tab === "Clear") {
      setActiveTab("");
      setGlobalDateRange({});
    } else {
      setActiveTab(tab);
      setGlobalDateRange(getDateRange(tab));
    }
  };
  const handleDateRangeChange = (type, date) => {
    const tDate = new Date(date);
    setActiveTab("Custom");
    if (type === "from") {
      setGlobalDateRange((pre) => ({ ...pre, from: tDate?.toISOString() }));
    } else {
      setGlobalDateRange((pre) => ({ ...pre, to: tDate?.toISOString() }));
    }
  };

  return (
    <>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          {tabComponent}
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "1em",
            gap: "5px",
          }}
        >
          <OutlinedButton
            sx={{
              backgroundColor: showFilters ? "#D6D9FF" : white.main,
              border: "none",
              borderRadius: "1em",
            }}
            onClick={() => setShowFilters(!showFilters)}
            name={"Filter"}
            icon={<Icon fontSize="1em">filter_alt</Icon>}
          />
          {tabs.map((tab) => (
            <MDBox
              sx={{
                padding: "0.5em 1em",
                textAlign: "center",
                borderRadius: "1em",
                cursor: "pointer",
                backgroundColor: activeTab === tab ? primary.main : white.main,
              }}
              color={activeTab === tab ? white.main : text.disabled}
              onClick={() => handleSelectTab(tab)}
            >
              {tab}
            </MDBox>
          ))}
          <MDBox>
            <DateRangePicker
              from={globalDateRange?.from}
              to={globalDateRange?.to}
              onChangeFrom={(date) => handleDateRangeChange("from", date)}
              onChangeTo={(date) => handleDateRangeChange("to", date)}
            />
          </MDBox>
          <Tooltip title="Customize Layout">
            <Switch
              onChange={(e, value) => {
                setEnableDrag(value);
              }}
              value={enableDrag}
              sx={{
                "& .Mui-checked .MuiSwitch-thumb": {
                  borderColor: primary.main + " !important",
                },
                "& .Mui-checked+.MuiSwitch-track": {
                  borderColor: primary.main + " !important",
                  backgroundColor: primary.main + " !important",
                },
              }}
            />
          </Tooltip>
        </MDBox>
      </MDBox>
      {filterOptions && filters && (
        <Collapse in={showFilters}>
          <MDBox
            sx={{
              display: "flex",
              backgroundColor: white.main,
              borderRadius: "10px",
              padding: "1rem",
              marginTop: "1rem",
              boxShadow: "0px 0px 8px 1px #0000000D",
            }}
          >
            <MDBox
              sx={{
                width: "100%",
                display: "grid",
                columnGap: "1rem",
                gridTemplateColumns: "repeat(3, 1fr)",
                "@media (max-width: 800px)": {
                  gridTemplateColumns: "repeat(2, 1fr)",
                },
              }}
            >
              {filterOptions?.map((input, index) => {
                const filterName = input?.field || input?.name;
                if (!ignoreFilterList.includes()) {
                  return (
                    <Controls
                      type={input?.type}
                      DefaultType={Dropdown}
                      key={index}
                      name={filterName}
                      value={tempFilters[filterName]}
                      onChange={handleFilterChange}
                      dataKey={"value"}
                      options={input?.options || []}
                      getOptionLabel={(option) => {
                        return option?.name ? option.name : option;
                      }}
                      placeholder={
                        tempFilters[filterName] != "" ? "" : "Select"
                      }
                      label={convertKeyToName(filterName)}
                      size={"small"}
                    />
                  );
                }
              })}
            </MDBox>
            <MDBox
              sx={{
                width: "30%",
                display: "flex",
                alignItems: "flex-end",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <MDBox
                color={primary.main}
                onClick={() => setShowFilters(!showFilters)}
                sx={{
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              >
                x Close
              </MDBox>
              <MDChip
                sx={{
                  backgroundColor: secondary.main,
                  padding: "5px 40px",
                  color: white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontSize: "14px",
                  height: "40px",
                  borderRadius: "100px",
                  marginBottom: "10px",
                }}
                label={"Apply All Filters"}
                onClick={() => {
                  setFilters(tempFilters);
                }}
              />
            </MDBox>
          </MDBox>
        </Collapse>
      )}
      <MDBox ref={imageRef}>
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: initialLayout }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 12 }}
          rowHeight={50}
          margin={[15, 15]}
          // onLayoutChange={onLayoutChange}
        >
          <MDBox sx={cardStyle} key="deliveries_over_time">
            <ChartHolder
              initialFilters={filters}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Deliveries by Time"}
              id={"deliveries_over_time"}
              getData={deliveryService.getDeliveriesOverTime}
              // showFilter={false}
              chartProps={{
                grid: { vertical: false },
                type: "area",
                graph: {
                  total: { fillOpacity: 0.3, type: "monotone", strokeWidth: 2 },
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="STORE">
            <InfoCard
              enableDrag={enableDrag}
              title={"Store"}
              iconName={"store"}
              iconColor={"#676DFF"}
              mainDataName={"Count"}
              mainData={deliveryStatus?.[2].count}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="Direct">
            <InfoCard
              enableDrag={enableDrag}
              title={"Direct"}
              iconName={"rocket_launch"}
              iconColor={"#33C23A"}
              mainDataName={"Count"}
              mainData={deliveryStatus?.[11].count}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="Collect">
            <InfoCard
              enableDrag={enableDrag}
              title={"Collect"}
              iconName={"deployed_code"}
              iconColor={"#ff00c6"}
              mainDataName={"Count"}
              mainData={deliveryStatus?.[0].count}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="Cancelled">
            <InfoCard
              enableDrag={enableDrag}
              title={"Cancelled"}
              iconName={"cancel"}
              iconColor={"#EF5350"}
              mainDataName={"Count"}
              mainData={deliveryStatus?.[6].count}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="zadip">
            <InfoCard
              enableDrag={enableDrag}
              title={"ZADIP"}
              iconName={"star"}
              iconColor={"#ff7b70"}
              mainDataName={"Count"}
              mainData={deliveryStatus?.[0].count}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="Collect 8ton">
            <InfoCard
              enableDrag={enableDrag}
              title={"Collect 8ton"}
              iconName={"workspaces"}
              iconColor={"#34d7b0"}
              mainDataName={"Count"}
              mainData={deliveryStatus?.[13].count}
            />
          </MDBox>
          <MDBox sx={cardStyle} key="getPlannedVsActualAnalysis">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              initialFilters={filters}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Planned Vs Actual Analysis"}
              getData={deliveryService.getPlannedVsActualAnalysis}
              id={"getPlannedVsActualAnalysis"}
              // showFilter={false}
              chartProps={{
                name: "name",
                type: "pie",
                dataKey: "count",
                graph: {
                  innerRadius: 40,
                  outerRadius: 70,
                  paddingAngle: 3,
                  showPercentage: false,
                },
                legend: {
                  show: false,
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="getDeliveriesByCustomer">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              initialFilters={filters}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Deliveries By Customer"}
              getData={deliveryService.getDeliveriesByCustomer}
              id={"getDeliveriesByCustomer"}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                radius: 0,
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="getTopShipments">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              initialFilters={filters}
              title={"Top Shipments"}
              id={"getTopShipments"}
              getData={deliveryService.getTopShipments}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                radius: 0,
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="getMissedDeliveriesAnalysis">
            <ChartHolder
              CustomComponent={ObjectToTable}
              customComponentProps={{
                sx: { height: "100%", overflow: "auto", marginLeft: "1.5em" },
              }}
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              globalDateRange={globalDateRange}
              initialFilters={filters}
              filterOptions={filterOptions}
              title={"Missed Deliveries Analysis"}
              id={"getMissedDeliveriesAnalysis"}
              getData={deliveryService.getMissedDeliveriesAnalysis}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="getOntimeDeliveries">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              initialFilters={filters}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"On time Deliveries"}
              getData={deliveryService.getOntimeDeliveries}
              id={"getOntimeDeliveries"}
              // showFilter={false}
              chartProps={{
                name: "name",
                type: "pie",
                dataKey: "count",
                graph: {
                  innerRadius: 40,
                  outerRadius: 70,
                  paddingAngle: 3,
                  showPercentage: false,
                },
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="getRegionPerformance">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              initialFilters={filters}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"Region Performance"}
              getData={deliveryService.getRegionPerformance}
              id={"getRegionPerformance"}
              chartProps={{
                type: "bar",
                grid: { vertical: false },
                radius: 0,
              }}
            ></ChartHolder>
          </MDBox>
          <MDBox sx={cardStyle} key="getOntimeCollections">
            <ChartHolder
              enableDrag={enableDrag}
              globalDateActiveTab={activeTab}
              initialFilters={filters}
              globalDateRange={globalDateRange}
              filterOptions={filterOptions}
              title={"On time Collections"}
              getData={deliveryService.getOntimeCollections}
              id={"getOntimeCollections"}
              // showFilter={false}
              chartProps={{
                name: "name",
                type: "pie",
                dataKey: "count",
                graph: {
                  innerRadius: 40,
                  outerRadius: 70,
                  paddingAngle: 3,
                  showPercentage: false,
                },
              }}
            ></ChartHolder>
          </MDBox>
        </ResponsiveGridLayout>
      </MDBox>
    </>
  );
};

export default DeliveryDashboard;
