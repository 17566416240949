import { useTheme } from "@emotion/react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { CircularProgress, InputBase } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect } from "react";

const SearchBar = ({
  placeholder,
  handleKeyUp,
  handleSearch,
  searchOnEnterPress,
  inputRef,
  fontColor,
  fontSize,
  isLoading,
  sx,
  focusOnOpen,
  serchIconPosition = "left",
  disabled,
  iconColor,
  ...props
}) => {
  const { palette, functions } = useTheme();
  const { primary, text } = palette;
  const { pxToRem } = functions;

  const handleOnKeyUp = (e) => {
    if (searchOnEnterPress && e.key === "Enter") {
      handleSearch();
    }
    if (handleKeyUp) handleKeyUp(e);
  };

  useEffect(() => {
    if (inputRef?.current && focusOnOpen) {
      inputRef.current.focus();
    }
  }, [inputRef?.current, disabled]);

  const SearchIcon = () => (
    <MDBox
      sx={{
        fontSize: pxToRem(22),
        color: iconColor|| fontColor || primary.main,
        height: "100%",
        display: "flex",
        alignItems: "center",
        cursor: !isLoading && handleSearch ? "pointer" : "",
      }}
    >
      {isLoading ? (
        <CircularProgress
          color="primary"
          sx={{ height: "20px !important", width: "20px !important" }}
        />
      ) : (
        <SearchOutlinedIcon
          onClick={() => (handleSearch ? handleSearch() : {})}
        />
      )}
    </MDBox>
  );

  return (
    <MDBox
      sx={{
        display: "flex",
        gap: "5px",
        padding: "2px 10px",
        alignItems: "center",
        border: "1px solid " + primary.main,
        borderRadius: "100px",
        ...sx,
      }}
      {...props}
    >
      {serchIconPosition === "left" && <SearchIcon />}
      <InputBase
        sx={{
          fontSize: fontSize || pxToRem(13),
          color: fontColor || text.disabled,
          "& .MuiInputBase-input::placeholder": {
            color: fontColor || text.disabled,
            opacity: 1,
          },
          width: "100%",
          "& .MuiInputBase-input": {
            width: "100%",
          },
        }}
        inputRef={inputRef}
        placeholder={placeholder}
        onKeyUp={(e) => {
          handleOnKeyUp(e);
        }}
        disabled={disabled}
      />
      {serchIconPosition !== "left" && <SearchIcon />}
    </MDBox>
  );
};

export default SearchBar;
