import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import Chart from "examples/chart/Chart";
import { useEffect, useRef, useState } from "react";
import {
  downloadImage,
  getFiltersCount,
  handleFilterChangeCustom,
} from "../utils";
import ChartHolderPopup from "./ChartHolderPopup";
import Leaderboard from "./Leaderboard";
import ObjectToTable from "./ObjectToTable";

const ignoreFilterList = [
  "days",
  "limit",
  "offset",
  "search",
  "from_date",
  "to_date",
  "category",
  "sku",
];

const ChartHolder = ({
  title,
  children,
  chartProps,
  getData,
  id,
  showFilter,
  filterOptions,
  globalDateActiveTab,
  globalDateRange,
  enableDrag,
  chartInsightsAccessor,
  isGetChatInsightsByApi,
  dataAccessor,
  CustomComponent,
  initialFilters,
  customComponentProps,
  enableTableView,
  formatTableData,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  const storedFilters = {};
  const [chartData, setChartData] = useState(null);
  const [filters, setFilters] = useState(storedFilters?.[id] || null);
  const [filtersCount, setFiltersCount] = useState(0);
  const [tempFilters, setTempFilters] = useState(null);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [isLoadingInsights, setIsLoadingInsights] = useState(false);
  const [showTableView, setShowTableView] = useState(false);

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const imageRef = useRef(null);

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters);
      setTempFilters(initialFilters);
    }
  }, [initialFilters]);

  const handleSelectTab = (tab, dateRange) => {
    if (tab === "Clear") {
      setActiveTab("");
      setFilters((pre) => ({ ...pre, from_date: "", to_date: "" }));
    } else {
      setActiveTab(tab);
      setFilters((pre) => ({
        ...pre,
        from_date: dateRange?.from || "",
        to_date: dateRange?.to || "",
      }));
    }
  };

  useEffect(() => {
    if (filters) {
      setTempFilters(filters);
      setFiltersCount(getFiltersCount(filters, ignoreFilterList));
      setChartData(null);
      if (getData && filters) {
        getData(filters)
          ?.then((data) => {
            setChartData(data);
            if (isGetChatInsightsByApi) {
              setIsLoadingInsights(true);
              getData(filters, true)
                ?.then((data) => {
                  setChartData(data);
                  setIsLoadingInsights(false);
                })
                .catch((err) => {
                  setIsLoadingInsights(false);
                });
            }
          })
          .catch((err) => {});
      }
    }
  }, [getData, filters]);
  const handleFilterChange = (event, type) => {
    setTempFilters(handleFilterChangeCustom(event, tempFilters, type));
  };

  const handleClosePopup = () => {
    setIsPopupActive(false);
    setTempFilters(filters);
  };

  const handleApplyFiter = () => {
    setFilters(tempFilters);
  };

  const handleClick = () => {
    setIsPopupActive(true);
  };

  useEffect(() => {
    if (globalDateActiveTab && globalDateRange) {
      handleSelectTab(globalDateActiveTab, globalDateRange);
    }
  }, [globalDateRange]);

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, key) => {
      return acc && acc[key] !== undefined ? acc[key] : undefined;
    }, obj);
  };

  return (
    <MDBox
      sx={{
        height: "100%",
        width: "100%",
        padding: "1em",
        position: "relative",
        backgroundColor: white.main,
        transition: "all 100ms",
        borderRadius: "10px",
        ...(enableDrag
          ? {
              boxShadow: "0px 0px 15px 1px #0000001a",
              transform: "rotate(-1deg)",
            }
          : {}),
      }}
      onClick={(e) => {
        if (!enableDrag) e.stopPropagation();
      }}
      onMouseDown={(e) => {
        if (!enableDrag) e.stopPropagation();
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <MDBox color={text.main}>{title}</MDBox>
        <MDBox sx={{ display: "flex", alignItems: "center", gap: "2em" }}>
          <MDBox sx={{ display: "flex", alignItems: "center", gap: ".5em" }}>
            {/* {showFilter !== false && <MDBox
              sx={{
                backgroundColor: primary.light,
                fontWeight: typography.fontWeightMedium,
                borderRadius: "1em",
                width: "40px",
                height: "30px",
                textAlign: "center",
                paddingTop: "5px",
                cursor: "pointer",
              }}
              color={text.disabled}
            >
              1M
            </MDBox>} */}
          </MDBox>

          <MDBox sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
            {enableTableView && chartData && (
              <span
                style={{
                  color: text.disabled,
                  backgroundColor: showTableView ? "#D6D9FF" : "",
                  borderRadius: "100px",
                  padding: "5px",
                  fontSize: "1.5em",
                  cursor: "pointer",
                }}
                class="material-symbols-outlined"
                onClick={() => setShowTableView((pre) => !pre)}
              >
                table
              </span>
            )}
            {showFilter !== false && (
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                <MDBox sx={{ position: "relative" }}>
                  {filtersCount !== 0 && (
                    <MDBox
                      sx={{
                        position: "absolute",
                        backgroundColor: primary.main,
                        top: "-1.5em",
                        right: "-2.6em",
                        transform: "scale(.8)",
                        width: "20px",
                        height: "20px",
                        textAlign: "center",
                        fontSize: "12px",
                        borderRadius: "100px",
                        paddingTop: "1px",
                      }}
                      color={white.main}
                    >
                      {filtersCount}
                    </MDBox>
                  )}
                </MDBox>
                <span
                  style={{
                    color: text.disabled,
                    fontSize: "1.5em",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                  class="material-symbols-outlined"
                >
                  filter_alt
                </span>
              </MDBox>
            )}
            <span
              style={{
                color: text.disabled,
                fontSize: "1.5em",
                cursor: "pointer",
              }}
              class="material-symbols-outlined"
              onClick={handleClick}
            >
              open_in_full
            </span>
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox
        sx={{
          height: "calc(100% - 40px)",
          marginRight: "1.5em",
        }}
        ref={chartProps ? imageRef : null}
      >
        {showTableView && enableTableView ? (
          <ObjectToTable
            sx={{ height: "100%", overflow: "auto", marginLeft: "1.5em" }}
            data={
              formatTableData
                ? formatTableData(
                    dataAccessor
                      ? getNestedValue(chartData, dataAccessor)
                      : chartData
                  )
                : dataAccessor
                ? getNestedValue(chartData, dataAccessor)
                : chartData
            }
          />
        ) : CustomComponent ? (
          <CustomComponent
            data={
              dataAccessor ? getNestedValue(chartData, dataAccessor) : chartData
            }
            {...customComponentProps}
          />
        ) : (
          <Chart
            {...chartProps}
            data={
              dataAccessor ? getNestedValue(chartData, dataAccessor) : chartData
            }
          />
        )}
      </MDBox>
      <MDBox
        sx={{
          position: "absolute",
          top: "100%",
          left: "100%",
          transform: "translate(-2.5em, -2em)",
          width: "1.5em",
          height: "1.5em",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <span
          style={{
            color: text.disabled,
            fontSize: "1.5em",
            cursor: "pointer",
          }}
          class="material-symbols-outlined"
          onClick={() => downloadImage(imageRef.current, title)}
        >
          photo_camera
        </span>
      </MDBox>
      {isPopupActive && filterOptions && (
        <ChartHolderPopup
          title={title}
          tabs={tabs}
          activeTab={activeTab}
          handleSelectTab={handleSelectTab}
          chart={
            showTableView && enableTableView ? (
              <ObjectToTable
                sx={{ height: "100%", overflow: "auto", marginLeft: "1.5em" }}
                data={
                  formatTableData
                    ? formatTableData(
                        dataAccessor
                          ? getNestedValue(chartData, dataAccessor)
                          : chartData
                      )
                    : dataAccessor
                    ? getNestedValue(chartData, dataAccessor)
                    : chartData
                }
              />
            ) : CustomComponent ? (
              <CustomComponent
                {...customComponentProps}
                data={
                  dataAccessor
                    ? getNestedValue(chartData, dataAccessor)
                    : chartData
                }
              />
            ) : (
              <Chart
                {...chartProps}
                data={
                  dataAccessor
                    ? getNestedValue(chartData, dataAccessor)
                    : chartData
                }
              />
            )
          }
          handleFilterChange={handleFilterChange}
          handleClosePopup={handleClosePopup}
          handleApplyFiter={handleApplyFiter}
          filters={tempFilters}
          filterOptions={filterOptions}
          showFilter={showFilter !== false}
          ignoreFilterList={ignoreFilterList}
          chartInsights={
            chartInsightsAccessor ? chartData?.[chartInsightsAccessor] : null
          }
          isLoadingInsights={isLoadingInsights}
          showTableView={showTableView}
          setShowTableView={setShowTableView}
          enableTableView={enableTableView}
        />
      )}
    </MDBox>
  );
};

export default ChartHolder;
