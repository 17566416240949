import { integrationAPI } from "api";

export const fileUploadService = {
  getFileUploadData(data){
    return integrationAPI.getFileUploadData(data)
  },
  getProcessedData(id, sourceType, sourceId){
    return integrationAPI.getProcessedData(id, sourceType, sourceId)
  },
  getFileUploadFilters(sourceType, sourceId){
    return integrationAPI.getFileUploadFilters(sourceType, sourceId)
  },
  uploadFile(file, sourceId){
    return integrationAPI.uploadFile(file, sourceId)
  }
};
