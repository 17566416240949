import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import DataTable from "examples/Tables/DataTable";
import {
  clearAllTableFilters,
  handleFilterChangeForCustomTable,
} from "examples/Tables/DataTable/utils";
import { setGlobalState } from "Global";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useDatabase from "./hooks/useDatabase";
import { databaseService } from "./service/databaseService";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import useIntegration from "../useIntegration";
import { ConfigSettings, SourcesDropdown } from "../components/Config";
import FlowChart from "../components/FlowChart";

const ignoreFilterList = ["limit", "offset", "search", "page_no"];

const Database = () => {
  const { dbname } = useParams();
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [applyFilterFlag, setApplyFilterFlag] = useState(false);
  const [activeData, setActiveData] = useState(null);
  const [isLoadingIngetion, setIsLoadingIngetion] = useState(false);
  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const [showFlowChart, setShowFlowChart] = useState(false);

  const {
    sources,
    setSources,
    activeSourceId,
    setActiveSourceId,
    integrationLoading,
    setIntegrationLoading,
  } = useIntegration(dbname);

  const {
    isLoading,
    setIsLoading,
    filterOptions,
    rows,
    columns,
    pages,
    filters,
    setFilters,
    scheduleData,
    setScheduleData,
  } = useDatabase({
    applyFilterFlag,
    dbname,
    activeSourceId,
  });

  const defaultInputs = {
    name: {
      type: "Text",
      editable: true,
      value: scheduleData.name,
    },
    interval: {
      label: "Interval in Minutes",
      type: "Number",
      editable: true,
      required: true,
      value: scheduleData.interval,
    },
    enabled: {
      type: "DropdownSingleSelect",
      options: ["true", "false"],
      editable: true,
      required: true,
      value: scheduleData.enabled,
    },
  };

  const handleFilterChange = (event, type) => {
    setFilters(handleFilterChangeForCustomTable(event, filters, type));
  };

  let debounceTimer;

  const handleSearchChange = (text, type) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilters((prevValue) => {
        return { ...prevValue, search: text };
      });
      handleClickApplyFilter();
    }, 300);
  };

  const getIngestionDetails = (id) => {
    setIsLoadingIngetion(true);
    databaseService
      .getIngestionDetails(id, dbname, activeSourceId)
      .then((res) => {
        if (res.status == 200) {
          setActiveData(res.data);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingIngetion(false);
      });
  };

  const handleInitiateIngestionClick = () => {
    if (!isLoading) {
      setIsLoading(true);
      databaseService
        .initiateIngestion(dbname, activeSourceId)
        .then((res) => {
          if (res.status == 200) {
            setGlobalState("error", {
              open: true,
              message: "Ingestion successfull!",
              type: "success",
            });
            handleClickApplyFilter();
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onRowClick = (row) => {
    getIngestionDetails(row.values.ingestion_id.props.data);
  };
  const clearAllFilter = () => {
    setFilters(clearAllTableFilters(filters));
    handleClickApplyFilter();
  };
  const handleClickApplyFilter = () => {
    setApplyFilterFlag(Math.random());
  };

  const handleSchedule = (data, type) => {
    setIsLoading(true);
    const dynamicFunction =
      type === "create"
        ? databaseService.createIngestionSchedules
        : databaseService.updateIngestionSchedules;
    dynamicFunction(data.name, data.interval, data.enabled, activeSourceId)
      .then((res) => {
        if (res.status == 200) {
          setScheduleData({
            name: data.name,
            interval: data.interval,
            enabled: data.enabled,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              database
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Link to={"/integrations"}>
              <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
                Integrations
              </MDBox>
            </Link>
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "1.5em" }}
            >
              chevron_right
            </span>
            <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
              {dbname?.toUpperCase()}
            </MDBox>
            <SourcesDropdown
              sources={sources}
              setActiveSourceId={setActiveSourceId}
              activeSourceId={activeSourceId}
            />
          </MDBox>
        }
        rightContent={
          <MDBox
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <OutlinedButton
              name={"Flow Chart"}
              sx={{
                backgroundColor: showFlowChart ? palette.primary.light : "",
              }}
              onClick={() => {
                setShowFlowChart((pre) => !pre);
              }}
              icon={<span class="material-symbols-outlined">account_tree</span>}
            />
            <OutlinedButton
              name={"Initiate Ingestion"}
              onClick={() => {
                handleInitiateIngestionClick();
              }}
              icon={
                <span
                  style={{ fontSize: "1.3em" }}
                  class="material-symbols-outlined"
                >
                  sync
                </span>
              }
            />
            <OutlinedButton
              name={"Schedule Ingestion"}
              onClick={() => {
                setShowScheduleDialog(true);
              }}
              icon={
                <span
                  style={{ fontSize: "1.3em" }}
                  class="material-symbols-outlined"
                >
                  schedule
                </span>
              }
            />
            <ConfigSettings
              type={dbname}
              sources={sources}
              setSources={setSources}
              activeSourceId={activeSourceId}
              setActiveSourceId={setActiveSourceId}
              integrationLoading={integrationLoading}
              setIntegrationLoading={setIntegrationLoading}
            />
          </MDBox>
        }
      />
      {showFlowChart ? (
        <FlowChart
          type={dbname}
          sources={sources}
          setSources={setSources}
          integrationLoading={integrationLoading}
          setIntegrationLoading={setIntegrationLoading}
        />
      ) : (
        <MDBox
          sx={{
            position: "relative",
          }}
        >
          <DataTable
            table={{ columns: columns, rows: rows || [] }}
            isSorted={true}
            entriesPerPage={{ defaultValue: filters?.limit }}
            showTotalEntries={true}
            noEndBorder
            canSearch={true}
            rowClick={onRowClick}
            filters={filters}
            filterOptions={filterOptions}
            handleFilterChange={handleFilterChange}
            clearAllFilter={clearAllFilter}
            handleSearchChange={(text) => {
              handleSearchChange(text);
            }}
            downloadable={false}
            customPagination={true}
            pageInfo={pages}
            isLoading={isLoading}
            handleClickApplyFilter={handleClickApplyFilter}
            tableHeight={"calc(100vh - 184px)"}
            ignoreFilterList={ignoreFilterList}
          />
        </MDBox>
      )}

      {(activeData || isLoadingIngetion) && (
        <MDBox
          onClick={() => {
            setActiveData(null);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            zIndex: "999999",
            height: "100vh",
            width: "100vw",
            left: "0",
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <style jsx>{`
            .MuiPopper-root,
            .MuiDialog-root {
              z-index: 99999999 !important;
            }
          `}</style>
          <MDBox
            sx={{
              height: "calc(100vh - 100px)",
              width: "calc(100% - 100px)",
              overflow: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <DataTable
              table={{
                columns: activeData?.column_data || [],
                rows: activeData?.data || [],
              }}
              isSorted={true}
              canSearch={true}
              showTotalEntries={true}
              noEndBorder
              downloadable={true}
              isLoading={isLoadingIngetion}
              entriesPerPage={{ defaultValue: 50 }}
              tableHeight={"calc(100% - 78px)"}
            />
          </MDBox>
        </MDBox>
      )}

      {showScheduleDialog && (
        <DataInputDialog
          open={showScheduleDialog}
          handleClose={() => {
            setShowScheduleDialog(null);
          }}
          defaultInputs={defaultInputs}
          handleSubmit={(data) => {
            handleSchedule(data, scheduleData.interval ? "update" : "create");
          }}
          buttonName={scheduleData.interval ? "Update" : "Create"}
          heading={"Schedule Ingestion"}
          iconName={"schedule"}
          isLoading={isLoading}
        />
      )}
    </DashboardLayout>
  );
};

export default Database;
