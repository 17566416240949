import { useTheme } from "@emotion/react";
import { Slide, TextField } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import Checkbox from "components/controls/Checkbox";
import { controlTypes } from "components/controls/Controls";
import CustomInput from "components/controls/CustomInput";
import Dropdown from "components/controls/Dropdown";
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import { useState, useRef, useEffect } from "react";
import PermissionsTable from "./PermissionsTable";
import EmptyResult from "utils/emptyResult";
import { convertKeyToName } from "utils/conversion";
import Controls from "components/controls/Controls";

export const defaultPermissions = {
  admin: {
    edit: false,
    view: false,
  },
  superuser: {
    edit: false,
    view: false,
  },
  agent1: {
    edit: false,
    view: false,
  },
  agent2: {
    edit: false,
    view: false,
  },
  "3PA": {
    edit: false,
    view: false,
  },
  "3PU": {
    edit: false,
    view: false,
  },
};

const controllTypeToTextMap = {
  DropdownSingleSelect: "Dropdown",
  Text: "Text",
  Date: "Date",
  Integer: "Integer",
  ListofFiles: "Files",
};

const FormBuilder = ({
  defaultInputs,
  inputTypes,
  handleSubmit,
  heading = "Form Builder",
  handleClose,
  showPermission = true,
  nameKey = "name",
  typeKey = "type",
  extraFields,
  topInfo,
  setInputBack,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, error } = palette;
  const { pxToRem } = functions;

  const [inputs, setInputs] = useState(defaultInputs);
  const [focusedFields, setFocusedFields] = useState({});

  useEffect(() => {
    if (setInputBack && inputs) {
      setInputBack(Object.values(inputs));
    }
  }, [inputs]);

  const handleFocus = (key) => {
    setFocusedFields((prev) => ({ ...prev, [key]: true }));
  };

  const handleBlur = (key) => {
    setFocusedFields((prev) => ({ ...prev, [key]: false }));
  };

  // useEffect(() => {
  //   if (defaultInputs) {
  //     setInputs(defaultInputs);
  //   }
  // }, [defaultInputs]);

  const [activeInputEditKey, setActiveInputEditKey] = useState(null);
  const inputContainerRef = useRef(null);

  const handleAddInput = (type) => {
    let temp = {
      [nameKey]: "",
      [typeKey]: type,
    };
    if (showPermission) {
      temp.permissions = defaultPermissions;
    }
    if (extraFields) {
      extraFields.forEach((data) => {
        if (!data.onlyFor || data.onlyFor === type) {
          temp[data.name] = data.value;
        }
      });
    }
    const newInputs =
      type === controlTypes.dropdown
        ? { ...inputs, [Math.random()]: { ...temp, scope: [] } }
        : { ...inputs, [Math.random()]: { ...temp } };

    setInputs(newInputs);
  };

  useEffect(() => {
    if (inputContainerRef.current) {
      inputContainerRef.current.scrollTop =
        inputContainerRef.current.scrollHeight;
    }
  }, [inputs]);

  return (
    <MDBox
      p={"1.5rem"}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: typography.size["md"],
          fontWeight: typography.fontWeightBold,
        }}
        pb={1}
      >
        <MDBox sx={{ fontSize: "inherit" }}>{heading}</MDBox>
        <span
          class="material-symbols-outlined"
          style={{
            color: primary.main,
            borderRadius: "100%",
          }}
        >
          edit
        </span>
      </MDBox>
      <MDBox
        sx={{
          width: "100%",
          height: "calc(100% - 70px)",
          display: "flex",
          gap: "1rem",
        }}
      >
        <MDBox sx={{ width: "100%", height: "100%" }}>
          <MDBox sx={{ display: "flex", gap: "1rem", height: "100%" }}>
            <MDBox
              ref={inputContainerRef}
              sx={{
                flex: "1",
                height: "100%",
                overflow: "auto",
                scrollBehavior: "smooth",
                position: "relative",
              }}
            >
              {topInfo}
              {Object.keys(inputs).length > 0 ? (
                Object.keys(inputs).map((key, index) => (
                  <MDBox
                    sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
                    key={key}
                  >
                    <MDBox
                      sx={{ width: "100%", display: "flex", gap: "1rem" }}
                      onClick={() => {
                        setActiveInputEditKey(key);
                      }}
                    >
                      <CustomInput
                        label={"Field Name"}
                        value={inputs[key]?.[nameKey]}
                        name={key}
                        disabled={true}
                        size={"small"}
                        sx={{
                          pointerEvents: "none",
                        }}
                      />
                      <CustomInput
                        label={"Type"}
                        value={
                          controllTypeToTextMap[inputs[key]?.[typeKey]] ||
                          inputs[key]?.[typeKey]
                        }
                        name={key}
                        disabled={true}
                        size={"small"}
                        sx={{
                          pointerEvents: "none",
                        }}
                      />
                    </MDBox>
                    <span
                      className="material-symbols-outlined"
                      style={{
                        color: primary.main,
                        borderRadius: "100%",
                        cursor: "pointer",
                        fontSize: "1.3em",
                      }}
                      onClick={() => {
                        setActiveInputEditKey(key);
                      }}
                    >
                      edit
                    </span>
                    <span
                      className="material-symbols-outlined"
                      style={{
                        color: error.main,
                        borderRadius: "100%",
                        cursor: "pointer",
                        fontSize: "1.3em",
                        marginRight: "1rem",
                      }}
                      onClick={() => {
                        let temp = { ...inputs };
                        delete temp[key];
                        setInputs({ ...temp });
                      }}
                    >
                      delete
                    </span>
                  </MDBox>
                ))
              ) : (
                <EmptyResult text={"No Inputs Fields Found"} />
              )}
            </MDBox>
            <MDBox sx={{ width: "25%", maxWidth: "150px" }}>
              {inputTypes?.map((type) => (
                <MDChip
                  sx={{
                    backgroundColor: primary.light,
                    border: "1px solid " + primary.main,
                    padding: "5px 10px",
                    color: primary.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    width: "140px",
                    marginTop: "1rem",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: primary.light,
                      boxShadow: "0 0 5px 1px " + primary.main + "82",
                    },
                  }}
                  label={"Add " + (controllTypeToTextMap[type] || type)}
                  onClick={() => handleAddInput(type)}
                  key={type}
                />
              ))}
            </MDBox>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDChip
              sx={{
                backgroundColor: error.light + "45",
                padding: "5px 10px",
                color: error.main,
                fontWeight: "bold",
                cursor: "pointer",
                border: "1px solid " + error.main,
                width: "140px",
                "&:hover": {
                  backgroundColor: error.light + "45",
                  boxShadow: "0 0 5px 1px " + error.main + "82",
                },
              }}
              label={"Cancel"}
              onClick={handleClose}
            />
            <MDChip
              sx={{
                backgroundColor: primary.main,
                padding: "5px 10px",
                color: white.main,
                fontWeight: "bold",
                cursor: "pointer",
                width: "140px",
                marginTop: ".5rem",
              }}
              label={"Save"}
              onClick={() => handleSubmit(inputs)}
            />
          </MDBox>
        </MDBox>
        <Slide direction="left" in={activeInputEditKey !== null} timeout={500}>
          <MDBox
            p={"1rem"}
            pt={1}
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              width: "30vw",
              maxWidth: "350px",
              minWidth: "300px",
              height: "100vh",
              boxShadow: "-5px 0px 8px 1px #00000017",
              zIndex: 99999,
              backgroundColor: white.main,
              borderRadius: "10px 0 0 10px",
              overflow: "auto",
            }}
          >
            {activeInputEditKey !== null && (
              <>
                <MDBox>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: typography.size["md"],
                      fontWeight: typography.fontWeightBold,
                    }}
                    py={1}
                  >
                    <MDBox sx={{ fontSize: "inherit" }}>Edit Input Field</MDBox>
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: error.main,
                        borderRadius: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => setActiveInputEditKey(null)}
                    >
                      close
                    </span>
                  </MDBox>
                  <CustomInput
                    label={"Field Name"}
                    value={
                      inputs[activeInputEditKey]?.[nameKey] === "Field Name"
                        ? ""
                        : inputs[activeInputEditKey]?.[nameKey]
                    }
                    onChange={(e) => {
                      setInputs((pre) => ({
                        ...pre,
                        [activeInputEditKey]: {
                          ...pre[activeInputEditKey],
                          [nameKey]: e.target.value,
                        },
                      }));
                    }}
                    // InputLabelProps={{
                    //   shrink:
                    //     !!inputs[activeInputEditKey]?.[nameKey] ||
                    //     focusedFields[activeInputEditKey],
                    // }}
                    onFocus={() => handleFocus(activeInputEditKey)}
                    onBlur={() => handleBlur(activeInputEditKey)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <CustomInput
                    label={"Type"}
                    value={inputs[activeInputEditKey]?.[typeKey]}
                    disabled={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {inputs[activeInputEditKey]?.[typeKey] ===
                    controlTypes.dropdown && (
                    <MDBox mt={1} mb={1}>
                      <Dropdown
                        options={[]}
                        value={
                          inputs[activeInputEditKey]?.scope
                            ? [...inputs[activeInputEditKey]?.scope]
                            : ""
                        }
                        defaultValue={[]}
                        getOptionLabel={""}
                        defaultOnChange={true}
                        freeSolo
                        onChange={(e, value) => {
                          const values = value
                            .join(",")
                            .split(",")
                            .map((item) => item.trim())
                            .filter((item) => item.length > 0);

                          setInputs((pre) => ({
                            ...pre,
                            [activeInputEditKey]: {
                              ...pre[activeInputEditKey],
                              scope: values || [],
                            },
                          }));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Dropdown Options"}
                            placeholder={
                              inputs[activeInputEditKey]?.scope?.length > 0
                                ? ""
                                : "Type And Press Enter To Add Options"
                            }
                          />
                        )}
                        InputLabelProps={{
                          shrink: inputs[activeInputEditKey]?.scope?.length > 0,
                        }}
                      />
                    </MDBox>
                  )}
                </MDBox>
                {extraFields &&
                  extraFields.map((feature, index) => {
                    if (
                      !feature.onlyFor ||
                      feature.onlyFor ===
                        inputs?.[activeInputEditKey]?.[typeKey]
                    )
                      return (
                        <MDBox sx={{ width: "100%" }}>
                          <Controls
                            type={feature?.type}
                            name={feature?.name || ""}
                            label={
                              feature?.label || convertKeyToName(feature?.name)
                            }
                            value={
                              inputs?.[activeInputEditKey]?.[feature?.name] ===
                                true ||
                              inputs?.[activeInputEditKey]?.[feature?.name] ===
                                false
                                ? inputs?.[activeInputEditKey]?.[
                                    feature?.name
                                  ] + ""
                                : inputs?.[activeInputEditKey]?.[feature?.name]
                            }
                            dataKey={feature.dataKey}
                            labelKey={feature.labelKey}
                            onChange={(e) => {
                              console.log({
                                [activeInputEditKey]: {
                                  ...inputs[activeInputEditKey],
                                  [feature.name]: e.target.value,
                                },
                              });
                              setInputs((pre) => ({
                                ...pre,
                                [activeInputEditKey]: {
                                  ...pre[activeInputEditKey],
                                  [feature.name]: e.target.value,
                                },
                              }));
                            }}
                            disabled={!feature.editable}
                            options={feature.options || []}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </MDBox>
                      );
                  })}
                {showPermission && (
                  <MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: typography.size["sm"],
                        fontWeight: typography.fontWeightBold,
                      }}
                      py={1}
                    >
                      Edit Permissions
                    </MDBox>
                    <MDBox>
                      <PermissionsTable
                        inputs={inputs}
                        activeInputEditKey={activeInputEditKey}
                        setInputs={setInputs}
                      />
                    </MDBox>
                  </MDBox>
                )}
                <MDChip
                  sx={{
                    backgroundColor: primary.main,
                    padding: "5px 10px",
                    color: white.main,
                    fontWeight: "bold",
                    cursor: "pointer",
                    width: "140px",
                    marginTop: "1rem",
                    width: "100%",
                  }}
                  label={"Close"}
                  onClick={() => setActiveInputEditKey(null)}
                />
              </>
            )}
          </MDBox>
        </Slide>
      </MDBox>
    </MDBox>
  );
};

export default FormBuilder;
