import React, { useEffect, useRef, useState } from "react";
import Datamaps from "datamaps";
import { convertKeyToName } from "utils/conversion";
import MDBox from "components/MDBox";
import EmptyResult from "utils/emptyResult";
import { CircularProgress } from "@mui/material";
import "reactflow/dist/style.css";

const WorldMap = ({
  data,
  isLoading,
  showControlls = true,
  zoomOffset = 100,
  defaultZoom = 500,
  controllsLocation = "vertical bottom left",
}) => {
  const mapRef = useRef(null);
  const [scale, setScale] = useState(defaultZoom);
  const [containerSize, setContainerSize] = useState("");
  const [center, setCenter] = useState({ latitude: 0, longitude: 0 });
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState(null);

  useEffect(() => {
    if (data) {
      setCenter(findCenter(data));
    }
  }, [data]);

  function convertRange(value, oldMin, oldMax) {
    const newMin = 10;
    const newMax = Math.max(scale / 20, 15);
    const newValue =
      ((value * 1 - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin;
    return newValue || 10;
  }

  function findCenter(points) {
    let totalLat = 0;
    let totalLon = 0;
    let numPoints = points.length;
    points.forEach((point) => {
      totalLat += point.latitude;
      totalLon += point.longitude;
    });
    let avgLat = totalLat / numPoints;
    let avgLon = totalLon / numPoints;

    return {
      latitude: avgLat + 10,
      longitude: avgLon,
    };
  }

  const handleMouseDown = (event) => {
    setDragging(true);
    mapRef.current.style.cursor = "grabbing";
    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (!dragging || !dragStart) return;

    const deltaX = event.clientX - dragStart.x;
    const deltaY = event.clientY - dragStart.y;

    const movementFactor = 0.1;
    setCenter({
      latitude: center.latitude + deltaY * movementFactor,
      longitude: center.longitude - deltaX * movementFactor,
    });

    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    mapRef.current.style.cursor = "default";
    setDragStart(null);
  };

  useEffect(() => {
    if (mapRef.current) {
      const handleResize = (entries) => {
        entries.forEach((entry) => {
          const { width, height } = entry.contentRect;
          setContainerSize({ width, height });
        });
      };
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(mapRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (data) {
      const values = data?.map((bomb) => bomb.value);
      const sortedData = data.sort((a, b) => b.value - a.value);
      const mapdata = sortedData.map((data, index) => ({
        ...data,
        radius: convertRange(
          data?.value,
          Math.min(...values),
          Math.max(...values)
        ),
        fillKey: index % 8,
      }));

      const map = new Datamaps({
        element: mapRef.current,
        scope: "world",
        fills: {
          0: "#666CFF",
          1: "#FF7B70",
          2: "#FEB686",
          3: "#34D7B0",
          4: "#D0D2FF",
          5: "#B8FFC7",
          6: "#FFD9E4",
          7: "#FFD9E4",
          defaultFill: "#348dec",
        },
        setProjection: function (element) {
          var projection = d3.geo
            .equirectangular()
            .center([center.longitude, center.latitude])
            .rotate([0, 0]);
          projection =
            data.length > 0
              ? projection
                  .scale(scale)
                  .translate([
                    element?.offsetWidth / 2,
                    element?.offsetHeight / 2,
                  ])
              : projection.translate([
                  element?.offsetWidth / 2,
                  element?.offsetHeight / 2,
                ]);
          var path = d3.geo.path().projection(projection);

          return { path: path, projection: projection };
        },
        geographyConfig: {
          borderColor: "#ffffff",
          highlightBorderColor: "rgba(255, 177, 171, 0.9)",
          highlightFillColor: "rgb(249, 255, 169)",
          popupTemplate: (geo, data) => {
            return `<div class="hoverinfo" style="color">${geo.properties.name}</div>`;
          },
        },
      });
      map.bubbles(mapdata, {
        popupTemplate: function (geo, data) {
          let tooltip = Object.keys(data?.tooltip)
            .map((key) => `${convertKeyToName(key)}: ${data?.tooltip[key]}`)
            .join("<br/>");
          return ['<div class="hoverinfo">' + tooltip + "</div>"].join("");
        },
      });
      const bubbles = mapRef.current.querySelectorAll(".datamaps-bubble");
      bubbles.forEach((bubble) => {
        bubble.addEventListener("click", (event) => {
          const bubbleData = event.target.__data__;
          console.log("Bubble clicked:", bubbleData);
        });
      });

      return () => {
        if (mapRef.current) {
          mapRef.current.innerHTML = "";
        }
      };
    }
  }, [data, scale, center, containerSize]);

  return data && !isLoading ? (
    <div style={{ width: "100%", height: "100%", minHeight: "100px" }}>
      {showControlls && (
        <div
          class={"react-flow__panel react-flow__controls " + controllsLocation}
          data-testid="rf__controls"
          aria-label="React Flow controls"
        >
          <button
            type="button"
            class="react-flow__controls-button react-flow__controls-zoomin"
            title="zoom in"
            aria-label="zoom in"
            onClick={() => setScale((pre) => Math.min(pre + zoomOffset, 1000))}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M32 18.133H18.133V32h-4.266V18.133H0v-4.266h13.867V0h4.266v13.867H32z"></path>
            </svg>
          </button>
          <button
            type="button"
            class="react-flow__controls-button react-flow__controls-zoomout"
            title="zoom out"
            aria-label="zoom out"
            onClick={() => setScale((pre) => Math.max(pre - zoomOffset, 100))}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 5">
              <path d="M0 0h32v4.2H0z"></path>
            </svg>
          </button>
          <button
            type="button"
            class="react-flow__controls-button react-flow__controls-fitview"
            title="fit view"
            aria-label="fit view"
            onClick={() => {
              setScale(defaultZoom);
              setCenter(findCenter(data));
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 30">
              <path d="M3.692 4.63c0-.53.4-.938.939-.938h5.215V0H4.708C2.13 0 0 2.054 0 4.63v5.216h3.692V4.631zM27.354 0h-5.2v3.692h5.17c.53 0 .984.4.984.939v5.215H32V4.631A4.624 4.624 0 0027.354 0zm.954 24.83c0 .532-.4.94-.939.94h-5.215v3.768h5.215c2.577 0 4.631-2.13 4.631-4.707v-5.139h-3.692v5.139zm-23.677.94c-.531 0-.939-.4-.939-.94v-5.138H0v5.139c0 2.577 2.13 4.707 4.708 4.707h5.138V25.77H4.631z"></path>
            </svg>
          </button>
        </div>
      )}
      <div
        id="map"
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        ref={mapRef}
        style={{ height: "100%", width: "100%" }}
      ></div>
    </div>
  ) : (
    <MDBox
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        minHeight: "100px",
      }}
    >
      <CircularProgress color="primary" />
    </MDBox>
  );
};

export default WorldMap;
