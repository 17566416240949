import { useTheme } from "@emotion/react";
import { Autocomplete, Dialog, Select, TextField } from "@mui/material";
import { integrationAPI } from "api";
import { controlTypes } from "components/controls/Controls";
import { CustomPopper } from "components/controls/CustomPopper";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import FormBuilder from "examples/formBuilder/FormBuilder";
import { defaultPermissions } from "examples/formBuilder/FormBuilder";
import { setGlobalState } from "Global";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { convertKeyToName } from "utils/conversion";
import ValidationDialog from "./ValidationDialog";

export const ConfigSettings = ({
  type,
  sources,
  setSources,
  activeSourceId,
  setActiveSourceId,
  integrationLoading,
  setIntegrationLoading,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text, grey } = palette;
  const { pxToRem } = functions;

  const [showInputDialog, setShowInputDialog] = useState(false);
  const [showCreateSourceDialog, setShowCreateSourceDialog] = useState(null);
  const [tempCreateSourceFields, setTempCreateSourceFields] = useState({});
  const [showFormBuilderCreateSource, setShowFormBuilderCreateSource] =
    useState(false);
  const [showFormBuilder, setShowFormBuilder] = useState(false);
  const [sourceDetails, setSourceDetails] = useState(null);
  const [defaultInputs, setDefaultInputs] = useState({});
  const [showValidationDialog, setShowValidationDialog] = useState(false);

  useEffect(() => {
    if (sourceDetails) {
      setDefaultInputs({
        id: {
          type: "Text",
          editable: false,
          value: sourceDetails?.id,
        },
        name: {
          type: "Text",
          editable: true,
          value: sourceDetails?.name,
        },
        description: {
          type: "Text",
          editable: true,
          value: sourceDetails?.description,
        },
        target: {
          type: "Text",
          editable: true,
          required: true,
          value: sourceDetails?.target,
        },
      });
    }
  }, [sourceDetails]);

  useEffect(() => {
    if (activeSourceId) {
      getSourceDetails(activeSourceId);
    }
  }, [activeSourceId]);

  const getSourceDetails = (id) => {
    setIntegrationLoading(true);
    integrationAPI
      .getSourceDetails(id, type)
      .then((res) => {
        if (res.status == 200) {
          let fields = {};
          Object.keys(res.data.fields).forEach((key, index) => {
            fields[key] = {
              name: res.data.fields[key].field,
              permissions:
                res.data.fields[key]?.permissions || defaultPermissions,
              type: res.data.fields[key].type,
              edit: res.data.fields[key].edit,
            };
          });
          setSourceDetails({ ...res.data, fields });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIntegrationLoading(false);
      });
  };

  const updateSourceDetails = (id, name, description, target, fields) => {
    let tempFields = [];
    Object.keys(fields).forEach((key, index) => {
      tempFields.push({
        field: fields[key].name,
        ...fields[key],
      });
    });
    integrationAPI
      .updateSourceDetails({
        integration_type: type,
        id: id,
        source_details: {
          ...sourceDetails,
          id: id,
          name: name,
          description: description,
          target: target,
          fields: tempFields,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setSourceDetails((pre) => ({
            ...pre,
            name,
            description,
            target,
            fields,
          }));
          setGlobalState("error", {
            open: true,
            message: "Source Details Updated successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIntegrationLoading(false);
      });
  };
  const createSource = (name, description, target, fields) => {
    let tempFields = [];
    Object.keys(fields).forEach((key, index) => {
      tempFields.push({
        field: fields[key].name,
        ...fields[key],
      });
    });
    integrationAPI
      .createSource(type, name, description, target, tempFields)
      .then((res) => {
        if (res.status == 200) {
          setSources((pre) => [
            ...pre,
            {
              id: res.data?.id || "",
              name,
              description,
              target,
              fields,
              validation: {},
            },
          ]);
          setGlobalState("error", {
            open: true,
            message: "New Source Created successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setIntegrationLoading(false);
        setTempCreateSourceFields({});
        setShowCreateSourceDialog(false);
      });
  };

  const updateSource = (id, sourceDetails, message) => {
    setIntegrationLoading(true);
    integrationAPI
      .updateSourceDetails({
        integration_type: type,
        id: id,
        source_details: sourceDetails,
      })
      .then((res) => {
        if (res.status == 200) {
          let tempSources = sources.map((data) => {
            if (data.id === id) {
              return { ...data, ...sourceDetails };
            }
            return data;
          });
          setSources(tempSources);
          setGlobalState("error", {
            open: true,
            message: message || "Target Updated successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        integrationLoading(false);
        setShowValidationDialog(false);
      });
  };

  return (
    <>
      <span
        class="material-symbols-outlined"
        style={{
          cursor: "pointer",
          color: primary.main,
        }}
        onClick={() => setShowInputDialog(true)}
      >
        settings
      </span>
      {showCreateSourceDialog && (
        <DataInputDialog
          open={showCreateSourceDialog}
          handleClose={() => {
            setShowCreateSourceDialog(false);
          }}
          defaultInputs={{
            name: {
              type: "Text",
              editable: true,
              required: true,
            },
            description: {
              type: "Text",
              editable: true,
              required: true,
            },
            target: {
              type: "Text",
              editable: true,
              required: true,
            },
          }}
          handleSubmit={(data) => {
            const { name, description, target } = data;
            createSource(name, description, target, tempCreateSourceFields);
          }}
          buttonName={"Create"}
          heading={"Create Source"}
          iconName={"add"}
          isLoading={integrationLoading}
          doubleColAfterInputs={10}
          bottomInfo={
            <>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  fontSize: typography.size["sm"],
                  fontWeight: typography.fontWeightBold,
                }}
                py={1}
                pl={2}
              >
                <MDBox sx={{ fontSize: "inherit" }}>
                  Form Fields (
                  {Object.keys(tempCreateSourceFields)?.length || 0 || 0})
                </MDBox>
                <OutlinedButton
                  name={" Show"}
                  onClick={() => setShowFormBuilderCreateSource(true)}
                  sx={{
                    height: "30px",
                    borderRadius: "100px",
                    paddingLeft: "13px",
                  }}
                  icon={<></>}
                />
              </MDBox>
              <Dialog
                open={showFormBuilderCreateSource}
                onClose={() => setShowFormBuilderCreateSource(false)}
                sx={{
                  "& .MuiPaper-root": {
                    maxWidth: "700px",
                    width: "70vw",
                    height: "70vh",
                  },
                }}
              >
                <style jsx>{`
                  ._loading_overlay_wrapper {
                    height: 100%;
                  }
                `}</style>
                <LoadingOverlay active={integrationLoading} spinner>
                  <MDBox sx={{ minWidth: "30rem", height: "100%" }}>
                    <FormBuilder
                      defaultInputs={tempCreateSourceFields}
                      inputTypes={[
                        controlTypes.text,
                        controlTypes.dropdown,
                        controlTypes.integer,
                        controlTypes.date,
                      ]}
                      handleSubmit={(data) => {
                        setTempCreateSourceFields(data);
                        setShowFormBuilderCreateSource(false);
                      }}
                      heading={"Form Builder"}
                      handleClose={() => {
                        setShowFormBuilderCreateSource(false);
                      }}
                    />
                  </MDBox>
                </LoadingOverlay>
              </Dialog>
            </>
          }
        />
      )}

      {showInputDialog && (
        <DataInputDialog
          open={showInputDialog}
          handleClose={() => {
            setShowInputDialog(null);
          }}
          showCancel={false}
          defaultInputs={defaultInputs}
          handleSubmit={(data) => {
            const { id, name, description, target } = data;
            updateSourceDetails(
              id,
              name,
              description,
              target,
              sourceDetails?.fields
            );
          }}
          buttonName={"Update"}
          heading={"Configurations"}
          iconName={"settings"}
          isLoading={integrationLoading}
          sx={{ width: "35rem" }}
          doubleColAfterInputs={10}
          bottomInfo={
            <>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  fontSize: typography.size["sm"],
                  fontWeight: typography.fontWeightBold,
                }}
                pt={1}
                pl={2}
              >
                <MDBox sx={{ fontSize: "inherit" }}>
                  Form Fields (
                  {sourceDetails?.fields
                    ? Object.keys(sourceDetails?.fields)?.length || 0
                    : 0}
                  )
                </MDBox>
                <OutlinedButton
                  name={"Show"}
                  onClick={() => setShowFormBuilder(true)}
                  sx={{
                    height: "30px",
                    borderRadius: "100px",
                    paddingLeft: "13px",
                  }}
                  icon={<></>}
                />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  fontSize: typography.size["sm"],
                  fontWeight: typography.fontWeightBold,
                }}
                pt={1}
                pl={2}
              >
                <MDBox sx={{ fontSize: "inherit" }}>Validations</MDBox>
                <OutlinedButton
                  name={"Show"}
                  onClick={() => setShowValidationDialog(true)}
                  sx={{
                    height: "30px",
                    borderRadius: "100px",
                    paddingLeft: "13px",
                  }}
                  icon={<></>}
                />
              </MDBox>
              <Dialog
                open={showFormBuilder}
                onClose={() => setShowFormBuilder(false)}
                sx={{
                  "& .MuiPaper-root": {
                    maxWidth: "700px",
                    width: "70vw",
                    height: "70vh",
                  },
                }}
              >
                <style jsx>{`
                  ._loading_overlay_wrapper {
                    height: 100%;
                  }
                `}</style>
                <LoadingOverlay active={integrationLoading} spinner>
                  <MDBox sx={{ minWidth: "30rem", height: "100%" }}>
                    <FormBuilder
                      defaultInputs={sourceDetails?.fields || {}}
                      inputTypes={[
                        controlTypes.text,
                        controlTypes.dropdown,
                        controlTypes.integer,
                        controlTypes.date,
                      ]}
                      handleSubmit={(data) => {
                        setSourceDetails((pre) => ({ ...pre, fileds: data }));
                        setShowFormBuilder(false);
                      }}
                      heading={"Form Builder"}
                      handleClose={() => {
                        setShowFormBuilder(false);
                      }}
                    />
                  </MDBox>
                </LoadingOverlay>
              </Dialog>
              {showValidationDialog && (
                <ValidationDialog
                  open={showValidationDialog}
                  handleClose={() => {
                    setShowValidationDialog(false);
                  }}
                  defaultInputs={{
                    primary_keys: {
                      type: "DropdownMultiSelect",
                      editable: true,
                      required: true,
                      options: [],
                      value: sourceDetails?.validation?.primary_keys,
                    },
                    validation_email: {
                      type: "Text",
                      editable: true,
                      required: true,
                      value: sourceDetails?.validation?.validation_email,
                    },
                    validation_name: {
                      type: "Text",
                      editable: true,
                      required: true,
                      value: sourceDetails?.validation?.validation_name,
                    },
                  }}
                  defaultSheets={sourceDetails?.validation?.sheets}
                  handleSubmit={(data) => {
                    updateSource(
                      activeSourceId,
                      {
                        validation: data,
                      },
                      "Source updated successfully!"
                    );
                  }}
                  buttonName={"update"}
                  heading={"Validation"}
                  iconName={"update"}
                  isLoading={integrationLoading}
                />
              )}
            </>
          }
          sidePanel={
            <MDBox
              sx={{
                paddingRight: "1rem",
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
                width: "200px",
                marginRight: "1rem",
                borderRight: "1px solid " + grey[300],
              }}
            >
              <MDBox>
                {sources?.map((data) => (
                  <MDBox
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingX: "1rem",
                      paddingY: "3px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      border: "1px solid #D6D9FF",
                      borderRadius: "6px",
                      textAlign: "center",
                      marginBottom: "5px",
                      fontWeight: "bold",
                      "&:hover": {
                        color: text.secondary + "!important",
                      },
                    }}
                    color={
                      activeSourceId === data?.id ? primary.main : text.disabled
                    }
                    onClick={() => setActiveSourceId(data?.id)}
                  >
                    {convertKeyToName(data.name)}
                  </MDBox>
                ))}
              </MDBox>

              <OutlinedButton
                name={"Add Source"}
                onClick={() => {
                  setShowCreateSourceDialog(true);
                }}
                sx={{
                  height: "30px",
                  borderRadius: "100px",
                }}
                icon={
                  <span
                    class="material-symbols-outlined"
                    style={{
                      color: primary.main,
                      cursor: "pointer",
                    }}
                  >
                    add
                  </span>
                }
              />
            </MDBox>
          }
        />
      )}
    </>
  );
};

export const SourcesDropdown = ({
  setActiveSourceId,
  sources,
  activeSourceId,
  labelKey,
}) => {
  const [activeSourceName, setActiveSourceName] = useState(null);
  useEffect(() => {
    if (sources && activeSourceId) {
      let data = sources?.filter((data) => data.id == activeSourceId);
      setActiveSourceName(data[0].name);
    }
  }, [sources, activeSourceId]);
  return (
    <>
      <span class="material-symbols-outlined" style={{ fontSize: "1.5em" }}>
        chevron_right
      </span>
      <MDBox sx={{ fontSize: "16px", width: "200px", fontWeight: "500" }}>
        {!activeSourceName ? (
          <SkeletonTheme highlightColor="white">
            <Skeleton height={"1rem"} width="150px" />
          </SkeletonTheme>
        ) : (
          <Autocomplete
            value={activeSourceName}
            PopperComponent={CustomPopper}
            sx={{
              border: "none",
              padding: 0,
              "& .MuiOutlinedInput-root": {
                "& .MuiAutocomplete-input": {
                  padding: "0",
                },
                "&:hover fieldset": {
                  borderColor: "#00000000",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#00000000",
                },
              },
              "& fieldset": {
                borderColor: "#00000000",
              },
              "& input": {
                fontSize: "15px",
              },
            }}
            renderInput={(params) => <TextField {...params} />}
            disableClearable
            options={sources}
            getOptionLabel={(option) => {
              return labelKey && option[labelKey]
                ? option[labelKey]
                : option?.name
                ? option.name
                : typeof option != "object"
                ? option
                : "";
            }}
            onChange={(e, newValue) => {
              setActiveSourceId(newValue?.id);
              setActiveSourceName(newValue?.name);
            }}
          />
        )}
      </MDBox>
    </>
  );
};
