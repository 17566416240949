import { Dialog } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect } from "react";

import { useTheme } from "@emotion/react";
import { Paper } from "@mui/material";
import Controls from "components/controls/Controls";
import MDChip from "components/MDChip";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { convertKeyToName } from "utils/conversion";
import Dropdown from "components/controls/Dropdown";
import { OutlinedButton } from "components/MDButton/button";
import { controlTypes } from "components/controls/Controls";
import FormBuilder from "examples/formBuilder/FormBuilder";
import CustomAutoComplete from "components/controls/AutoComplete";
import CustomInput from "components/controls/CustomInput";

function ValidationDialog({
  open,
  handleClose,
  defaultInputs,
  handleSubmit,
  buttonName = "Add buttonName",
  heading = "Add heading",
  iconName = "Add google iconName",
  isLoading,
  defaultSheets,
  doubleColAfterInputs = 5,
}) {
  const [state, setState] = useState({});
  const [sheets, setSheets] = useState({});
  const [showFormBuilder, setShowFormBuilder] = useState(null);
  const [tempSheetValue, setTempSheetValue] = useState(null);
  const [inputBack, setInputBack] = useState([]);

  const handleSubmitClick = () => {
    let isValid = true;
    let temp = {};
    let tempdata = {};
    Object.keys(state).forEach((key) => {
      temp[key] = state[key];
      if (state[key].required && !state[key].value) {
        state[key].error = true;
        isValid = false;
      } else {
        tempdata[key] = state[key].value;
        state[key].error = false;
      }
    });
    if (isValid) {
      handleSubmit({ ...tempdata, sheets: Object.values(sheets) });
    } else {
      setState(temp);
    }
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: {
        ...state[event.target.name],
        value: event.target.value,
      },
    });
  };

  useEffect(() => {
    if (sheets.length > 0) {
      setState({
        ...state,
        primary_keys: {
          ...state["primary_keys"],
          options: getPrimaryKeyOptions(sheets),
        },
      });
    }
  }, [sheets]);

  const initialize = () => {
    let tempState = {};
    Object.keys(defaultInputs).map((feature) => {
      tempState[feature] = defaultInputs[feature];
      if (feature == "primary_keys") {
        tempState[feature].options = getPrimaryKeyOptions(defaultSheets);
      }
    });
    setState(tempState);
    let tempSheets =
      defaultSheets?.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {}) || {};
    setSheets(tempSheets);
  };

  const getPrimaryKeyOptions = (sheets) => {
    let options = [];
    Object.values(sheets).forEach((sheet) => {
      sheet?.columns?.forEach((data) => {
        options.push(data.db_name);
      });
    });
    return options;
  };

  useEffect(() => {
    if (defaultInputs && defaultSheets) {
      initialize();
    }
  }, [defaultInputs, defaultSheets]);

  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text, grey } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", maxWidth: "29rem" }}
      >
        <LoadingOverlay active={isLoading} spinner>
          <MDBox p={3}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: typography.size["xl"],
                fontWeight: typography.fontWeightBold,
              }}
              pb={2}
            >
              <MDBox sx={{ fontSize: "inherit" }}>{heading}</MDBox>
              <span
                class="material-symbols-outlined"
                style={{ color: primary.main }}
              >
                {iconName}
              </span>
            </MDBox>
            <Grid container columnSpacing={2}>
              {Object.keys(state).map((feature, index) => {
                return (
                  <Grid
                    item
                    xs={index < doubleColAfterInputs ? 12 : 6}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <MDBox sx={{ width: "100%" }}>
                      <Controls
                        type={state[feature]?.type}
                        name={feature || ""}
                        variant={
                          index < doubleColAfterInputs ? "standard" : "outlined"
                        }
                        label={
                          state[feature]?.label || convertKeyToName(feature)
                        }
                        value={state[feature]?.value}
                        dataKey={state[feature]?.dataKey}
                        labelKey={state[feature]?.labelKey}
                        onChange={handleChange}
                        disabled={!state[feature]?.editable}
                        options={state[feature]?.options || []}
                        error={state[feature]?.error}
                        helperText={
                          state[feature]?.error
                            ? "This field is required."
                            : null
                        }
                      />
                    </MDBox>
                  </Grid>
                );
              })}

              <MDBox sx={{ width: "100%" }} pl={2}>
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: typography.size["sm"],
                    fontWeight: typography.fontWeightBold,
                  }}
                  py={1}
                >
                  <MDBox sx={{ fontSize: "inherit" }}>Sheets</MDBox>
                  <OutlinedButton
                    name={"Add Sheets"}
                    onClick={() => {
                      setShowFormBuilder({
                        data: {},
                        type: "create",
                        key: Math.random(),
                      });
                    }}
                    sx={{
                      height: "30px",
                      borderRadius: "100px",
                    }}
                    icon={
                      <span
                        class="material-symbols-outlined"
                        style={{
                          color: primary.main,
                          cursor: "pointer",
                        }}
                      >
                        add
                      </span>
                    }
                  />
                </MDBox>
                <MDBox
                  sx={{
                    maxHeight: "200px",
                    overflow: "auto",
                  }}
                >
                  {Object.keys(sheets).map((key, index) => (
                    <MDBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingY: "5px",
                      }}
                    >
                      <MDBox>{sheets[key].sheet_name}</MDBox>
                      <MDBox
                        sx={{
                          display: "flex",
                          gap: "1em",
                          alignItems: "center",
                        }}
                      >
                        <span
                          class="material-symbols-outlined"
                          style={{
                            color: primary.main,
                            borderRadius: "100%",
                            cursor: "pointer",
                            fontSize: "1.3em",
                          }}
                          onClick={() => {
                            setShowFormBuilder({
                              data: sheets[key],
                              type: "update",
                              key: key,
                            });
                            setTempSheetValue({
                              primary_key: sheets[key].primary_key,
                              sheet_name: sheets[key].sheet_name,
                            });
                          }}
                        >
                          edit
                        </span>
                        <span
                          class="material-symbols-outlined"
                          style={{
                            color: error.main,
                            borderRadius: "100%",
                            cursor: "pointer",
                            fontSize: "1.3em",
                          }}
                          onClick={() => {
                            let tempSheets = { ...sheets };
                            delete tempSheets[key];
                            setSheets(tempSheets);
                          }}
                        >
                          delete
                        </span>
                      </MDBox>
                    </MDBox>
                  ))}
                </MDBox>
              </MDBox>

              <Grid item xs={2} md={12}>
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                >
                  <MDChip
                    sx={{
                      backgroundColor: error.light + "45",
                      padding: "5px 10px",
                      color: error.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      border: "1px solid " + error.main,
                      width: "140px",
                      "&:hover": {
                        backgroundColor: error.light + "45",
                        boxShadow: "0 0 5px 1px " + error.main + "82",
                      },
                    }}
                    label={"Cancel"}
                    onClick={handleClose}
                  />
                  <MDChip
                    sx={{
                      backgroundColor: primary.main,
                      padding: "5px 10px",
                      color: white.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "140px",
                    }}
                    label={buttonName}
                    onClick={() => handleSubmitClick()}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </LoadingOverlay>
      </Paper>

      {showFormBuilder && (
        <Dialog
          open={showFormBuilder}
          onClose={() => {
            setShowFormBuilder(null);
            setTempSheetValue(null);
            setInputBack(null);
          }}
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "700px",
              width: "70vw",
              height: "70vh",
            },
          }}
        >
          <style jsx>{`
            ._loading_overlay_wrapper {
              height: 100%;
            }
          `}</style>
          <MDBox sx={{ minWidth: "30rem", height: "100%" }}>
            <FormBuilder
              defaultInputs={
                showFormBuilder?.data?.columns?.reduce(
                  (acc, currentValue, index) => {
                    acc[index] = currentValue;
                    return acc;
                  },
                  {}
                ) || {}
              }
              inputTypes={[
                controlTypes.text,
                controlTypes.dropdown,
                controlTypes.integer,
                controlTypes.datetime,
              ]}
              topInfo={
                <MDBox
                  sx={{ marginRight: "1rem", display: "flex", gap: "1rem" }}
                >
                  <CustomInput
                    label={"Sheet Name"}
                    name={"sheetName"}
                    value={tempSheetValue?.sheet_name}
                    onChange={(e) => {
                      setTempSheetValue((pre) => ({
                        ...pre,
                        sheet_name: e.target.value,
                      }));
                    }}
                    size={"small"}
                  />
                  <CustomAutoComplete
                    label={"Primary key"}
                    name={"primaryKey"}
                    disableClearable
                    value={tempSheetValue?.primary_key?.[0]}
                    options={inputBack?.map((data) => data.db_name) || []}
                    onChange={(e) => {
                      setTempSheetValue((pre) => ({
                        ...pre,
                        primary_key: [e.target.value],
                      }));
                    }}
                    size={"small"}
                  />
                </MDBox>
              }
              handleSubmit={(data) => {
                setSheets((pre) => ({
                  ...pre,
                  [showFormBuilder.key]: {
                    ...pre[showFormBuilder.key],
                    sheet_name: tempSheetValue.sheet_name,
                    primary_key: tempSheetValue.primary_key,
                    columns: Object.values(data),
                  },
                }));
                setShowFormBuilder(null);
                setTempSheetValue(null);
                setInputBack(null);
              }}
              heading={"Form Builder"}
              handleClose={() => {
                setInputBack(null);
                setShowFormBuilder(null);
                setTempSheetValue(null);
              }}
              nameKey={"column_name"}
              showPermission={false}
              extraFields={[
                {
                  name: "db_name",
                  type: "Text",
                  editable: true,
                },
                {
                  name: "not_null",
                  type: "DropdownSingleSelect",
                  editable: true,
                  options: ["true", "false"],
                },
                {
                  name: "unique",
                  type: "DropdownSingleSelect",
                  editable: true,
                  options: ["true", "false"],
                },
                {
                  name: "date_format",
                  type: "Text",
                  editable: false,
                  value: "YYYY-MM-DDTHH:MM:SS",
                  onlyFor: controlTypes.datetime,
                },
              ]}
              setInputBack={setInputBack}
            />
          </MDBox>
        </Dialog>
      )}
    </Dialog>
  );
}

export default ValidationDialog;
