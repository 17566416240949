import { useEffect, useState } from "react";
import { databaseService } from "../service/databaseService";

const initialFilters = {
  search: "",
  page_no: 1,
  limit: 10,
  offset: 0,
};

const useDatabase = ({ applyFilterFlag, dbname, activeSourceId }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [scheduleData, setScheduleData] = useState({
    name: "",
    interval: "",
    enabled: "true",
  });

  useEffect(() => {
    if (activeSourceId) {
      setIsLoading(true);
      databaseService
        .getDatabaseFilters(dbname, activeSourceId)
        .then((res) => {
          if (res.status === 200) {
            const tempFilters = [];
            res.data.forEach((data) => {
              tempFilters[data.field] = data.options ? [] : "";
            });
            setFilterOptions(res.data || []);
            setFilters((pre) => ({ ...pre, ...tempFilters }));
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
      databaseService
        .getIngestionSchedules(activeSourceId)
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            setScheduleData({
              name: data.name,
              interval: data?.schedule_expression?.match(/\d+/)[0],
              enabled: data.enabled,
            });
          }
        })
        .catch(() => {});
    }
  }, [activeSourceId]);

  useEffect(() => {
    if (activeSourceId) {
      setIsLoading(true);
      const { page_no, limit, search, offset, ...remainingfilters } = filters;
      databaseService
        .getIngestions({
          filters: remainingfilters,
          page_no: filters.offset + 1,
          source_type: dbname,
          id: activeSourceId,
          limit,
          search,
        })
        .then((res) => {
          if (res.status === 200) {
            setPageInfo({
              count: res.data.total_count || res.data.data.length,
              pages: Math.ceil(
                (res.data.total_count || res.data.data.length) / filters.limit
              ),
            });
            setRows(res.data.data);
            setColumnData(res.data.column_data);
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [applyFilterFlag, activeSourceId]);

  return {
    isLoading,
    setIsLoading,
    filterOptions,
    rows,
    columns: columnData,
    pages: pageInfo,
    filters,
    setFilters,
    scheduleData,
    setScheduleData,
  };
};

export default useDatabase;
