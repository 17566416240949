import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import DataTable from "examples/Tables/DataTable";
import {
  clearAllTableFilters,
  handleFilterChangeForCustomTable,
} from "examples/Tables/DataTable/utils";
import TableDisplay from "examples/Tables/tableDisplay/tableDisplay";
import { setGlobalState } from "Global";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ConfigSettings, SourcesDropdown } from "../components/Config";
import FlowChart from "../components/FlowChart";
import useIntegration from "../useIntegration";
import useFileUpload from "./hooks/useFileUpload";
import { fileUploadService } from "./service/fileUploadService";

const ignoreFilterList = ["limit", "offset", "search", "page_no"];
const sourceType = "excel";

const FileUpload = () => {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [applyFilterFlag, setApplyFilterFlag] = useState(false);
  const [activeData, setActiveData] = useState(null);
  const [isLoadingProcessedData, setIsLoadingProcessedData] = useState(false);
  const [resData, setResData] = useState(null);
  const [showFlowChart, setShowFlowChart] = useState(false);

  const {
    sources,
    setSources,
    activeSourceId,
    setActiveSourceId,
    integrationLoading,
    setIntegrationLoading,
  } = useIntegration(sourceType);

  const {
    isLoading,
    setIsLoading,
    filterOptions,
    rows,
    columns,
    pages,
    filters,
    setFilters,
  } = useFileUpload({
    applyFilterFlag,
    sourceType,
    activeSourceId,
  });

  const handleFilterChange = (event, type) => {
    setFilters(handleFilterChangeForCustomTable(event, filters, type));
  };

  let debounceTimer;

  const handleSearchChange = (text, type) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilters((prevValue) => {
        return { ...prevValue, search: text };
      });
      handleClickApplyFilter();
    }, 300);
  };

  const handleExcelFileChange = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];

    fileUploadService
      .uploadFile(file, activeSourceId)
      .then((res) => {
        if (res.status == 200) {
          if (res.data?.validation_result?.length > 0) {
            setGlobalState("error", {
              open: true,
              message: res.data?.validation_result.join(", "),
              type: "success",
            });
          } else {
            setGlobalState("error", {
              open: true,
              message: "Successfully uploaded the File",
              type: "success",
            });
          }
          handleClickApplyFilter();
          if (Object.keys(res.data.errored_data).length > 0) {
            setResData(res.data);
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        event.target.value = null;
        setIsLoading(false);
      });
  };

  const getProcesseData = (id) => {
    setIsLoadingProcessedData(true);
    fileUploadService
      .getProcessedData(id, sourceType, activeSourceId)
      .then((res) => {
        if (res.status == 200) {
          setActiveData(res.data);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingProcessedData(false);
      });
  };
  const onRowClick = (row) => {
    getProcesseData(row.values.file_id.props.data);
  };
  const clearAllFilter = () => {
    setFilters(clearAllTableFilters(filters));
    handleClickApplyFilter();
  };
  const handleClickApplyFilter = () => {
    setApplyFilterFlag(Math.random());
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              upload
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Link to={"/integrations"}>
              <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
                Integrations
              </MDBox>
            </Link>
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "1.5em" }}
            >
              chevron_right
            </span>
            <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
              Excel Upload
            </MDBox>
            <SourcesDropdown
              sources={sources}
              setActiveSourceId={setActiveSourceId}
              activeSourceId={activeSourceId}
            />
          </MDBox>
        }
        rightContent={
          <MDBox
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <OutlinedButton
              name={"Flow Chart"}
              sx={{
                backgroundColor: showFlowChart ? palette.primary.light : "",
              }}
              onClick={() => {
                setShowFlowChart((pre) => !pre);
              }}
              icon={<span class="material-symbols-outlined">account_tree</span>}
            />
            <label htmlFor="file">
              <input
                type="file"
                onChange={(e) => handleExcelFileChange(e)}
                name="file"
                id="file"
                style={{ display: "none" }}
                accept=".xls,.xlsx,.xlsm,.csv"
              />
              <OutlinedButton
                name={"Upload File"}
                onClick={() => {}}
                icon={
                  <span
                    style={{ fontSize: "1.3em" }}
                    class="material-symbols-outlined"
                  >
                    upload
                  </span>
                }
              />
            </label>
            <ConfigSettings
              type={"excel"}
              sources={sources}
              setSources={setSources}
              activeSourceId={activeSourceId}
              setActiveSourceId={setActiveSourceId}
              integrationLoading={integrationLoading}
              setIntegrationLoading={setIntegrationLoading}
            />
          </MDBox>
        }
      />
      {showFlowChart ? (
        <FlowChart
          type={"excel"}
          sources={sources}
          setSources={setSources}
          integrationLoading={integrationLoading}
          setIntegrationLoading={setIntegrationLoading}
        />
      ) : (
        <MDBox
          sx={{
            position: "relative",
          }}
        >
          <DataTable
            table={{ columns: columns, rows: rows || [] }}
            isSorted={true}
            entriesPerPage={{ defaultValue: filters?.limit }}
            showTotalEntries={true}
            noEndBorder
            canSearch={true}
            rowClick={onRowClick}
            filters={filters}
            filterOptions={filterOptions}
            handleFilterChange={handleFilterChange}
            clearAllFilter={clearAllFilter}
            handleSearchChange={(text) => {
              handleSearchChange(text);
            }}
            downloadable={false}
            customPagination={true}
            pageInfo={pages}
            isLoading={isLoading || integrationLoading}
            handleClickApplyFilter={handleClickApplyFilter}
            tableHeight={"calc(100vh - 184px)"}
            ignoreFilterList={ignoreFilterList}
          />
        </MDBox>
      )}

      {(activeData || isLoadingProcessedData) && (
        <MDBox
          onClick={() => {
            setActiveData(null);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            zIndex: "99999",
            height: "100vh",
            width: "100vw",
            left: "0",
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <style jsx>{`
            .MuiPopper-root,
            .MuiDialog-root {
              z-index: 99999999 !important;
            }
          `}</style>
          <MDBox
            sx={{
              height: "calc(100vh - 100px)",
              width: "calc(100% - 100px)",
              overflow: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <DataTable
              table={{
                columns: activeData?.column_data || [],
                rows: activeData?.data || [],
              }}
              isSorted={true}
              canSearch={true}
              showTotalEntries={true}
              noEndBorder
              downloadable={true}
              isLoading={isLoadingProcessedData}
              entriesPerPage={{ defaultValue: 50 }}
              tableHeight={"calc(100% - 78px)"}
            />
          </MDBox>
        </MDBox>
      )}
      {resData && (
        <ErrorTableDisplay
          open={true}
          errorMessages={resData?.validation_result.join(", ")}
          data={resData.errored_data}
          handleClose={() => {
            setResData(null);
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default FileUpload;

const ErrorTableDisplay = ({
  open,
  handleClose,
  errorMessages = [],
  data = {},
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text, error } = palette;
  const { pxToRem } = functions;

  const [activeTab, setActiveTab] = useState(Object.keys(data)[0]);

  return (
    <>
      {open && (
        <MDBox
          onClick={() => handleClose()}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            zIndex: "99999999999",
            height: "100vh",
            width: "100vw",
            left: "0",
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <MDBox
            sx={{
              height: "calc(100vh - 100px)",
              width: "calc(100% - 100px)",
              overflow: "auto",
              backgroundColor: white.main,
              borderRadius: "10px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <MDBox
              sx={{ padding: "10px", paddingBottom: "5px" }}
              color={error.main}
            >
              {errorMessages}
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                fontSize: typography.size["sm"],
                fontWeight: typography.fontWeightMedium,
                borderBottom: `1px solid ${palette.grey[300]}`,
                gap: "1em",
                backgroundColor: white.main,
                borderRadius: "10px 10px 0 0",
              }}
            >
              {Object.keys(data)?.map((key, index) => (
                <MDBox
                  key={index}
                  sx={{
                    padding: pxToRem(8),
                    cursor: "pointer",
                    gap: "5px",
                    display: "flex",
                    paddingBottom: pxToRem(5),
                    paddingTop: 0,
                    borderBottom:
                      activeTab === key
                        ? `2px solid ${palette.primary.main}`
                        : ``,
                  }}
                  color={palette.text.header}
                  onClick={() => setActiveTab(key)}
                >
                  {key}
                </MDBox>
              ))}
            </MDBox>
            <TableDisplay
              data={data[activeTab]}
              downloadable={true}
              canSearch={true}
              entriesPerPage={false}
              showTotalEntries={true}
              noTopPadding={true}
              tableHeight={"calc(100% - 71px)"}
              sx={{
                height: "calc(100% - 70px)",
              }}
            />
          </MDBox>
        </MDBox>
      )}
    </>
  );
};
