import { orderAnalyticsApi } from "api";

export const orderService = {
  getFilterOptions(data) {
    return orderAnalyticsApi.getFilterOptions({
      filters: {},
      data_type: "delivery",
      interval: "monthly",
      limit: 100,
      ...data,
    });
  },
  getOrdersOverTime(filters) {
    return orderAnalyticsApi
      .getOrdersOverTime({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getOrdersByStatus(filters) {
    return orderAnalyticsApi
      .getOrdersByStatus({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getOrdersByOrigin(filters) {
    return orderAnalyticsApi
      .getOrdersByOrigin({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getPaymentMethodDistribution(filters) {
    return orderAnalyticsApi
      .getPaymentMethodDistribution({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getTopCustomers(filters) {
    return orderAnalyticsApi
      .getTopCustomers({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getProductCategoryPerformance(filters) {
    return orderAnalyticsApi
      .getProductCategoryPerformance({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getWarehousePerformance(filters) {
    return orderAnalyticsApi
      .getWarehousePerformance({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getUnitStatusDistribution(filters) {
    return orderAnalyticsApi
      .getUnitStatusDistribution({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getDiscountAnalysis(filters) {
    return orderAnalyticsApi
      .getDiscountAnalysis({
        filters: filters,
        data_type: "delivery",
        interval: "monthly",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
};
