import { useTheme } from "@emotion/react";
import {
  CircularProgress,
  Collapse,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import { formatTime } from "examples/Tables/DataTable/utils";
import { formatDate } from "examples/Tables/DataTable/utils";
import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import { emailService } from "../service/emailService";
import { folderService } from "layouts/folders/service/folderService";
import FileModel from "layouts/folders/components/FileViewer";
import DialogWrapper from "utils/dialog/dialogWrapper";
import TextWithIcon from "layouts/folders/components/TextWithIcon";
import QuickActionPopup from "components/quickActionPopup/QuickActionPopup";
import GridView from "layouts/folders/components/GridView";
import { printHtmlToPdf } from "utils";
import ExcelPopupTable from "./ExcelPopupTable";

const EmailViewer = ({ emailDetails, onBackClick, setPreviewFile, sourceId }) => {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text, grey } = palette;
  const { pxToRem } = functions;

  const [isLoading, setIsLoading] = useState(false);
  const [showTablePopup, setShowTablePopup] = useState(false);

  const { chain_emails, ...mainEmail } = emailDetails;
  const allEmails = {};
  chain_emails.forEach((email) => {
    allEmails[email._id] = email;
    allEmails[email._id].show = mainEmail._id === email._id ? true : false;
    allEmails[email._id].attachmentsData = [];
  });
  const [chainEmails, setChainEmails] = useState(allEmails);

  const [attachments, setAttachments] = useState(null);

  const printRef = useRef(null);

  const getAttachments = (emailId) => {
    if (
      chainEmails[emailId]?.attachments.length > 0 &&
      chainEmails[emailId]?.attachmentsData.length === 0
    ) {
      setIsLoading(true);
      emailService
        .getAttachmentDetails(chainEmails[emailId]?.attachments, sourceId)
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            setChainEmails((pre) => ({
              ...pre,
              [emailId]: {
                ...pre[emailId],
                attachmentsData: res.data,
              },
            }));
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getAttachments(mainEmail._id);
  }, []);

  return (
    <MDBox
      sx={{
        height: "100%",
        overflow: "auto",
        paddingX: ".5rem",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "space-between",
          "& span:hover": {
            color: text.main + " !important",
            backgroundColor: grey[200],
          },
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <span
            onClick={onBackClick}
            style={{
              borderRadius: "100px",
              cursor: "pointer",
              color: text.disabled,
              padding: "5px",
              marginRight: "6px",
            }}
            class="material-symbols-outlined"
          >
            arrow_back
          </span>
          <MDBox
            sx={{ fontWeight: "500", fontSize: typography.size["lg"] }}
            color={text.secondary}
          >
            {mainEmail.subject}
          </MDBox>
        </MDBox>
        <span
          onClick={() =>
            printHtmlToPdf(
              printRef.current.innerHTML,
              `.MuiCollapse-root{height: auto !important; visibility: visible !important;}`
            )
          }
          style={{
            borderRadius: "100px",
            cursor: "pointer",
            color: text.disabled,
            padding: "5px",
            fontSize: "1.5em",
          }}
          class="material-symbols-outlined"
        >
          print
        </span>
      </MDBox>
      <MDBox ref={printRef}>
        {Object.keys(chainEmails).map((key) => {
          const email = chainEmails[key];
          return (
            <MDBox
              key={email._id}
              sx={{
                borderBottom: "1px solid " + text.light,
                paddingY: "10px",
              }}
            >
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setChainEmails((pre) => ({
                    ...pre,
                    [email._id]: {
                      ...pre[email._id],
                      show: !pre[email._id].show,
                    },
                  }));
                  getAttachments(email._id);
                }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1rem",
                  }}
                >
                  <MDBox
                    sx={{
                      height: "40px",
                      width: "40px",
                      fontSize: typography.size["lg"],
                      fontWeight: "bold",
                      background: primary.light,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "100%",
                      border: `1px solid ${palette.primary.main}`,
                    }}
                  >
                    {email.from[0].toUpperCase()}
                  </MDBox>
                  <MDBox>
                    <MDBox sx={{ fontWeight: "bold" }} color={text.secondary}>
                      {email.from}
                    </MDBox>
                    <MDBox
                      sx={{ fontSize: typography.size["xs"] }}
                      color={text.disabled}
                    >
                      {email.show || mainEmail._id === email._id ? (
                        <MDBox
                          sx={{ display: "flex" }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <MDBox
                            sx={{ fontSize: typography.size["xs"] }}
                            color={text.disabled}
                          >
                            {email.to[0]}
                          </MDBox>
                          <QuickActionPopup
                            icon={
                              <span
                                class="material-symbols-outlined"
                                style={{
                                  color: text.disabled,
                                  cursor: "pointer",
                                  fontSize: "1.5em",
                                  position: "relative",
                                  top: "-2px",
                                }}
                              >
                                arrow_drop_down
                              </span>
                            }
                            sx={{
                              left: "18px",
                              top: "18px",
                              right: "none",
                              padding: "10px 1.5rem",
                            }}
                          >
                            <Table
                              sx={{
                                width: "100%",
                                color: text.main,
                                borderCollapse: "collapse",
                                "& td, & th": {
                                  padding: "2px 0px",
                                  textAlign: "left",
                                  fontSize: "14px",
                                  border: "none",
                                },
                                "& .side-heading": {
                                  textAlign: "right !important",
                                  paddingRight: "10px",
                                  color: text.disabled,
                                  verticalAlign: "top",
                                },
                              }}
                            >
                              <TableRow>
                                <TableCell className="side-heading">
                                  from:
                                </TableCell>
                                <TableCell>{email.from}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="side-heading">
                                  to:
                                </TableCell>
                                <TableCell>
                                  {email.to.map((email) => " " + email + ",")}
                                </TableCell>
                              </TableRow>
                              {email.cc.length > 0 && (
                                <TableRow>
                                  <TableCell className="side-heading">
                                    cc:
                                  </TableCell>
                                  <TableCell>
                                    {email.cc.map((email) => " " + email + ",")}
                                  </TableCell>
                                </TableRow>
                              )}
                              {email.bcc.length > 0 && (
                                <TableRow>
                                  <TableCell className="side-heading">
                                    bcc:
                                  </TableCell>
                                  <TableCell>
                                    {email.bcc.map(
                                      (email) => " " + email + ","
                                    )}
                                  </TableCell>
                                </TableRow>
                              )}
                              <TableRow>
                                <TableCell className="side-heading">
                                  date:
                                </TableCell>
                                <TableCell>
                                  {formatDate(email.created_at)},{" "}
                                  {formatTime(email.created_at)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className="side-heading">
                                  subject:
                                </TableCell>
                                <TableCell>{email.subject}</TableCell>
                              </TableRow>
                            </Table>
                          </QuickActionPopup>
                        </MDBox>
                      ) : (
                        email.subject
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox color={text.disabled}>
                    {formatDate(email.created_at)}
                  </MDBox>
                  <MDBox
                    sx={{ fontSize: typography.size["xs"] }}
                    color={text.disabled}
                  >
                    {formatTime(email.created_at)}
                  </MDBox>
                </MDBox>
              </MDBox>
              <Collapse in={mainEmail._id === email._id || email.show}>
                <MDBox sx={{ marginX: "56px", paddingY: "1rem" }}>
                  <MDBox
                    sx={{
                      fontWeight: "500",
                      fontSize: typography.size["lg"],
                      marginBottom: "1rem",
                    }}
                    color={text.secondary}
                  >
                    {email.subject}
                  </MDBox>
                  <MDBox
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(email.content.html),
                    }}
                  />
                  {email.attachments.length > 0 && (
                    <MDBox
                      sx={{
                        marginTop: "1rem",
                        paddingY: "1rem",
                        borderTop: "1px solid " + text.light,
                      }}
                    >
                      <MDBox sx={{ fontWeight: "bold" }} color={text.secondary}>
                        Attachments
                      </MDBox>
                      <MDBox>
                        {isLoading && email.attachmentsData.length === 0 ? (
                          <CircularProgress
                            color="primary"
                            sx={{
                              height: "1.5em !important",
                              width: "1.5em !important",
                              marginTop: "1em",
                            }}
                          />
                        ) : (
                          <GridView
                            foldersAndFiles={{
                              files: email.attachmentsData || [],
                              subfolders: [],
                            }}
                            setPreviewFile={setPreviewFile}
                          />
                        )}
                      </MDBox>
                    </MDBox>
                  )}
                </MDBox>
              </Collapse>
            </MDBox>
          );
        })}
      </MDBox>
    </MDBox>
  );
};

export default EmailViewer;
