import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { convertKeyToName } from "utils/conversion";
import MDBox from "components/MDBox";
import { useTheme } from "@emotion/react";

const ObjectToTable = ({ data, tableStyle, sx }) => {
  const keys = data && data.length > 0 ? Object.keys(data[0]) : [];
  const theme = useTheme();
  return (
    <MDBox sx={sx}>
      <Table
        sx={{
          width: "100%",
          borderCollapse: "collapse",
          "& td, & th": {
            border: "1px solid #f0f2f5",
            padding: ".3rem 1rem",
            textAlign: "left",
            fontSize: "14px",
          },
          ...tableStyle
        }}
      >
        <TableRow
          sx={{
            background: theme.palette.primary.light
          }}
        >
          {keys.map((key) => (
            <TableCell
              sx={(theme) => ({
                fontWeight: "500",
                color: theme.palette.text.secondary,
              })}
              key={key}
            >
              {convertKeyToName(key)?.toUpperCase()}
            </TableCell>
          ))}
        </TableRow>
        {data?.map((items, index) => (
          <TableRow>
            {keys.map((key) =>
              typeof items[key] === "object" && items[key] ? (
                <TableCell key={index}>
                  {Object.keys(items[key]).map((datakey, index) => (
                    <MDBox
                      color={theme.palette.text.secondary}
                      key={datakey + index}
                    >
                      <MDBox sx={{ fontWeight: "500" }} color={"inherit"}>
                        {datakey}:
                      </MDBox>{" "}
                      {items[key][datakey]}
                    </MDBox>
                  ))}
                </TableCell>
              ) : (
                <TableCell key={index}>{items[key]}</TableCell>
              )
            )}
          </TableRow>
        ))}
      </Table>
    </MDBox>
  );
};

export default ObjectToTable;
