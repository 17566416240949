/*
 * This file serves as a base file for any API interactions with other applications.
 * All API calls, declarations, definitions are included here.
 */
import axios from "axios";
import useWebSocket from "react-use-websocket";

const url = "";
const webSocketUrl = process.env.REACT_APP_API_URL_WS;

export let client = axios.create({
  baseURL: process.env.REACT_APP_API_URL_MAIN,
  withCredentials: true,
});

export let analyticsClient = axios.create({
  baseURL: process.env.REACT_APP_ANALYTICS_API,
  withCredentials: true,
});

export let claimDeskClient = axios.create({
  baseURL: process.env.REACT_APP_API_CLAIM_DESK,
  withCredentials: true,
});

export const basicAPI = {
  signin(username, password) {
    const params = { username: username, password: password };
    return client.post(`${url}/signin`, null, { params: params });
  },

  signinNew(username, password) {
    const data = { username: username, password: password };
    return client.post(`${url}/signin_new`, data);
  },

  signinKey() {
    return client.get(`${url}/signin_new/public_key`);
  },

  login() {
    return client.get(`${url}/login`);
  },

  logout() {
    return client.get(`${url}/logout`);
  },

  userData() {
    return client.get(`${url}/me`);
  },

  refreshToken() {
    return client.get(`${url}/me`);
  },

  loginCallback(code) {
    const params = { code: code };
    return client.get(`${url}/login/callback`, { params: params });
  },
};

export const agentAPI = {
  // New api
  getAllCases(paramsData) {
    const params = paramsData;
    return client.get(`${url}/get_all_cases`, { params: params });
  },

  getAllCasesAdmin(paramsData) {
    const params = paramsData;
    return client.get(`${url}/get_all_cases_admin`, { params: params });
  },

  getAgentDetails(agentId) {
    const params = { agent_id: agentId };
    return client.get(`${url}/get_single_agent_details`, { params: params });
  },
};

export const userAPI = {
  getUserDetails() {
    return client.get(`${url}/get_current_user_details`);
  },

  updateUserDetails(userDetails) {
    return client.post(`${url}/update_user_details`, null, {
      params: userDetails,
    });
  },
};

//signup-auth

export const SignUpAPI = {
  signUpEmail(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify`,
      method: "POST",
      data,
    });
  },
  signUpEmailResend(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/resend`,
      method: "POST",
      data,
    });
  },
  signUpEmailOTP(params, email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/otp`,
      method: "POST",
      data,
      params: params,
    });
  },
  signUpEmailPasswd(params, email) {
    const data = { value: email };
    console.log(data, params);
    return client({
      url: `${url}/sign-up/emailverify/otp/setpasswd`,
      method: "POST",
      data,
      params: params,
    });
  },
};

// New application apis
export const DashboardAPI = {
  getFilterValues(params) {
    return client.post(`${url}/filter/common`, null, { params: params });
  },
  getFilterNames() {
    return client.post(`${url}/filter/get_all_filter_names`);
  },

  // Base Analytics
  getOTDBASEDataTile(params, data) {
    return client.post(`${url}/analytics/main/otd-base/tile`, data, {
      params: params,
    });
  },

  getOTDPHCDataTile(params, data) {
    return client.post(`${url}/analytics/main/otd-phc/tile`, data, {
      params: params,
    });
  },
  getOTCBASEDataTile(params, data) {
    return client.post(`${url}/analytics/main/otc-base/tile`, data, {
      params: params,
    });
  },

  getOTCDSADAKARDataTile(params, data) {
    return client.post(`${url}/analytics/main/otc-dsadakar/tile`, data, {
      params: params,
    });
  },
  getVFRDataTile(params, data) {
    return client.post(`${url}/analytics/main/vfr/tile`, data, {
      params: params,
    });
  },

  getOTDBASEDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otd-base/trend`, data, {
      params: params,
    });
  },

  getOTDPHCDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otd-phc/trend`, data, {
      params: params,
    });
  },
  getOTCBASEDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otc-base/trend`, data, {
      params: params,
    });
  },

  getOTCDSADAKARDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otc-dsadakar/trend`, data, {
      params: params,
    });
  },
  getVFRDataTrend(params, data) {
    return client.post(`${url}/analytics/main/vfr/trend`, data, {
      params: params,
    });
  },

  getTrendGroupBy(params, data) {
    return client.post(`${url}/analytics/main/trend/groupby_names`, data, {
      params: params,
    });
  },

  // Dedicated breakdown
  getOTDBASEDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otd-base`, data, {
      params: params,
    });
  },
  getOTDPHCDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otd-phc`, data, {
      params: params,
    });
  },
  getOTCBASEDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otc-base`, data, {
      params: params,
    });
  },
  getOTCDSADAKARDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otc-dsadakar`, data, {
      params: params,
    });
  },
  getVFRDataSub(params, data) {
    return client.post(`${url}/analytics/sub/vfr`, data, { params: params });
  },

  getGroupByOTD(params, data) {
    return client.post(`${url}/analytics/sub/otdotc/groupby_names`, data, {
      params: params,
    });
  },

  getGroupByOTC(params, data) {
    return client.post(`${url}/analytics/sub/otdotc/groupby_names`, data, {
      params: params,
    });
  },

  getGroupByVFR(params, data) {
    return client.post(`${url}/analytics/sub/vfr/groupby_names`, data, {
      params: params,
    });
  },

  // OTD New Analytics

  getOTDGroupBy(params, data) {
    return client.post(`${url}/analytics/otd/groupby_names`, data, {
      params: params,
    });
  },

  getOTDTrend(params, data) {
    return client.post(`${url}/analytics/otd/trend`, data, { params: params });
  },

  getOTDTrendGroupBy(params, data) {
    return client.post(`${url}/analytics/otd/trend/groupby_names`, data, {
      params: params,
    });
  },

  // OTD (Domestic) Analytics
  getOTDDomesticCustomer(params, data) {
    return client.post(`${url}/analytics/otd/domestic/customer_wise`, data, {
      params: params,
    });
  },

  getOTDDomesticDeliveryRegion(params, data) {
    return client.post(`${url}/analytics/otd/domestic/region_wise`, data, {
      params: params,
    });
  },

  getOTDDomesticTrend(params, data) {
    return client.post(`${url}/analytics/otd/domestic/trend`, data, {
      params: params,
    });
  },
  getOTDDomesticOverall(params, data) {
    return client.post(`${url}/analytics/otd/domestic/overall`, data, {
      params: params,
    });
  },

  // OTD (Export) Analytics
  getOTDExportCustomer(params, data) {
    return client.post(`${url}/analytics/otd/export/customer_wise`, data, {
      params: params,
    });
  },

  getOTDExportOffloadingPoint(params, data) {
    return client.post(
      `${url}/analytics/otd/export/offloading_point_wise`,
      data,
      { params: params }
    );
  },

  getOTDExportTrend(params, data) {
    return client.post(`${url}/analytics/otd/export/trend`, data, {
      params: params,
    });
  },

  getOTDExportOverall(params, data) {
    return client.post(`${url}/analytics/otd/export/overall`, data, {
      params: params,
    });
  },

  // VFR Analytics
  getVFRCustomer(params, data) {
    return client.post(`${url}/analytics/vfr/customer_wise`, data, {
      params: params,
    });
  },
  getVFRRegion(params, data) {
    return client.post(`${url}/analytics/vfr/region_wise`, data, {
      params: params,
    });
  },
  getVFROverall(params, data) {
    return client.post(`${url}/analytics/vfr/overall`, data, {
      params: params,
    });
  },
  getVFRTrend(params, data) {
    return client.post(`${url}/analytics/vfr/trend`, data, { params: params });
  },
};

export const ExcelDataAPI = {
  excelUpload(params, data) {
    return client({
      url: `${url}/excel_upload`,
      method: "POST",
      params: params,
      data,
      headers: { "content-type": "multipart/form-data" },
    });
  },
  getReferences() {
    return client.post(`${url}/get_supported_references`);
  },
  getMainTable(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/get_main_table`, data, { params: params });
  },

  updateMainRecords(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/update_records`, data, { params: params });
  },

  deleteMainRecord(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/delete_record`, data, { params: params });
  },
  getSuccessTable(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/get_success_table`, data, { params: params });
  },
  getFailTable(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/get_fail_table`, data, { params: params });
  },
};

// docstore api

export const folderAPI = {
  getFolderAndFilesDetails(data) {
    return client.post(`${url}/folders/list_contents`, data);
  },
  getFolderDetails(id) {
    return client.post(`${url}/folders/get_folder`, { folder_id: id });
  },
  getFileDetails(id) {
    return client.post(`${url}/files/get_file`, { file_id: id });
  },
  createFolder(data) {
    return client.post(`${url}/folders/create_folder`, data);
  },
  deleteFolder(data) {
    return client.post(`${url}/folders/delete_folder`, data);
  },
  deleteFile(data) {
    return client.post(`${url}/files/delete_file`, data);
  },
  uploadFile(data) {
    return client({
      url: `${url}/folders/upload_files`,
      method: "POST",
      data,
      headers: { "content-type": "multipart/form-data" },
    });
  },
  searchFiles(query, isVectorSearch = false) {
    return client.post(`${url}/files/search`, {
      search_query: query,
      vector_search: isVectorSearch,
    });
  },
  searchFilesWS(params) {
    return new useWebSocket(`${webSocketUrl}/files/search/ws`, {
      queryParams: params,
    });
  },
  shareFolder(id, shareWith = [], removedList = []) {
    return client.post(`${url}/folders/share_folder`, {
      folder_id: id,
      shared_with: shareWith,
      removed_list: removedList,
    });
  },
  shareFile(id, shareWith = [], removedList = []) {
    return client.post(`${url}/files/share_file`, {
      file_id: id,
      shared_with: shareWith,
      removed_list: removedList,
    });
  },
};

export const docstoreUserAPI = {
  getAllUsers() {
    return client.post(`${url}/users/get_all`);
  },
  createUser(email, name, permission, username, teamId) {
    return client.post(`${url}/users/create_user`, {
      email_id: email,
      username: username,
      name: name,
      permission: permission,
      team_id: teamId,
    });
  },
  updateUser(data) {
    return client.post(`${url}/users/update_user`, data);
  },
};

export const teamsManagementAPI = {
  getAllTeams() {
    return client.post(`${url}/teams/get_all_teams`, {});
  },
  createTeam(name, parentTeam, members, subTeams) {
    return client.post(`${url}/teams/create_team`, {
      team_name: name,
      parent_team: parentTeam,
      members: members || [],
      sub_teams: subTeams || [],
    });
  },
  updateTeam(id, members, name) {
    return client.post(`${url}/teams/update_team`, {
      team_id: id,
      members: members,
      team_name: name,
    });
  },
};

export const integrationAPI = {
  //database
  getIngestions(data) {
    return client.post(`${url}/integrations/db/get_ingestions`, data);
  },
  getIngestionDetails(data) {
    return client.post(
      `${url}/integrations/db/get_ingestion_details`,
      data
    );
  },
  initiateIngestion(data) {
    return client.post(
      `${url}/integrations/db/initiate_ingestion`,
      data
    );
  },
  getDatabaseFilters(data) {
    return client.post(`${url}/integrations/db/filters`, data);
  },
  getIngestionSchedules(data) {
    return client.post(`${url}/integrations/cron/get_cron_job`, data);
  },
  createIngestionSchedules(data) {
    return client.post(
      `${url}/integrations/cron/create_cron_job`,
      data
    );
  },
  updateIngestionSchedules(data) {
    return client.post(
      `${url}/integrations/cron/update_cron_job`,
      data
    );
  },

  //email
  getAllEmails(data) {
    return client.post(`${url}/integrations/emails`, data);
  },
  getEmailFilters(sourceType, sourceId) {
    return client.post(`${url}/integrations/emails/filters`, {
      source_type: sourceType,
      id: sourceId,
    });
  },
  getAttachmentDetails(ids, sourceId) {
    return client.post(`${url}/integrations/emails/get_attachments`, {
      attachment_ids: ids,
      id: sourceId,
    });
  },
  getExcelProcessedData(id, sourceId) {
    return client.post(
      `${url}/integrations/emails/get_processed_data`,
      {
        attachment_ids: [id],
        id: sourceId,
      }
    );
  },

  //excel fileUpload
  getFileUploadData(data) {
    return client.post(
      `${url}/integrations/excel/get_excel_uploads`,
      data
    );
  },
  getProcessedData(id, sourceType, sourceId) {
    return client.post(`${url}/integrations/excel/get_processed_data`, {
      filters: { file_id: id },
      source_type: sourceType,
      id: sourceId,
    });
  },
  getFileUploadFilters(sourceType, sourceId) {
    return client.post(`${url}/integrations/excel/filters`, {
      source_type: sourceType,
      id: sourceId,
    });
  },
  uploadFile(file, sourceId, sourceType = "excel") {
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("id", sourceId);
    return client.post(`${url}/integrations/excel/upload`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // common
  getIntegrationsList() {
    return client.post(`${url}/integrations/get_integrations_list`, {});
  },
  getSources(type) {
    return client.post(`${url}/integrations/get_sources`, {
      integration_type: type,
    });
  },
  getSourceDetails(id, type) {
    return client.post(`${url}/integrations/get_source_details`, {
      integration_type: type,
      id: id,
    });
  },
  updateSourceDetails(data) {
    return client.post(
      `${url}/integrations/update_source_details`,
      data
    );
  },
  createSource(type, name, description, target, fields) {
    return client.post(`${url}/integrations/create_source`, {
      integration_type: type,
      source_details: {
        name: name,
        description: description,
        target: target,
        fields: fields,
        filters: [],
        validation: {},
        icon_name: "",
      },
    });
  },
};

export const deliveryAPI = {
  getDeliveryData(data) {
    return client.post(`${url}/data_view`, data);
  },
  getDeliveryDetails(id) {
    return client.post(`${url}/data_view/get_details`, {
      filters: { _id: id },
    });
  },
  updateDeliveryDetails(id, data) {
    return client.post(`${url}/data_view/update_details`, {
      filters: { _id: id },
      field_values: data,
    });
  },
  getDeliveryFilterOptions() {
    return client.post(`${url}/data_view/filters`, {});
  },
  getDeliveryFiles(id) {
    return client.post(`${url}/data_view/get_documents`, {
      filters: { _id: id },
    });
  },
  deleteFile(deliveryId, fileId) {
    return client.post(`${url}/data_view/delete_document`, {
      filters: { _id: deliveryId },
      field_values: { file_id: fileId },
    });
  },
  uploadFiles(deliveryId, files = [], fileIdsList = []) {
    const formdata = new FormData();
    files.forEach((file) => {
      formdata.append("files", file);
    });

    fileIdsList.forEach((id) => {
      formdata.append("file_ids", id);
    });

    formdata.append("filters", JSON.stringify({ _id: deliveryId }));

    return client.post(`${url}/data_view/add_documents`, formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export const chatGPTAPI = {
  createNewSession(params) {
    return analyticsClient.get(`${url}/get_new_session_for_text_to_sql`, {
      params: params,
    });
  },
  getSessionList(params) {
    return analyticsClient.get(`${url}/get_all_sessions_by_user`, {
      params: params,
    });
  },
  getSessionConversation(params) {
    return analyticsClient.get(`${url}/get_all_conversation_in_session`, {
      params: params,
    });
  },
  updateSession(params, data) {
    return analyticsClient.post(`${url}/update_session_summary`, data, {
      params: params,
    });
  },
  deleteSession(params, data) {
    return analyticsClient.post(`${url}/delete_session`, data, {
      params: params,
    });
  },
  getSql(params, data) {
    return analyticsClient.post(`${url}/get_text_to_sql`, data, {
      params: params,
    });
  },
  getDataFromSql(params) {
    return analyticsClient.post(`${url}/execute_sql`, null, { params: params });
  },
  getGraphFromSql(params) {
    return analyticsClient.post(`${url}/get_text_to_graph/v2`, null, {
      params: params,
    });
  },

  // APIs for "@" (Value select) Feature
  getAllFeaturesAvailable(params) {
    return analyticsClient.get(`${url}/get_all_features_available`, {
      params: params,
    });
  },
  getUniqueFeatureValues(params) {
    return analyticsClient.get(`${url}/get_unique_values_of_features`, {
      params: params,
    });
  },
};

export const AnalyticsApi = {
  getCaseStateCounts(filters) {
    return claimDeskClient.post(`${url}/get_cases_states_analytics`, filters);
  },
  getClaimsCountByAgeBucket(filters) {
    return claimDeskClient.post(
      `${url}/get_claim_count_by_age_bucket`,
      filters
    );
  },
  getClaimCostByCustomer(filters) {
    return claimDeskClient.post(`${url}/get_claim_cost_by_customer`, filters);
  },
  getClaimCountByReasonCodes(filters) {
    return claimDeskClient.post(
      `${url}/get_claim_count_by_reason_codes`,
      filters
    );
  },
  getInvalidClaimsByCustomer(filters) {
    return claimDeskClient.post(`${url}/invalid_claims_by_customer`, filters);
  },
  getClaimsByAgent(filters) {
    return claimDeskClient.post(`${url}/get_claims_by_agent`, filters);
  },
  getClaimsByTime(filters) {
    return claimDeskClient.post(`${url}/cases_per_state_vs_time`, filters);
  },
  getClaimsByCurrentStatus(filters) {
    return claimDeskClient.post(`${url}/get_claims_by_current_status`, filters);
  },
  getClaimsByWorkflow(filters) {
    return claimDeskClient.post(`${url}/get_claims_by_workflow`, filters);
  },
  getClaimsByRetailer(filters) {
    return claimDeskClient.post(`${url}/claims_by_retailer`, filters);
  },
};
export const analyticsAPI = {
  // Basic Analytics

  getCaseOldnessSummary(resolved) {
    const params = { resolved: resolved };
    return claimDeskClient.get(`${url}/get_case_oldness_summary`, {
      params: params,
    });
  },

  getWorkflowWiseClaimCount(resolved) {
    const params = { resolved: resolved };
    return claimDeskClient.get(`${url}/get_workflow_wise_number_of_claims`, {
      params: params,
    });
  },

  getWorkflowWiseClaimAmount(resolved) {
    const params = { resolved: resolved };
    return claimDeskClient.get(`${url}/get_workflow_wise_total_claim_amount`, {
      params: params,
    });
  },

  getStatusWiseCount() {
    const params = {};
    return claimDeskClient.get(`${url}/get_number_of_cases_in_statuses`, {
      params: params,
    });
  },

  getClaimsByCustomer(resolved) {
    const params = { resolved: resolved };
    return claimDeskClient.get(`${url}/get_total_claims_by_customer`, {
      params: params,
    });
  },

  // Info Cards - Total Claims
  getCasesStatesAnalytics(filters) {
    return claimDeskClient.post(`${url}/get_cases_states_analytics`, filters);
  },

  // Pie Chart Filters

  getSummaryFilterValues() {
    return claimDeskClient.get(`${url}/get_all_filters`);
  },

  getGroupedFilterValues() {
    return claimDeskClient.get(`${url}/get_all_filters_grouped_cases`);
  },
};

export const orderForecastAnalyticsApi = {
  getFilterOptions(data) {
    return client.post(`${url}/analytics/forecast/filters`, data);
  },
  getDemandForecast(data) {
    return client.post(
      `${url}/analytics/forecast/demand-forecast`,
      data
    );
  },
  getSeasonalDecomposition(data) {
    return client.post(
      `${url}/analytics/forecast/seasonal-decomposition`,
      data
    );
  },
  getRegionWiseDemand(data) {
    return client.post(
      `${url}/analytics/forecast/region-wise-demand`,
      data
    );
  },
};

export const orderAnalyticsApi = {
  getFilterOptions(data) {
    return client.post(`${url}/analytics/orders/filters`, data);
  },
  getOrdersOverTime(data) {
    return client.post(
      `${url}/analytics/orders/orders_over_time`,
      data
    );
  },
  getOrdersByStatus(data) {
    return client.post(
      `${url}/analytics/orders/orders_by_status`,
      data
    );
  },
  getOrdersByOrigin(data) {
    return client.post(
      `${url}/analytics/orders/orders_by_origin`,
      data
    );
  },
  getPaymentMethodDistribution(data) {
    return client.post(
      `${url}/analytics/orders/payment_method_distribution`,
      data
    );
  },
  getTopCustomers(data) {
    return client.post(`${url}/analytics/orders/top_customers`, data);
  },
  getProductCategoryPerformance(data) {
    return client.post(
      `${url}/analytics/orders/product_category_performance`,
      data
    );
  },
  getWarehousePerformance(data) {
    return client.post(
      `${url}/analytics/orders/warehouse_performance`,
      data
    );
  },
  getUnitStatusDistribution(data) {
    return client.post(
      `${url}/analytics/orders/unit_status_distribution`,
      data
    );
  },
  getDiscountAnalysis(data) {
    return client.post(
      `${url}/analytics/orders/discount_analysis`,
      data
    );
  },
};

export const deliveryAnalyticsApi = {
  getFilterOptions(data) {
    return client.post(`${url}/analytics/delivery/filters`, data);
  },
  getDeliveriesOverTime(data) {
    return client.post(
      `${url}/analytics/delivery/deliveries_over_time`,
      data
    );
  },
  getDeliveriesByTransporter(data) {
    return client.post(
      `${url}/analytics/delivery/deliveries_by_transporter`,
      data
    );
  },
  getDeliveriesByCustomer(data) {
    return client.post(
      `${url}/analytics/delivery/deliveries_by_customer`,
      data
    );
  },
  getTopShipments(data) {
    return client.post(`${url}/analytics/delivery/top_shipments`, data);
  },
  getMissedDeliveriesAnalysis(data) {
    return client.post(
      `${url}/analytics/delivery/missed_deliveries_analysis`,
      data
    );
  },
  getOntimeDeliveries(data) {
    return client.post(
      `${url}/analytics/delivery/ontime_deliveries`,
      data
    );
  },
  getOntimeCollections(data) {
    return client.post(
      `${url}/analytics/delivery/ontime_collections`,
      data
    );
  },
  getRegionPerformance(data) {
    return client.post(
      `${url}/analytics/delivery/region_performance`,
      data
    );
  },
  getPlannedVsActualAnalysis(data) {
    return client.post(
      `${url}/analytics/delivery/planned_vs_actual_analysis`,
      data
    );
  },
};
