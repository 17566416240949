import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import DataTable from "examples/Tables/DataTable";
import {
  clearAllTableFilters,
  handleFilterChangeForCustomTable,
} from "examples/Tables/DataTable/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useDelivery from "./hooks/useDelivery";

const ignoreFilterList = ["limit", "offset", "search", "page_no"];

const Delivery = () => {
  const navigation = useNavigate();

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [applyFilterFlag, setApplyFilterFlag] = useState(false);

  const {
    isLoading,
    filterOptions,
    deliveries,
    columns,
    pages,
    filters,
    setFilters,
  } = useDelivery({
    applyFilterFlag,
  });

  const handleFilterChange = (event, type) => {
    setFilters(handleFilterChangeForCustomTable(event, filters, type));
  };

  let debounceTimer;

  const handleSearchChange = (text, type) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilters((prevValue) => {
        return { ...prevValue, search: text };
      });
      setApplyFilterFlag(Math.random());
    }, 300);
  };

  const onRowClick = (row) => {
    navigation("/delivery/" + row.values._id.props.data);
  };

  const clearAllFilter = () => {
    setFilters(clearAllTableFilters(filters));
    handleClickApplyFilter();
  };
  const handleClickApplyFilter = () => {
    setApplyFilterFlag(Math.random());
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              table_view
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Data</MDBox>
        }
      />
      <MDBox
        sx={{
          position: "relative",
        }}
      >
        <DataTable
          table={{ columns: columns, rows: deliveries || [] }}
          isSorted={true}
          entriesPerPage={{ defaultValue: filters?.limit }}
          showTotalEntries={true}
          noEndBorder
          canSearch={true}
          rowClick={onRowClick}
          filters={filters}
          filterOptions={filterOptions}
          handleFilterChange={handleFilterChange}
          clearAllFilter={clearAllFilter}
          handleSearchChange={(text) => {
            handleSearchChange(text);
          }}
          downloadable={false}
          customPagination={true}
          pageInfo={pages}
          isLoading={isLoading}
          handleClickApplyFilter={handleClickApplyFilter}
          tableHeight={"calc(100vh - 184px)"}
          ignoreFilterList={ignoreFilterList}
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default Delivery;
