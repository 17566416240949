import { deliveryAnalyticsApi } from "api";

export const deliveryService = {
  getFilterOptions(data) {
    return deliveryAnalyticsApi.getFilterOptions({
      filters: {},
      data_type: "delivery",
      interval: "daily",
      limit: 100,
      ...data,
    });
  },
  getDeliveriesOverTime(filters) {
    return deliveryAnalyticsApi
      .getDeliveriesOverTime({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getDeliveriesByTransporter(filters) {
    return deliveryAnalyticsApi
      .getDeliveriesByTransporter({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getDeliveriesByCustomer(filters) {
    return deliveryAnalyticsApi
      .getDeliveriesByCustomer({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getTopShipments(filters) {
    return deliveryAnalyticsApi
      .getTopShipments({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getMissedDeliveriesAnalysis(filters) {
    return deliveryAnalyticsApi
      .getMissedDeliveriesAnalysis({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getOntimeDeliveries(filters) {
    return deliveryAnalyticsApi
      .getOntimeDeliveries({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getOntimeCollections(filters) {
    return deliveryAnalyticsApi
      .getOntimeCollections({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getRegionPerformance(filters) {
    return deliveryAnalyticsApi
      .getRegionPerformance({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
  getPlannedVsActualAnalysis(filters) {
    return deliveryAnalyticsApi
      .getPlannedVsActualAnalysis({
        filters: filters,
        data_type: "delivery",
        interval: "daily",
        limit: 100,
      })
      .then((res) => {
        return res.data;
      });
  },
};
