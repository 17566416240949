import { useTheme } from "@emotion/react";
import Dropdown from "components/controls/Dropdown";
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import { useEffect, useRef, useState } from "react";
import { convertKeyToName } from "utils/conversion";
import DateRangePicker from "../../../components/controls/DateRangePicker";
import { convertToList, getDateRange } from "../utils";
import CustomDatePicker from "components/controls/CustomDatePicker";
import { Dialog, List, ListItem } from "@mui/material";
import ObjectToTable from "./ObjectToTable";
import AiLoading from "examples/loaders/AiLoading";
import Controls from "components/controls/Controls";

const ChartHolderPopup = ({
  title,
  handleFilterChange,
  chart,
  handleClosePopup,
  filters,
  ignoreFilterList,
  filterOptions,
  handleApplyFiter,
  showFilter,
  handleSelectTab,
  tabs,
  activeTab,
  chartInsights,
  isLoadingInsights,
  setShowTableView,
  showTableView,
  enableTableView,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [height, setHeight] = useState(10);
  const divRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        setHeight(divRef.current.offsetHeight + 20);
      }
    }, 0);
  }, []);

  return (
    <Dialog open={true}>
      <MDBox
        sx={{
          height: "100vh",
          width: "100vw",
          backdropFilter: "blur(3px)",
          position: "fixed",
          zIndex: "9999",
          top: "0",
          left: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={handleClosePopup}
      >
        <MDBox
          sx={{
            width: "calc(100% - 100px)",
            height: "calc(100% - 50px)",
            background: white.main,
            borderRadius: "10px",
            padding: "1.3em",
            overflow: "auto",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <MDBox ref={divRef}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <MDBox
                sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <MDBox
                  sx={{ fontSize: typography.size["lg"] }}
                  color={text.main}
                >
                  {title}
                </MDBox>
                {enableTableView && chart && (
                  <span
                    style={{
                      color: text.disabled,
                      backgroundColor: showTableView ? "#D6D9FF" : "",
                      borderRadius: "100px",
                      padding: "5px",
                      fontSize: "1.5em",
                      cursor: "pointer",
                    }}
                    class="material-symbols-outlined"
                    onClick={() => setShowTableView((pre) => !pre)}
                  >
                    table
                  </span>
                )}
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3em",
                }}
              >
                {showFilter && filters && Object.keys(filters).includes("from_date") && (
                  <>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {tabs.map((tab) => (
                        <MDBox
                          sx={{
                            padding: "0.5em 1em",
                            textAlign: "center",
                            borderRadius: "1em",
                            cursor: "pointer",
                            backgroundColor:
                              activeTab === tab ? primary.main : "#F7F7F7",
                          }}
                          color={activeTab === tab ? white.main : text.disabled}
                          onClick={() =>
                            handleSelectTab(tab, getDateRange(tab))
                          }
                        >
                          {tab}
                        </MDBox>
                      ))}
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "1em",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {/* <span
                  class="material-symbols-outlined"
                  style={{
                    color: primary.main,
                    backgroundColor: primary.light,
                    borderRadius: "100%",
                  }}
                >
                  chevron_left
                </span>

                <MDBox mt={"3px"} color={text.disabled}>
                  Oct ‘23 – Sep ‘24
                </MDBox>
                <span
                  class="material-symbols-outlined"
                  style={{
                    color: primary.main,
                    backgroundColor: primary.light,
                    borderRadius: "100%",
                  }}
                >
                  chevron_right
                </span> */}
                      <DateRangePicker
                        from={filters?.from_date}
                        to={filters?.to_date}
                        onChangeFrom={(date) =>
                          handleSelectTab("custom", {
                            from: date,
                            to: filters?.to_date,
                          })
                        }
                        onChangeTo={(date) =>
                          handleSelectTab("custom", {
                            to: date,
                            from: filters?.from_date,
                          })
                        }
                      />
                    </MDBox>
                  </>
                )}
                <MDBox
                  color={primary.main}
                  onClick={handleClosePopup}
                  sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                >
                  x Close
                </MDBox>
              </MDBox>
            </MDBox>

            {filters && showFilter && (
              <MDBox
                my={2}
                mx={1}
                sx={{
                  display: "flex",
                  gap: "1rem",
                }}
              >
                <MDBox
                  sx={{
                    width: "100%",
                    display: "grid",
                    columnGap: "1rem",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    "@media (max-width: 800px)": {
                      gridTemplateColumns: "repeat(2, 1fr)",
                    },
                  }}
                >
                  {filterOptions?.map((input, index) => {
                    const filterName = input?.field || input?.name;
                    if (!ignoreFilterList.includes()) {
                      return (
                        <Controls
                          type={input?.type}
                          DefaultType={Dropdown}
                          key={index}
                          name={filterName}
                          value={filters[filterName]}
                          onChange={handleFilterChange}
                          dataKey={"value"}
                          options={input?.options || []}
                          getOptionLabel={(option) => {
                            return option?.name ? option.name : option;
                          }}
                          placeholder={
                            filters[filterName] != "" ? "" : "Select"
                          }
                          label={convertKeyToName(filterName)}
                          size={"small"}
                        />
                      );
                    }
                  })}
                </MDBox>

                <MDBox
                  sx={{
                    width: "30%",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <MDChip
                    sx={{
                      backgroundColor: secondary.main,
                      padding: "5px 40px",
                      color: white.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      fontSize: "14px",
                      height: "40px",
                      borderRadius: "100px",
                      marginBottom: "10px",
                    }}
                    label={"Apply All Filters"}
                    onClick={handleApplyFiter}
                  />
                </MDBox>
              </MDBox>
            )}
          </MDBox>

          <MDBox
            sx={{
              height: `calc(100% - ${height}px)`,
              display: "flex",
            }}
          >
            <MDBox
              sx={{
                height: `100%`,
                minHeight: "100px",
                marginRight: "1.5em",
                position: "relative",
                flex: 1,
                maxWidth: chartInsights ? "55%" : "100%",
                minWidth: "50%",
              }}
            >
              {chart}
            </MDBox>
            {chartInsights &&
              (isLoadingInsights ? (
                <AiLoading
                  messages={[
                    "Analyzing Chart Data...",
                    "Gathering Insights...",
                  ]}
                  time={1000}
                />
              ) : (
                <MDBox
                  sx={{
                    flex: "1",
                    height: `100%`,
                    overflow: "auto",
                  }}
                >
                  {Object.keys(chartInsights).map((key) => (
                    <MDBox sx={{ marginY: "10px" }}>
                      <MDBox sx={{ fontWeight: "500" }}>
                        {convertKeyToName(key)}
                      </MDBox>
                      {
                        <>
                          <style jsx>{`
                            ul {
                              margin-left: 1rem;
                            }
                          `}</style>
                          {Array.isArray(chartInsights[key]) ? (
                            typeof chartInsights[key][0] === "object" ? (
                              <ObjectToTable data={chartInsights[key]} />
                            ) : (
                              <List sx={{ marginLeft: "1rem" }}>
                                {chartInsights[key].map((data, index) => (
                                  <ListItem>
                                    {index + 1}. {data}
                                  </ListItem>
                                ))}
                              </List>
                            )
                          ) : typeof chartInsights[key] === "object" ? (
                            <ul>{convertToList(chartInsights[key])}</ul>
                          ) : (
                            chartInsights[key]
                          )}
                        </>
                      }
                    </MDBox>
                  ))}
                </MDBox>
              ))}
          </MDBox>
        </MDBox>
        <style jsx>{`
          .MuiPopper-root,
          .MuiDialog-root {
            z-index: 99999999 !important;
          }
        `}</style>
      </MDBox>
    </Dialog>
  );
};

export default ChartHolderPopup;
