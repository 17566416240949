import { useTheme } from "@emotion/react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import DeliveryOverview from "./deliveryOverview/DeliveryOverview";
import Documents from "./documents/Documents";

const tabs = ["DELIVERY OVERVIEW", "DOCUMENTS"];
const tabicons = ["quick_reference", "folder"];

const LeftPanel = ({ deliveryId }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [activeTab, setActiveTab] = useState(tabs[0]);

  // declaring here to preserve state
  const [deliveryOverview, setDeliveryOverview] = useState([]);
  const [deliveryFiles, setDeliveryFiles] = useState([]);

  return (
    <MDBox
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          fontSize: "14px",
          alignItems: "center",
          width: "100%",
        }}
        color={text.main}
      >
        {tabs.map((tab, index) => (
          <MDBox
            sx={{
              padding: "5px 10px",
              cursor: "pointer",
              flex: "1",
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              backgroundColor: activeTab === tab ? primary.main : ``,
              borderRadius: "10px",
            }}
            key={tab}
            color={activeTab != tab ? text.header : white.main}
            onClick={() => {
              setActiveTab(tab);
            }}
          >
            <MDTypography class="material-symbols-outlined">
              {tabicons[index]}
            </MDTypography>
            <MDBox color={"inherit"} sx={{ marginTop: "2px" }}>
              {tab}
            </MDBox>
          </MDBox>
        ))}
      </MDBox>
      <MDBox
        sx={{
          padding: "10px",
          marginTop: "5px",
          overflow: "auto",
          height: "100%",
          backgroundColor: white.main,
          borderRadius: "10px",
          boxShadow: "0px 0px 8px 1px #0000000D",
        }}
      >
        {activeTab === tabs[0] ? (
          <DeliveryOverview
            deliveryOverview={deliveryOverview}
            setDeliveryOverview={setDeliveryOverview}
            deliveryId={deliveryId}
          />
        ) : (
          <Documents
            deliveryFiles={deliveryFiles}
            setDeliveryFiles={setDeliveryFiles}
            deliveryId={deliveryId}
            deliveryOverview={deliveryOverview}
          />
        )}
      </MDBox>
    </MDBox>
  );
};

export default LeftPanel;
