import MDBox from "components/MDBox";
import { format } from "date-fns";
import dayjs from "dayjs";
import { toPng } from "html-to-image";
import { convertKeyToName } from "utils/conversion";

export const getDateRange = (tab) => {
  const now = new Date();
  let from, to;

  switch (tab) {
    case "This Week":
      const weekStart = now.getDate() - now.getDay();
      from = new Date(now.setDate(weekStart));
      to = new Date(now.setDate(weekStart + 6));
      break;
    case "This Quarter":
      const currentMonth = now.getMonth();
      const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
      from = new Date(now.getFullYear(), quarterStartMonth, 1);
      to = new Date(now.getFullYear(), quarterStartMonth + 3, 0);
      break;
    case "Last 6 Months":
      from = new Date(now.setMonth(now.getMonth() - 6));
      to = new Date();
      break;
    case "This Year":
      from = new Date(now.getFullYear(), 0, 1);
      to = new Date(now.getFullYear(), 11, 31);
      break;
    default:
      from = null;
      to = null;
      break;
  }
  from?.setHours(0, 0, 0, 0);
  return {
    from: from?.toISOString() || "",
    to: to?.toISOString() || "",
  };
};

export const getFiltersCount = (filters, ignoreFilterList) => {
  let count = 0;
  if (filters) {
    Object.keys(filters).forEach((filterName) => {
      console.log(filters[filterName])
      if (!ignoreFilterList.includes(filterName)) {
        if (Array.isArray(filters[filterName])) {
          count += filters[filterName]?.length;
        } else if (filters[filterName]) {
          count++;
        }
      }
      // if (filterName === "from_date" && filters[filterName]) {
      //   count++;
      // }
    });
  }
  return count;
};

export const downloadImage = (DomObject, name) => {
  if (DomObject)
    toPng(DomObject, {
      backgroundColor: "white",
      style: {
        backgroundColor: "white",
      },
    }).then((dataUrl) => {
      const a = document.createElement("a");

      a.setAttribute("download", name + ".png");
      a.setAttribute("href", dataUrl);
      a.click();
    });
};

export const calculateCombinedPercentage = (...percentages) => {
  let totalMultiplier = 1;

  percentages.forEach((percentage) => {
    totalMultiplier *= 1 + percentage / 100;
  });

  const combinedIncrease = (totalMultiplier - 1) * 100;
  return combinedIncrease;
};

export const convertToList = (obj) => {
  if (obj) {
    return Object.keys(obj).map((key) => {
      const value = obj[key];

      if (typeof value === "object" && value !== null) {
        return (
          <li key={key}>
            <MDBox
              component={"b"}
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontWeight: "500 !important",
              })}
            >
              {convertKeyToName(key)}
            </MDBox>
            :<ul>{convertToList(value)}</ul>
          </li>
        );
      }
      return (
        <li key={key}>
          <MDBox
            component={"b"}
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontWeight: "500 !important",
            })}
          >
            {convertKeyToName(key)}
          </MDBox>
          : {value}
        </li>
      );
    });
  }
};

export const handleFilterChangeCustom = (event, filters, type) => {
  if (
    (event.target.value instanceof Date &&
      !isNaN(event.target.value.getTime())) ||
    dayjs.isDayjs(event.target.value) ||
    type == "Date"
  ) {
    event.target.value = format(event.target.value, "yyyy-MM-dd");
  }
  if (Array.isArray(event.target.value) && event.target.value.length > 0) {
    let index = event.target.value.length - 1;
    let value = event.target.value[event.target.value.length - 1];
    if (typeof value == typeof {}) {
      event.target.value[index] = value.email_id
        ? value.email_id
        : value._id
        ? value._id
        : "";
    }
  }
  if (Array.isArray(event.target.value) && event.target.value.includes("All")) {
    event.target.value = [];
  }
  let tempFilters = { ...filters, [event.target.name]: event.target.value };

  if (event.target.name == "limit") {
    tempFilters["offset"] = 0;
  } else if (
    typeof event.target.value == "list" &&
    event.target.value?.includes("All")
  ) {
    tempFilters[event.target.name] = [];
  }

  return tempFilters;
};

export const formatForecastTableData = (data) => {
  if (data) {
    const formattedData = [];
    const labels = data.labels;
    const datasetsMap = {};
    data.datasets.forEach((dataset) => {
      datasetsMap[dataset.label] = dataset.data;
    });
    for (let i = 0; i < labels.length; i++) {
      const row = { label: labels[i] };
      for (const datasetLabel in datasetsMap) {
        row[datasetLabel] =
          datasetsMap[datasetLabel][i] !== null
            ? datasetsMap[datasetLabel][i]
            : "--";
      }
      formattedData.push(row);
    }
    return formattedData;
  }
};
