import { useTheme } from "@emotion/react";
import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Tab, Tabs } from "@mui/material";
import { chatGPTAPI } from "api";
import MDBox from "components/MDBox";
import Chart from "examples/chart/Chart";
import TableDisplay from "examples/Tables/tableDisplay/tableDisplay";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import EmptyResult from "utils/emptyResult";

const AiResponseViewer = ({ summary, sql }) => {
  const [tabValue, setTabValue] = useState("1");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [imageData, setImageData] = useState(null);
  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const [sqlResult, setSqlResult] = useState(null);

  const getGraph = () => {
    setIsGraphLoading(true);
    chatGPTAPI
      .getGraphFromSql({ sql_command: sql })
      .then((res) => {
        if (res.status == 200) {
          setIsGraphLoading(false);
          setImageData(res.data.content);
        }
      })
      .catch((error) => {
        setIsGraphLoading(false);
      });
  };

  const getTableData = () => {
    setIsGraphLoading(true);
    chatGPTAPI
      .getDataFromSql({ sql_command: sql })
      .then((res) => {
        if (res.status == 200) {
          setSqlResult(res.data);
          setIsGraphLoading(false);
        }
      })
      .catch((error) => {
        setIsGraphLoading(false);
      });
  };

  useEffect(() => {
    if (true) getTableData();
  }, [sql]);

  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, error, text } = palette;
  const { pxToRem } = functions;

  const [showTablePopup, setShowTablePopup] = useState(false);

  return (
    <>
      <MDBox
        sx={{
          backgroundColor: white.main,
          padding: "10px 15px",
          borderRadius: "10px",
          boxShadow: "0px 0px 8px 1px #0000000D",
          width: "100%",
          height: "max-content",
          minWidth: "30rem",
          position: "relative",
        }}
      >
        {sqlResult?.length > 0 && tabValue === "1" && (
          <span
            class="material-symbols-outlined"
            onClick={() => setShowTablePopup(true)}
            style={{
              color: text.disabled,
              cursor: "pointer",
              position: "absolute",
              right: "0",
              background: white.main,
              padding: "5px",
              borderRadius: "100%",
              top: "-10px",
              right: "-10px",
              border: "1px solid " + primary.main,
              fontSize: "1.3em",
            }}
          >
            open_in_full
          </span>
        )}
        <TabContext value={tabValue}>
          <AppBar
            position="static"
            sx={{
              margin: "5px 0 0 0",
              padding: "5px 10px",
              paddingBottom: "0",
            }}
          >
            <Tabs
              orientation={"horizontal"}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                background: primary.light,
                fontSize: typography.size["sm"] + " !important",
                "& .Mui-selected": {
                  fontSize: typography.size["sm"],
                  color: primary.main + " !important",
                  outline: "none",
                },
              }}
              textColor={text.disabled}
            >
              <Tab label="Data Table" value={"1"} />
              <Tab label="Analytics" value={"2"} />
              <Tab label="Summary" value={"3"} />
            </Tabs>
          </AppBar>
          <TabPanel
            value="1"
            index={1}
            sx={{
              width: "100%",
              height: "100%",
              margin: "0",
              padding: "1rem",
              overflow: "auto",
            }}
          >
            {sqlResult?.length == 0 && <EmptyResult text={"No data to show"} />}
            {sqlResult?.length > 0 && (
              <TableDisplay
                data={sqlResult}
                downloadable={true}
                canSearch={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noTopPadding={true}
                tableHeight={"15rem"}
                showControlls={false}
              />
            )}
          </TabPanel>
          <SkeletonTheme highlightColor="white">
            <TabPanel
              value="2"
              index={2}
              sx={{
                width: "100%",
                height: "100%",
                margin: "0",
                padding: "1em",
              }}
            >
              <MDBox
                sx={{
                  width: "100%",
                  height: "100%",
                  minWidth: "15rem",
                }}
              >
                {isGraphLoading == true && (
                  <Skeleton
                    style={{
                      height: "10rem",
                      width: "100%",
                      marginTop: "1rem",
                    }}
                  />
                )}
                {sqlResult?.length == 0 && isGraphLoading != true && (
                  <EmptyResult text={"No visualisation possible"} />
                )}
                {sqlResult?.length != 0 && (
                  // <BaseChart data={sqlResult} convertData />
                  <MDBox sx={{ height: "15rem" }}>
                    <Chart type={"bar"} data={sqlResult} />
                  </MDBox>
                )}
              </MDBox>
            </TabPanel>
          </SkeletonTheme>

          <TabPanel
            value="3"
            index={3}
            sx={{ width: "100%", height: "100%", margin: "0", padding: "1rem" }}
          >
            <MDBox>
              <MDBox sx={{ fontSize: typography.size['sm'] }}>
                {summary.replace(".", ".\n")}
              </MDBox>
            </MDBox>
          </TabPanel>
        </TabContext>
      </MDBox>

      {showTablePopup && (
        <MDBox
          onClick={() => setShowTablePopup(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            zIndex: "9999",
            height: "100vh",
            width: "100vw",
            left: "0",
            background: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <MDBox
            sx={{
              height: "calc(100vh - 100px)",
              width: "calc(100% - 100px)",
              overflow: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <TableDisplay
              data={sqlResult || []}
              downloadable={true}
              tableHeight={"calc(100% - 80px)"}
              entriesPerPage={{ defaultValue: 100 }}
            />
          </MDBox>
        </MDBox>
      )}
    </>
  );
};

export default AiResponseViewer;
